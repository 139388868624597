import {
  Box,
  Container,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  Divider,
  Button,
  FormControl,
  InputLabel,
  Select,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  MenuItem,
  TextField,
  ListItemAvatar,
  Avatar
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { X, Plus } from "react-feather";
import { captureException } from "@sentry/browser";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Calendar,
  CheckSquare,
  Clipboard,
  Clock,
  Info,
  MapPin
} from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { organizationSettings } from "../../actions";
import Page from "../../components/Page";
import api from "../../utils/api";
import getAvatarStyle from "../../utils/getAvatarStyle";
import getInitials from "../../utils/getInitials";
import Loader from "../../utils/loader";
import ResponseComponent from "./ResponseComponent";

const ResponseInterface = props => {
  var query = {};
  const location = useLocation();
  location.search
    .slice(1)
    .split("&")
    .forEach(item => {
      query[item.split("=")[0]] = item.split("=")[1];
    });

  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("");
  const [error, setError] = useState("");
  const [roomList, setRoomList] = useState([]);
  const [room, setRoom] = useState("");
  const [organizer, setOrganizer] = useState({});
  const [meeting, setMeeting] = useState({});
  const [selectRoom, setSelectRoom] = useState(true);
  const [isVisitor, setisVisitor] = useState(false);
  const [isBaggageAdded, setisBaggageAdded] = useState(false);
  const [baggage, setbaggage] = useState([]);
  const [baggageModal, setbaggageModal] = useState(false);
  const [newBaggageError, setnewBaggageError] = useState("");
  const [newBaggageName, setnewBaggageName] = useState("");
  const [newBaggageDescription, setnewBaggageDescription] = useState("");
  const [newBaggageModel, setnewBaggageModel] = useState("");
  const [newBaggageSerial, setnewBaggageSerial] = useState("");
  const [organizersBaggage, setorganizersBaggage] = useState([]);

  const orgSettings = useSelector(state => state.orgSettings);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      setLoading(true);
      let exists = localStorage.getItem("token");
      localStorage.setItem("token", query.token);
      try {
        const data = await api(
          "GET",
          "meeting/getMeetingByParticipant/" + query.participantId,
          null,
          true
        );

        const orgSettings = await api(
          "GET",
          "organization/getOrgSettings/" + data.organizationId,
          null,
          true
        );
        dispatch(organizationSettings(orgSettings));

        setMeeting(data);
        let hostParticipant = data.participants.find(
          item => item.isHost === true
        );

        setOrganizer(hostParticipant.employee || hostParticipant.visitor);

        let respondingParticipant = data.participants.find(
          item => item.id === query.participantId
        );

        if (hostParticipant.employee || respondingParticipant.visitor)
          setSelectRoom(false);

        if (respondingParticipant.visitor) {
          setisVisitor(true);
          if (respondingParticipant.baggage.length > 0) {
            setisBaggageAdded(true);
          }
        } else {
          setorganizersBaggage(hostParticipant.baggages);
        }

        const res = await api(
          "POST",
          "room/find",
          {
            search: [],
            sort: { key: "room.createdAt", order: "ASC" }
          },
          true
        );
        let availablerooms = res.filter(rooms => rooms.isActive && rooms.premiseType === "meetingRoom");
        setRoomList(availablerooms);
      } catch (error) {
        captureException(error);
      }

      setLoading(false);

      return () => {
        if (exists) {
          localStorage.setItem("token", exists);
        } else {
          localStorage.removeItem("token");
        }
      };
    })();
  }, [status, error]);

  const handleResponse = async (response, room, msg) => {
    setLoading(true);
    try {
      if (response === "accepted") {
        await api(
          "PATCH",
          "meeting/addBaggage/" + query.participantId,
          {
            baggage: baggage
          },
          false,
          false,
          false,
          false,
          query.token
        );
      }
      let data = await api(
        "PATCH",
        "meeting/updateParticipantStatus/" + query.participantId,
        room
          ? {
              status: response,
              roomId: room,
              customMessage: msg
            }
          : {
              status: response,
              customMessage: msg
            },
        true
      );
      setStatus(data.status);
      console.log("++++++++++++++++++++++++++++",data);
    } catch (error) {
      console.log("PPPPPPPPPPPPPPPPPPPPP",error.response.data);
      setError(error.response.data.message);
      // captureException(error);
    }
    setLoading(false);
  };

  return loading ? (
    <Loader />
  ) : (
    <Page title="Meeting Request Response">
      <Container maxWidth="sm">
        <Box p={3} m={3} style={{ backgroundColor: "#fff" }}>
          <Box mt={3} mb={2}>
            <Typography
              variant="h4"
              component="h2"
              align="center"
              color="primary"
            >
              Meeting Request Response
            </Typography>
          </Box>

          <Typography component="h2" gutterBottom variant="h5">
            Organizer
          </Typography>
          <Box minWidth={280} flexBasis={1}>
            <ListItem disableGutters>
              <ListItemAvatar>
                <Avatar style={getAvatarStyle(organizer.email)}>
                  {getInitials(organizer.name)}
                </Avatar>
              </ListItemAvatar>

              <ListItemText
                primary={<Typography variant="h5">{organizer.name}</Typography>}
                secondary={
                  <Typography variant="body2" color="textSecondary">
                    {organizer.role &&
                      organizer[orgSettings.employeePrimaryIdentifier]}
                    {!organizer.role &&
                      organizer[orgSettings.visitorPrimaryIdentifier]}
                  </Typography>
                }
              />
            </ListItem>
          </Box>
          <br />
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Grid container direction="row" spacing={1} alignItems="center">
                <Grid item>
                  <Box width={30}>
                    <Clipboard />
                  </Box>
                </Grid>
                <Grid item>
                  <Box width={80}>
                    <ListItem disableGutters>
                      <ListItemText
                        primary={<Typography variant="h5">Purpose</Typography>}
                      />
                    </ListItem>
                  </Box>
                </Grid>
                <Grid item>
                  <ListItem disableGutters>
                    <ListItemText
                      primary={
                        <Typography variant="body1">
                          {meeting.purpose}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container direction="row" spacing={1} alignItems="center">
                <Grid item>
                  <Box width={30}>
                    <Calendar />
                  </Box>
                </Grid>
                <Grid item>
                  <Box width={80}>
                    <ListItem disableGutters>
                      <ListItemText
                        primary={<Typography variant="h5">Date</Typography>}
                      />
                    </ListItem>
                  </Box>
                </Grid>
                <Grid item>
                  <ListItem disableGutters>
                    <ListItemText
                      primary={
                        <Typography variant="body1">
                          {moment(parseInt(meeting.startTime))
                            .local()
                            .format("Do MMM YYYY")}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={1} alignItems="center">
                <Grid item>
                  <Box width={30}>
                    <Clock />
                  </Box>
                </Grid>
                <Grid item>
                  <Box width={80}>
                    <ListItem disableGutters>
                      <ListItemText
                        primary={<Typography variant="h5">Time</Typography>}
                      />
                    </ListItem>
                  </Box>
                </Grid>
                <Grid item>
                  <ListItem disableGutters>
                    <ListItemText
                      primary={
                        <Typography variant="body1">
                          {moment(parseInt(meeting.startTime))
                            .local()
                            .format("LT")}
                          {" to "}
                          {moment(parseInt(meeting.endTime))
                            .local()
                            .format("LT")}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={1} alignItems="center">
                <Grid item>
                  <Box width={30}>
                    <Info />
                  </Box>
                </Grid>
                <Grid item>
                  <Box width={80}>
                    <ListItem disableGutters>
                      <ListItemText
                        primary={<Typography variant="h5">Status</Typography>}
                      />
                    </ListItem>
                  </Box>
                </Grid>
                <Grid item>
                  <ListItem disableGutters>
                    <ListItemText
                      primary={
                        <Typography variant="button">
                          {meeting.status}
                        </Typography>
                      }
                    />
                  </ListItem>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" spacing={1} alignItems="center">
                <Grid item>
                  <Box width={30}>
                    <CheckSquare />
                  </Box>
                </Grid>
                <Grid item>
                  <Box width={80}>
                    <ListItem disableGutters>
                      <ListItemText
                        primary={
                          <Typography variant="h5">My Status</Typography>
                        }
                      />
                    </ListItem>
                  </Box>
                </Grid>
                <Grid item>
                  <ListItem disableGutters>
                    <ListItemText
                      primary={
                        <Typography variant="button">
                          {
                            meeting.participants.find(
                              item => item.id === query.participantId
                            ).status
                          }
                        </Typography>
                      }
                    />
                  </ListItem>
                </Grid>
              </Grid>
            </Grid>
            {meeting.room && (
              <Grid item>
                <Grid container direction="row" spacing={1} alignItems="center">
                  <Grid item>
                    <Box width={30}>
                      <MapPin />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box width={80}>
                      <ListItem disableGutters>
                        <ListItemText
                          primary={<Typography variant="h5">Venue</Typography>}
                        />
                      </ListItem>
                    </Box>
                  </Grid>
                  <Grid item>
                    <ListItem disableGutters>
                      <ListItemText
                        primary={
                          <Typography variant="body1">
                            {meeting.room.name}
                            {", "}
                            {meeting.building.name}
                          </Typography>
                        }
                      />
                    </ListItem>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {[
              "requested",
              "checkedIn",
              "checkedOut",
              "cancelled",
              "declined"
            ].includes(
              meeting.participants.find(item => item.id === query.participantId)
                .status
            ) &&
              status == "" &&
              isVisitor &&
              !isBaggageAdded &&
              query.baggage === "true" && (
                <>
                  <Divider />
                  <br />
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography component="h2" gutterBottom variant="h4">
                        Baggage Details
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        color="primary"
                        variant="outlined"
                        id="submit"
                        size="large"
                        onClick={e => {
                          setbaggageModal(true);
                        }}
                        startIcon={<Plus />}
                      >
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                  <Dialog
                    open={baggageModal}
                    onClose={e => setbaggageModal(false)}
                    maxWidth="xs"
                    fullWidth
                    aria-labelledby="form-dialog-title"
                  >
                    <DialogContent>
                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <Typography component="h2" gutterBottom variant="h4">
                            Add Baggage
                          </Typography>
                        </Grid>
                        <Grid item>
                          <IconButton
                            edge="end"
                            color="primary"
                            onClick={() => setbaggageModal(false)}
                          >
                            <X />
                          </IconButton>
                        </Grid>
                      </Grid>
                      {newBaggageError !== "" && (
                        <Alert
                          severity="error"
                          action={
                            <Button
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setnewBaggageError("");
                              }}
                            >
                              <X />
                            </Button>
                          }
                        >
                          {newBaggageError}
                        </Alert>
                      )}
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%" }}
                        id="baggageSelect"
                      >
                        <InputLabel id="building"> Type</InputLabel>
                        <Select
                          labelId="type"
                          id="type"
                          label="Type"
                          value={newBaggageName}
                          onChange={e => {
                            setnewBaggageError("");
                            setnewBaggageName(e.target.value);
                          }}
                          autoWidth
                        >
                          {JSON.parse(orgSettings.baggageItems).map(bitem => (
                            <MenuItem value={bitem}>
                              {bitem.charAt(0).toUpperCase() + bitem.slice(1)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <br />
                      <br />
                      <TextField
                        label="Description"
                        id="description"
                        variant="outlined"
                        type="text"
                        margin="none"
                        value={newBaggageDescription}
                        onChange={e => {
                          setnewBaggageError("");
                          setnewBaggageDescription(e.target.value);
                        }}
                        fullWidth
                      />
                      <br />
                      <br />
                      {JSON.parse(orgSettings.baggageFields).includes(
                              "model"
                            ) && (
                              <>
                                <TextField
                                  label="Make and model"
                                  id="model"
                                  variant="outlined"
                                  type="text"
                                  margin="none"
                                  value={newBaggageModel}
                                  onChange={e => {
                                    setnewBaggageError("");
                                    setnewBaggageModel(e.target.value);
                                  }}
                                  fullWidth
                                />
                                <br />
                                <br />
                              </>
                            )}
                            {JSON.parse(orgSettings.baggageFields).includes(
                              "model"
                            ) && (
                              <>
                                <TextField
                                  label="Serial no."
                                  id="model"
                                  variant="outlined"
                                  type="text"
                                  margin="none"
                                  value={newBaggageSerial}
                                  onChange={e => {
                                    setnewBaggageError("");
                                    setnewBaggageSerial(e.target.value);
                                  }}
                                  fullWidth
                                />
                                <br />
                                <br />
                              </>
                            )}
                      <Button
                        color="primary"
                        variant="contained"
                        id="submit"
                        size="large"
                        fullWidth
                        onClick={e => {
                          if (
                            newBaggageName !== ""
                          ) {
                            setbaggage([
                              ...baggage,
                              {
                                name: newBaggageName,
                                description: newBaggageDescription,
                                model: newBaggageModel,
                                serial: newBaggageSerial
                              }
                            ]);
                            setnewBaggageName("");
                            setnewBaggageDescription("");
                            setnewBaggageModel("");
                            setnewBaggageSerial("");
                            setbaggageModal(false);
                          } else {
                            setnewBaggageError("Please fill all the fields");
                          }
                        }}
                      >
                        Add Baggage to List
                      </Button>
                      <br />
                      <br />
                    </DialogContent>
                  </Dialog>
                  <List dense>
                    {/* <Divider /> */}
                    {baggage.map((bag, index) => (
                      <ListItem divider key={index}>
                        <ListItemText
                          primary={
                            bag.name.charAt(0).toUpperCase() + bag.name.slice(1)
                          }
                          secondary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="body2"
                                color="textPrimary"
                              >
                                {bag.description}
                              </Typography>
                              {bag.model !== "" &&
                                  bag.model !== null &&
                                  " - " + bag.model}
                                {bag.serial !== "" &&
                                  bag.serial !== null &&
                                  " - " + bag.serial}
                            </React.Fragment>
                          }
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            onClick={e => {
                              setbaggage(baggage.filter((b, i) => i !== index));
                            }}
                          >
                            <X />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </>
              )}

            {orgSettings.baggageBusinessEnable && !isVisitor && (
              <>
                <Typography component="h2" gutterBottom variant="h4">
                  Baggage Details
                </Typography>
                <List dense>
                  <Divider />
                  {organizersBaggage.length === 0 && (
                    <ListItem divider>
                      <ListItemText
                        primary="No baggage"
                        secondary="Organizer has not added any baggage"
                      />
                    </ListItem>
                  )}
                  {organizersBaggage.map((bag, index) => (
                    <ListItem divider key={index}>
                      <ListItemText
                        primary={
                          bag.name.charAt(0).toUpperCase() + bag.name.slice(1)
                        }
                        secondary={
                          <React.Fragment>
                            <Typography
                              component="span"
                              variant="body2"
                              color="textPrimary"
                            >
                              {bag.description}
                            </Typography>
                            {bag.serial !== "" &&
                                  bag.serial !== null &&
                                  " - " + bag.serial}
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </>
            )}
          </Grid>
          {error !== "" && <Alert severity="error">{error}</Alert>}
          {status !== "" && (
            <Alert severity="success">
              {" "}
              Your have succesfully {status} the request
            </Alert>
          )}
          {(meeting.participants.find(item => item.id === query.participantId)
            .status === "pending") && (
            <ResponseComponent
              selectRoom={selectRoom}
              customMessage={orgSettings.customResponseMessage}
              roomList={roomList}
              accept={(room, message ) => {
                handleResponse("accepted", room, message ?? "");
              }}
              decline={data => {
                handleResponse("declined", data);
              }}
            />
          )}

          <Grid
            container
            direction="row"
            spacing={1}
            alignItems="center"
            justify="center"
          >
            <Grid item>
              <Typography variant="h5" color="primary">
                Powered by
              </Typography>
            </Grid>
            <Grid item>
              <img
                src="https://hipla.io/vectors/hipla-logo.svg"
                style={{ width: 100 }}
                alt="logo"
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

export default ResponseInterface;
