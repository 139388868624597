import {
  Chip,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  Grid,
  Button,
  Box,
  Divider
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/styles";
import { captureException } from "@sentry/browser";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { clearToastAction, toastAction } from "../../../../actions";
import api from "../../../../utils/api";
import OrderModal from "../OrderModal";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  nameCell: {
    display: "flex",
    alignItems: "center"
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: "flex-end"
  },
  capitalize: {
    textTransform: "capitalize"
  },
  chipButton: {
    paddingLeft: 12,
    paddingRight: 12
  },
  redButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.error.dark
    },
    "&:disabled": {
      backgroundColor: theme.palette.error.light
    }
  }
}));

const PantryOrders = props => {
  const { className, reload, ...rest } = props;
  const classes = useStyles();
  const [pantryOrders, setPantryOrders] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [pantryList, setPantryList] = useState([]);
  const [organizationList, setOrganizationList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [filters, setFilters] = useState({});
  const [currentSort, setCurrentSort] = useState({
    label: "",
    direction: "asc"
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [orderId, setOrderId] = useState({});
  const [query, setQuery] = useState({
    search: [],
    sort: { key: "pantryOrder.createdAt", order: "DESC" },
    skip: page * rowsPerPage,
    take: rowsPerPage
  });

  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const orgSettings = useSelector(state => {
    return state.orgSettings;
  });

  var columns = [
    {
      label: "pantry",
      dataType: "text",
      field: "pantry.name"
    },
    {
      label: "organization",
      dataType: "text",
      field: "organization.name"
    },
    {
      label: "Placed By",
      dataType: "text",
      field: "employee.name"
    },
    {
      label: "Placed From",
      dataType: "text",
      field: "room.name"
    },
    {
      label: "status",
      dataType: "text",
      field: "status"
    },
    // {
    //   label:"Date",
    //   dataType:"text",
    //   field:"createdAt"
    // }
  ];


  useEffect(() => {
    (async () => {
      handleQuery({ ...query, skip: page * rowsPerPage, take: rowsPerPage });
      setQuery({ ...query, skip: page * rowsPerPage, take: rowsPerPage });
    })();
  }, [page, rowsPerPage, modalOpen, reload]);

  useEffect(() => {
    (async () => {
      const pantry = await api(
        "POST",
        "pantry/find",
        {
          sort: {
            key: "pantry.name",
            order: "ASC"
          }
        },
        true
      );
      const res = pantry || [];
      setPantryList(res);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const organization = await api(
        "POST",
        "organization/find",
        {
          sort: {
            key: "organization.name",
            order: "ASC"
          }
        },
        true
      );
      const res = organization || [];
      setOrganizationList(res);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const employee = await api(
        "POST",
        "employee/find",
        {
          sort: {
            key: "employee.name",
            order: "ASC"
          }
        },
        true
      );
      const res = employee || [];
      setEmployeeList(res);
    })();
  }, []);

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setPage(0);
    setRowsPerPage(event.target.value);
  };

  const handleChangeFilter = (label, field, value) => {
    let allFilters = filters;

    if (value.trim() !== "") {
      allFilters[label] = value;
      setQuery({
        ...query,
        search: [
          ...query.search.filter(item => item.key !== "pantryOrder." + field),
          {
            key: "pantryOrder." + field,
            value: value,
            comparison: label === "organization" ? "=" : "LIKE"
          }
        ],
        skip: 0
      });
    } else if (allFilters.hasOwnProperty(label)) {
      setQuery({
        ...query,
        search: [
          ...query.search.filter(item => item.key !== "pantryOrder." + field)
        ],
        skip: 0
      });
      delete allFilters[label];
    }

    setFilters({ ...allFilters });
  };

  const handleDeleteChip = key => {
    let allFilters = filters;
    delete allFilters[key];
    var field = key === "phone number" ? "mobile" : key;
    setQuery({
      ...query,
      search: [...query.search.filter(item => item.key !== "pantryOrder." + field)],
      skip: 0
    });
    if (Object.keys(allFilters).length === 0) {
      handleQuery({
        ...query,
        search: []
      });
    }
    setFilters({ ...allFilters });
  };



  const handleEditRow = data => {
    setOrderId(data.id);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleSort = label => {
    var direction = "asc";
    var key = "pantryOrder." + (label === "phone number" ? "mobile" : label);
    if (currentSort.label === label && currentSort.direction === "asc")
      direction = "desc";
    const order = direction.toUpperCase();
    handleQuery({ ...query, sort: { key, order }, skip: 0 });
    setQuery({ ...query, sort: { key, order }, skip: 0 });
    setCurrentSort({ label, direction });
  };

  const handleQuery = async customQuery => {
    dispatch(
      toastAction({
        status: true,
        message: "Loading...",
        type: "info",
        noIcon: true
      })
    );
    try {
      const data = customQuery
        ? await api("POST", "pantry/order/find", customQuery, true)
        : await api("POST", "pantry/order/find", query, true);
      const countData = customQuery
        ? await api("POST", "pantry/order/findCount", customQuery, true)
        : await api("POST", "pantry/order/findCount", query, true);
      setCount(countData);
      let arr = []
      data.forEach(ele => {
        if(ele.room===null){
          ele["room"]="NA"
          // ele["createdAt"]=moment(parseInt(ele.createdAt)).format("DD-MM-YYYY");
          arr.push(ele)
        }
          // ele["createdAt"]=moment(parseInt(ele.createdAt)).format("DD-MM-YYYY");
        arr.push(ele)
      });
      setPantryOrders([...new Set(arr)]);
      dispatch(clearToastAction({}));
    } catch (error) {
      captureException(error);
      dispatch(clearToastAction({}));
    }
  };

  const handleReport = async () => {
    const orderReportUrl = await api(
      "POST",
      "pantry/order/findOrderReport",
      query,
      true
    );
    window.open(orderReportUrl, "_blank");
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Box px={1.5} py={1}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography color="textSecondary" variant="h6">
              {query.search.length === 0 ? "All Orders" : "Filtered results"}
            </Typography>
            <Typography color="textSecondary" gutterBottom variant="caption">
              {count} Results found. Page {page + 1} of{" "}
              {Math.ceil(count / rowsPerPage)}
            </Typography>
          </Grid>
          <Grid item>
            <Button onClick={handleReport} variant="outlined" color="primary">Download Report</Button>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <PerfectScrollbar>
        <div className={classes.inner}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((col, i) => (
                  <TableCell className={classes.capitalize} key={i}>
                    <TableSortLabel
                      active={currentSort.label === col.label}
                      direction={currentSort.direction}
                      onClick={() => handleSort(col.label)}
                    >
                      {col.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {columns.map((col, i) => (
                  <TableCell key={i} size="small" style={{ padding: 6 }}>
                    {
                      (col.label !== "pantry" && col.label !== "organization" && col.label !== "Placed By") ? (
                        <TextField
                          onChange={e => {
                            handleChangeFilter(
                              col.label,
                              col.field,
                              e.target.value
                            );
                          }}
                          size="small"
                          name={col.label}
                          placeholder={`Search by ${col.label}`}
                          variant="outlined"
                          value={filters[col.label] || ""}
                        />
                      )
                        :
                        col.label === "pantry"
                          ?
                          (
                            <FormControl
                              variant="outlined"
                              style={{ width: "100%" }}
                              size="small"
                            >
                              <Select
                                id="pantry"
                                value={filters[col.label] || ""}
                                displayEmpty
                                onChange={e =>
                                  handleChangeFilter(
                                    col.label,
                                    "pantry",
                                    e.target.value
                                  )
                                }
                                autoWidth
                              >
                                <MenuItem value="" disabled>
                                  Filter by Pantry
                                </MenuItem>
                                {pantryList.map((item, i) => (
                                  <MenuItem value={item.id} key={i}>{item.name}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )
                          :
                          col.label === "organization"
                            ?
                            (
                              <FormControl
                                variant="outlined"
                                style={{ width: "100%" }}
                                size="small"
                              >
                                <Select
                                  id="Organization"
                                  value={filters[col.label] || ""}
                                  displayEmpty
                                  onChange={e => {
                                    console.log(e.target);
                                    handleChangeFilter(
                                      col.label,
                                      "organization",
                                      e.target.value
                                    )
                                  }
                                  }
                                  autoWidth
                                >
                                  <MenuItem value="" disabled>
                                    Filter by Organization
                                  </MenuItem>
                                  {organizationList.map((item, i) => (
                                    <MenuItem value={item.id} key={i}>{item.name}</MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            )
                            :
                            (
                              <FormControl
                                variant="outlined"
                                style={{ width: "100%" }}
                                size="small"
                              >
                                <Select
                                  id="Employee"
                                  value={filters[col.label] || ""}
                                  displayEmpty
                                  onChange={e =>
                                    handleChangeFilter(
                                      col.label,
                                      "employeeId",
                                      e.target.value
                                    )
                                  }
                                  autoWidth
                                >
                                  <MenuItem value="" disabled>
                                    Filter by Employee
                                  </MenuItem>
                                  {employeeList.map((item, i) => (
                                    <MenuItem value={item.id} key={i}>{item.name}</MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            )
                    }
                  </TableCell>
                ))}
              </TableRow>
              {Object.keys(filters).length > 0 && (
                <>
                  <TableRow selected>
                    <TableCell colSpan={columns.length} align="center">
                      <Typography variant="h6" component="p">
                        Selected filters: &nbsp;
                      </Typography>
                      {Object.keys(filters).map((filter, i) => {
                        return (
                          <React.Fragment key={i}>
                            <Chip
                              variant="outlined"
                              color="primary"
                              onDelete={() => {
                                handleDeleteChip(filter);
                              }}
                              label={
                                <>
                                  <b className={classes.capitalize}>{filter}</b>
                                  :
                                  {
                                    (filter === "pantry") ? pantryList.find(item => item.id == filters[filter]).name :
                                      (filter === "organization") ? organizationList.find(item => item.id == filters[filter]).name :
                                        (filter === "Placed By") ? employeeList.find(item => item.id == filters[filter]).name :
                                          filters[filter]
                                  }
                                </>
                              }
                            />
                            &nbsp; &nbsp;
                          </React.Fragment>
                        );
                      })}
                      <Chip
                        color="primary"
                        icon={<SearchIcon />}
                        className={classes.chipButton}
                        clickable
                        onClick={() => {
                          handleQuery();
                        }}
                        label={<b>Search</b>}
                      />
                    </TableCell>
                  </TableRow>
                </>
              )}
              {pantryOrders.map(order => (

                <TableRow
                  hover
                  key={order.id}
                  onClick={() => handleEditRow(order)}
                >
                  {columns.map((col, i) => (
                    <TableCell key={i}>
                        {col.field.split(".").reduce((ox, ix) => ox[ix], order)}
                    </TableCell>
                  ))}
                </TableRow>
              )
              )}
            </TableBody>
          </Table>
        </div>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={count}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
      <OrderModal
        open={modalOpen}
        close={closeModal}
        type="pantry"
        orderId={orderId}
      />
    </div>
  );
};

PantryOrders.propTypes = {
  className: PropTypes.string,
  pantryOrders: PropTypes.array.isRequired,
  reload: PropTypes.bool
};

PantryOrders.defaultProps = {
  pantryOrders: []
};

export default PantryOrders;
