import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { captureException } from "@sentry/react";
import React, { useState } from "react";
import { PlusCircle } from "react-feather";
import { useDispatch } from "react-redux";
import api from "src/utils/api";
import { toastAction } from "../../../actions";
import Loader from "../../../utils/loader";
import ConfirmationDialog from "../common/ConfirmDialog";
import AddDeviceModal from "./AddDeviceModal";
import DeviceModal from "./DeviceModal";
import { Box, Divider } from "@material-ui/core";

const useStyles = makeStyles({
  root: {},
  table: {
    minWidth: 650
  },
  button: {
    marginBottom: 10
  }
});

export default function DeviceRegistration() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [deviceOpen, setDeviceOpen] = useState(false);
  const [isLoading, setLoader] = useState(true);
  const [isLoadingDelete, setDeleteLoader] = useState(false);
  const [floorList, setFloorList] = useState([]);
  const [roomList, setRoomList] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [countData, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeRowInfo, setActiveRow] = useState({});
  const [tat, setTat] = useState(false);
  const [error, setError] = useState("");
  React.useEffect(() => {
    (async () => {
      const countPayload = {
        sort: { key: "device.passcode", order: "ASC" },
        skip: 0,
        take: 10
      };
      const room = await api("POST", "room/find", null, true);
      setRoomList(room);
      const floor = await api("POST", "floor/find", null, true);
      const deviceCount = await api(
        "POST",
        "device/findCount",
        countPayload,
        true
      );
      Promise.all([floor, deviceCount])
        .then(data => {
          setFloorList(data[0]);
          setCount(data[1]);
        })
        .catch(error => {
          captureException(error);
          setLoader(false);
        });
    })();
  }, []);

  const deviceCount = async () => {
    try {
      const countPayload = {
        sort: { key: "device.passcode", order: "ASC" },
        skip: 0,
        take: 10
      };
      const res = await api("POST", "device/findCount", countPayload, true);
      setCount(res);
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(
            toastAction({
                status: true,
                message: error.response.data.message,
                type: "error"
            })
        )
        setError(error.response.data);
    }
  }
  };

  React.useEffect(() => {
    fetchData();
  }, [rowsPerPage, page, deviceOpen]);

  const fetchData = async () => {
    setLoader(true);
    try {
      const payload = {
        sort: {
          key: "device.name",
          order: "ASC"
        },
        skip: page * rowsPerPage,
        take: rowsPerPage
      };
      try {
        const building = await api("POST", "device/find", payload, true);
        const res = building || [];
        setDataList(res);
        setLoader(false);
      } catch (error) {
        if (error.response && error.response.data) {
          dispatch(
              toastAction({
                  status: true,
                  message: error.response.data.message,
                  type: "error"
              })
          )
          setError(error.response.data);
      }
        captureException(error);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleModal = () => {
    setIsOpen(true);
  };

  const handleDevicemodal = id => {
    setIndex(id);
    setDeviceOpen(true);
  };

  const handleClick = (event, info) => {
    setAnchorEl(event.currentTarget);
    setActiveRow(info);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setActiveRow({});
  };

  // console.log("activeRowInfo",activeRowInfo);

  const activeAction = async (status) => {
    if (activeRowInfo && activeRowInfo.passcode) {
      let body = {
        category: activeRowInfo.category,
        isActive: status
    };
      setLoader(true);
      try {
        const res = await api(
          "PATCH",
          `device/${activeRowInfo.passcode}`,
          body,
          true
        );

        if (res.passcode) {
          const ind = dataList.findIndex(l => l.passcode === res.passcode);
          dataList[ind].isActive = status;
          setDataList(dataList);
          setLoader(false);
          setOpen(false);
          setAnchorEl(null);
          dispatch(
            toastAction({
              status: true,
              message: "Status update successfully",
              type: "success"
            })
          );
        }
      } catch (error) {
        if (error.response && error.response.data) {
          dispatch(
              toastAction({
                  status: true,
                  message: error.response.data.message,
                  type: "error"
              })
          )
          setError(error.response.data);
      }
        setLoader(false);
      }
    }
  };

  const deleteItem = async () => {
    if (activeRowInfo && activeRowInfo.passcode) {
      setDeleteLoader(true);
      try {
        const res = await api(
          "DELETE",
          `device/${activeRowInfo.passcode}`,
          null,
          true
        );
        if (res.passcode) {
          handleClose();
          setOpen(false);
          fetchData();
          deviceCount();
          setDeleteLoader(false);
          setAnchorEl(null);
        }
      } catch (error) {
        if (error.response && error.response.data) {
          dispatch(
              toastAction({
                  status: true,
                  message: error.response.data.message,
                  type: "error"
              })
          )
          setError(error.response.data);
      }
        setDeleteLoader(false);
      }
    }
  };

  //enable TAT API
  const enableTat = async () => {
    if (activeRowInfo && activeRowInfo.id) {
      try {
        await api("PATCH", "device/tatToggle/" + activeRowInfo.id, null, true);
        dispatch(
          toastAction({
            status: true,
            message: "Status update successfully",
            type: "success"
          })
        );
        fetchData();
        setAnchorEl(null);
      } catch (error) {
        if (error.response && error.response.data) {
          dispatch(
              toastAction({
                  status: true,
                  message: error.response.data.message,
                  type: "error"
              })
          )
          setError(error.response.data);
      }
      }
    }
  };
  const regeneratePasscode = async () => {
    if (activeRowInfo && activeRowInfo.passcode) {
      setLoader(true);
      try {
        const res = await api(
          "PATCH",
          `device/regeneratePasscode/${activeRowInfo.passcode}`,
          null,
          true
        );
        if (res.passcode) {
          const ind = dataList.findIndex(l => l.apiKey === res.apiKey);
          dataList[ind].passcode = res.passcode;
          setDataList(dataList);
          setLoader(false);
          setAnchorEl(null);
          dispatch(
            toastAction({
              status: true,
              message: "Passcode regenerate successfully",
              type: "success"
            })
          );
        }
      } catch (error) {
        if (error.response && error.response.data) {
          dispatch(
              toastAction({
                  status: true,
                  message: error.response.data.message,
                  type: "error"
              })
          )
          setError(error.response.data);
      }
        setLoader(false);
      }
    }
  };

  const DialogButtons = (
    <React.Fragment>
      <Button variant="outlined" color="primary" onClick={() => setOpen(false)}>
        NO
      </Button>
      <Button variant="contained" onClick={deleteItem} color="primary">
        {isLoadingDelete ? "Loading..." : "Yes delete"}
      </Button>
    </React.Fragment>
  );

  const handleReport = async () => {
    const deviceReportUrl = await api("POST", "device/findDeviceReport");
    window.open(deviceReportUrl, "_blank");
  };

  if (isLoading) {
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <Loader />
          </Grid>
          <Grid item xs={4} />
        </Grid>
      </React.Fragment>
    );
  }

  if (deviceOpen)
    return (
      <DeviceModal
        open={deviceOpen}
        dataList={dataList[index]}
        roomList={roomList}
        close={() => setDeviceOpen(false)}
      />
    );

     if (isOpen)
      return (
      <DeviceModal
        open={isOpen}
        deviceCount={deviceCount}
        fetchData={fetchData}
        floorList={floorList}
        roomList={roomList}
        close={() => setIsOpen(false)}
      />
    );

  return (
    <React.Fragment>
      <Grid
        alignItems="flex-start"
        container
        justify="space-between"
        spacing={3}
        direction="row"
      >
        <Grid item>
          <Typography variant="h4" color="primary">
            Device Registration
          </Typography>
        </Grid>

        <Grid item>
          <Button
            color="primary"
            className={classes.button}
            startIcon={<PlusCircle />}
            variant="outlined"
            onClick={handleModal}
          >
            Add New Device
          </Button>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Grid item>
          <Button onClick={handleReport} variant="outlined" color="primary">
            Download Report
          </Button>
        </Grid>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Sl No</TableCell>
              <TableCell align="center">Device Name</TableCell>
              <TableCell align="center">Category</TableCell>
              <TableCell align="center">Subcategory</TableCell>
              {/* <TableCell align="center">Pass key</TableCell> */}
              {/* <TableCell align="center">Mac ID</TableCell> */}
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataList.map((row, ind) => (
              <TableRow key={ind}>
                <TableCell align="center">
                  {rowsPerPage * page - 1 + ind + 2 || ""}
                </TableCell>
                <TableCell align="center">{row.name || "-"}</TableCell>
                <TableCell align="center">{row.category || "-"}</TableCell>

                <TableCell align="center">
                  {row.subCategory[0] || "-"}
                </TableCell>
                {/* <TableCell align="center">{row.passcode || "-"}</TableCell> */}
                {/* <TableCell align="center">{row.macAddress || "-"}</TableCell> */}
                <TableCell align="center">
                  {row.isActive ? "Active" : "InActive"}
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    aria-label="edit"
                    onClick={event => handleClick(event, row)}
                  >
                    <MoreVertIcon fontSize="small" />
                  </IconButton>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleDevicemodal(ind)}
                  >
                    Details
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={countData}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
      {/* {isOpen && (
        <AddDeviceModal
          open={isOpen}
          deviceCount={deviceCount}
          fetchData={fetchData}
          floorList={floorList}
          roomList={roomList}
          close={() => setIsOpen(false)}
        />
      )} */}

      {/* {deviceOpen && (
        <DeviceModal
          floorList={floorList}
          open={deviceOpen}
          roomList={roomList}
          dataList={dataList[index]}
          close={() => setDeviceOpen(false)}
        />
      )} */}
      {open && (
        <ConfirmationDialog
          open={open}
          error={null}
          handleClose={() => setOpen(false)}
          title={"Delete device"}
          content={"Are you sure you want to delete device?"}
          btnActions={DialogButtons}
        />
      )}
      {Boolean(anchorEl) && (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {!(activeRowInfo && activeRowInfo.isActive) && (
            <MenuItem onClick={() => activeAction(true)}>Activate</MenuItem>
          )}
          {activeRowInfo && activeRowInfo.isActive && (
            <MenuItem onClick={() => activeAction(false)}>
              Reset Device
            </MenuItem>
          )}
          <MenuItem onClick={() => regeneratePasscode()}>
            Regenerate Passcode
          </MenuItem>
          <MenuItem onClick={() => setOpen(true)}>Delete</MenuItem>
          <MenuItem onClick={enableTat}>
            {activeRowInfo.isTatActive ? "Disable" : "Enable"} TAT
          </MenuItem>
        </Menu>
      )}
    </React.Fragment>
  );
}
