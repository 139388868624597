import { Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, Button,Box, CircularProgress, TextField, FormControl, MenuItem, Select, CardContent, Card } from "@material-ui/core";
import { KeyboardBackspace } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import api from "src/utils/api";
import moment from "moment-timezone";
import {getAccessLog} from "src/utils/reporting-api"
import { search } from "core-js/fn/symbol";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import DownloadIcon from '';
// import { Button } from "react-simple-wysiwyg";



const AccessLog = ({close}) =>{

  // const [employeeName, setEmployeeName] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [accessLogData, setAccessLogData] = useState([]);
  const [accessLogPagination, setAccessLogPagination] = useState([]);
  const [accessLogDownload, setAccessLogDownload] = useState([]);
  const [roomsList, setRoomList] = useState([]);
  const [isLoading, setLoader] = useState(true);
  const user = useSelector(state => state.user);
  const [suggestionList, setSuggestionList] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [openSuggestion, setopenSuggestion] = useState(false);
  const [loading, setloading] = useState(false);
  const [userNameFiltered,setUserNameFiltered] = useState("");
  const [roomNameList, setRoomNameList] = useState([]);
  const [filterRoomType, setFilterRoomType] = useState("");

 

  useEffect(()=>{
    fetchRoomData();
  },[user])

  useEffect(()=>{
    fetchAccessLog()
  },[currentPage,pageSize,roomsList,userNameFiltered,filterRoomType])

  useEffect(()=>{
    fetchLogDownload();
  },[roomsList])


  const fetchRoomData = async () => {
    setLoader(true);
    try {
      const floor = await api("POST", "floor/find", null, true);
      const payload = {
        search: [
          {"key":"room.floorId","value":floor.map(f => f.id),"comparison":"IN"}
        ],
      };
      const building = await api("POST", "room/find", payload, true);
      const res = building || [];
      setRoomNameList(res);
      setRoomList(res.map(f=>f.id));
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };


  // console.log("roomsList",roomNameList.map(row=>row.name));


  useEffect(() => {
    if (openSuggestion) {
      (async () => {
        try {
          let dataEmployee = await api(
            "POST",
            "employee/find",
            {
              search: [
                {
                  key: "employee.name",
                  value: searchInput,
                  comparison: "LIKE"
                }
              ],
              sort: {
                key: "employee.name",
                order: "DESC"
              },
              skip: 0,
              take: 10
            },
            true
          );
          let dataStaff = await api(
            "POST",
            "staff/find",
            {
              search: [
                {
                  key: "staff.name",
                  value: searchInput,
                  comparison: "LIKE"
                }
              ],
              sort: {
                key: "staff.name",
                order: "DESC"
              },
              skip: 0,
              take: 10
            },
            true
          );
          // console.log(data);
          setSuggestionList([...dataEmployee, ...dataStaff]);
        } catch (error) {
          console.log(error);
        }
      })();
    } else {
      setSuggestionList([]);
    }
  }, [searchInput]);


  const fetchLogDownload = async () =>
  {
    let query = `currentPage=${1}&pageSize=${100}`;
    query = "&eventType=AUDIT_ACL";
    query += `&inKey=premiseId&inValue=${[JSON.stringify(roomsList)]}`
    query += `&order={"id": "DESC"}`;
    const download = await getAccessLog(query);
    let downloadData = [];
    download.data.records.map(d=>{
      let row = {};
      row["id"] = d.id;
      row["User Name"] = d.userName;
      row["User Type"] = d.userType;
      row["Room Name"] = d.premiseName;
      row["Device Name"] = d.deviceName;
      row["Device Category"] = d.deviceCateogry;
      row["Device Sub-Category"]= d.deviceSubcateogry;
      row["Source"] = d.source;
      row["Access Medium"] = d.accessMedium;
      row["isApproved"] = d.isApproved;
      row["Access Time & Date"] = moment.utc(d.createdAt).local().format("HH:mm, Do MMM YYYY");
      downloadData.push(row);
    })
    setAccessLogDownload(downloadData)

  }


  const fetchAccessLog = async () =>
  {
    try
    {
      let query = `currentPage=${currentPage}&pageSize=${pageSize}`;
      query += "&eventType=AUDIT_ACL";
      query += `&inKey=premiseId&inValue=${[JSON.stringify(roomsList)]}`
      query += `&order={"id": "DESC"}`;
      query += `&likes=[{"userName":"${userNameFiltered}"},{"premiseName":"${filterRoomType}"}]`;
      const res= await getAccessLog(query);
      setAccessLogPagination(res.data.pagination.total)
      setAccessLogData(res.data.records);
     
    }
    catch (error)
    {
      console.log(error)
    }
  }

    const handlePageChange = (event, newPage) => {
      onPaginate(pageSize, newPage + 1);
    };
  
    const handleRowsPerPageChange = (event, newRowsPerPage) => {
      onPaginate(event.target.value, currentPage);
    };


    const onPaginate = (size, page) => {
      setPageSize(size);
      if (size !== pageSize) {
        setCurrentPage(1);
      } else {
        setCurrentPage(page);
      }
    };


    return (
        <>
        <Card>
          <CardContent>
          <Grid
        alignItems="center"
        container
        spacing={1}
        justify="space-between"
        direction="row"
      >
        <Grid item xs={2}>
        <Autocomplete
            open={openSuggestion}
            options={suggestionList}
            noOptionsText={"No user found"}
            onOpen={() => {
              setopenSuggestion(true);
            }}
            onClose={() => {
              setopenSuggestion(false);
            }}
            onChange={(event, value)=>{
              console.log("value",value);
              if(value)
              {
                setUserNameFiltered(value?.name);
              }
              else
              {
                setUserNameFiltered("")
              }
            }}
            getOptionLabel={option => {
              return option.name;
            }}
            renderInput={params => (
              <TextField
                {...params}
                size="small"
                placeholder="Search by Name"
                fullWidth
                variant="outlined"
                value={searchInput}
                onChange={e => setSearchInput(e.target.value)}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  )
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={8}>
            <Select
              variant="standard"
              defaultValue=""
              displayEmpty
              value={filterRoomType}
              onChange={e=>{
                if(e.target.value == "")
                {
                  setFilterRoomType("");
                }
                else
                {
                  // console.log("value",e.target.value);
                  setFilterRoomType(e.target.value);

                }
              }}
            >
              <MenuItem value="">
                <em>Filter By Room</em>
              </MenuItem>
                {roomNameList.map(row => {
                    return (
                      <MenuItem
                        value={row.name}
                        key={row.id}
                      >
                        {row.name}
                      </MenuItem>
                    );
                  })}
            </Select>
        </Grid>
        <Grid item>
          <Button variant="outlined" >
            <CSVLink  data={accessLogDownload}>Download Logs</CSVLink>
          </Button>
        </Grid>
        </Grid>
          </CardContent>
        </Card>
        <br/>
        <Card>
          <CardContent>
        <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">User Name</TableCell>
              <TableCell align="left">User Type</TableCell>
              <TableCell align="left">Room Name</TableCell>
              <TableCell align="left">Device Name</TableCell>
              <TableCell align="left">Device Category</TableCell>
              <TableCell align="left">Device Sub-Cat</TableCell>
              <TableCell align="left">Access Medium</TableCell>
              <TableCell align="left">Access Time and Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {accessLogData.map((row, ind) => (
              <TableRow key={ind}>
                <TableCell align="left">{row.userName}</TableCell>
                <TableCell align="left">{row.userType}</TableCell>
                <TableCell align="left">{row.premiseName}</TableCell>
                <TableCell align="left">{row.deviceName}</TableCell>
                <TableCell align="left">{row.deviceCateogry}</TableCell>
                <TableCell align="left">{row.deviceSubcateogry}</TableCell>
                <TableCell align="left">{row.accessMedium}</TableCell>
                <TableCell align="left">{moment.utc(row.createdAt).local().format("HH:mm, Do MMM YYYY")}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </CardContent>
       </Card>
      <br/>
      {accessLogData.length===0 && (
              <Card>
              <CardContent>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <h3>No access log available in this room!!!</h3>
                </Box>
                </CardContent>
             </Card>
            )}
      <TablePagination
          component="div"
          count={accessLogPagination}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={currentPage-1}
          rowsPerPage={pageSize}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </>
    );
}
export default AccessLog;