import {
  FormControl,
  makeStyles,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-phone-input-2/lib/material.css";
import { clearToastAction, toastAction } from "../../../../actions";
import api from "../../../../utils/api";

const useStyles = makeStyles(theme => ({
  root: {},
  marginNormal: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}));

const AddItemModelPage = props => {
  const { open, close, type, pantryItemData } = props;
  const classes = useStyles();
  const user = useSelector(state => {
    return state.user;
  });
  const org = useSelector(state => {
    return state.org;
  });
  const orgSettings = useSelector(state => {
    return state.orgSettings;
  });

  const [stockId, setStockId] = useState("");
  const [value, setValue] = useState("");
  const [sort, setSort] = useState();
  const [name, setName] = useState();
  const [status, setStatus] = useState("");
  const [available, setAvailable] = useState(`${status}`);
  const [sortvalue, setSortValue] = useState(`${sort}`);
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const handleClose = (refresh) => {
    close(refresh);
  };
  useEffect(() => {
    if (pantryItemData) {
      setStockId(pantryItemData.stockId ?? "");
      setValue(pantryItemData.stock ?? "");
      setSort(pantryItemData.sort ?? "");
      setName(pantryItemData.name ?? "");
      setStatus(pantryItemData.status ?? false);
      setAvailable(pantryItemData.isAvailable ?? false);
      setSortValue(pantryItemData.sort ?? 0);

    }
  }, [pantryItemData]);

  const handleUpdatePantry = async () => {
    dispatch(
      toastAction({ status: true, message: "Updating Pantry", type: "success" })
    );
    setError("");
    (async () => {
      try {
        await api(
            "PATCH",
            `pantry/stock/${stockId}`,
            {
              stock: value,
              status: available,
              displayOrder: `${sortvalue}`
            },
            true
          );
      dispatch(clearToastAction({}));
      handleClose(true);
    } catch (error) {
      error.response && error.response.data && setError(error.response.data);
      dispatch(clearToastAction({}));
    }
  })()
  };
  const handlechangeAvailablity = value => {
    setAvailable(value);
  };

  const handleDelete = async (refresh) => {
      await api("DELETE", `pantry/item/${pantryItemData.id}`,null,true)
      close(refresh);
  }



  return (
    <React.Fragment>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Edit Pantry Item
        </DialogTitle>
        <DialogContent dividers>
          <FormControl fullWidth>
            <TextField
              label="Item*"
              type="text"
              defaultValue={name}
              inputProps={{ readOnly: true }}
              variant="outlined"
            ></TextField>
          </FormControl>
          <br />
          <br />
          <FormControl fullWidth>
            <TextField
              type="number"
              id="demo-simple-select"
              fullWidth
              label="Quantity*"
              variant="outlined"
              value={value}
              InputProps={{
                inputProps: { min: 0 }
              }}
              onChange={e => {
                setValue(e.target.value);
              }}
            ></TextField>
          </FormControl>
          <br />
          <br />
          <FormControl fullWidth>
            <TextField
              id="demo-simple-select"
              type="number"
              label="Display Order*"
              variant="outlined"
              value={sortvalue}
              InputProps={{
                inputProps: { min: 0 }
              }}
              onChange={e => {
                setSortValue(e.target.value);
              }}
            ></TextField>
            <br />
          </FormControl>
          <div>
            <RadioGroup
              aria-label="status"
              name="status"
              className={classes.group}
              value={available}
              onChange={e => handlechangeAvailablity(e.target.value)}
            >
              <div>
                <FormControlLabel
                  value="available"
                  control={<Radio color="primary" />}
                  label="Available"
                  labelPlacement="start"
                />
                <FormControlLabel
                  value="unavailable"
                  control={<Radio color="primary" />}
                  label="Unavailable"
                  labelPlacement="start"
                />
              </div>
            </RadioGroup>
          </div>
          <Grid container spacing={3} direction="row-reverse" sx={{ pt: 1 }}>
            <Grid item>
              <Button
                autoFocus
                onClick={() => {
                  handleUpdatePantry();
                }}
                variant="contained"
                color="primary"
              >
                Update
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={handleDelete}
                variant="contained"
                color="secondary">
                Archive
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="text"
                color="error"
                size="large"
                onClick={handleClose}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default AddItemModelPage;
