import { Divider, Grid, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/material.css";
import api from "../../utils/api";
import ResponseComponent from "../responseInterface/ResponseComponent";

const ResponseModal = props => {
  const { open, close, participantId, selectRoom } = props;
  const [loading, setLoading] = useState(false);
  const [roomList, setRoomList] = useState(false);
  const [error, setError] = useState("");
  const [status, setStatus] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const res = await api(
          "POST",
          "room/find",
          {
            search: [],
            sort: { key: "room.createdAt", order: "ASC" },
            skip: 0,
            take: 10
          },
          true
        );
        let availablerooms = res.filter(ele => ele.isActive && ele.premiseType === "meetingRoom");
        setRoomList(availablerooms);
        setRoomList(availablerooms);
      } catch (error) {}
    })();
  }, []);

  const handleResponse = async (response, room) => {
    setLoading(true);
    setError("");
    setStatus("");
    try {
      let data = await api(
        "PATCH",
        "meeting/updateParticipantStatus/" + participantId,
        room
          ? {
              status: response,
              roomId: room
            }
          : {
              status: response
            },
        true
      );
      setStatus(data.status);
      console.log(data.status);
      setTimeout(() => {
        close(true);
      }, 1000);
    } catch (error) {
      setError(error.response.data);
    }
    setLoading(false);
  };

  const handleClose = () => {
    close();
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle disableTypography>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Typography component="h1" variant="h3" color="primary">
              Approve Meeting
            </Typography>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent>
          {error !== "" && <Alert severity="error">{error}</Alert>}
          {status !== "" && (
            <Alert severity="success">
              Your have succesfully {status} the request
            </Alert>
          )}
          {status === "" && (
            <ResponseComponent
              selectRoom={selectRoom}
              roomList={roomList}
              accept={(room, message ) => {
                handleResponse("accepted", room);
              }}
              decline={room => {
                handleResponse("declined");
              }}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ResponseModal;
