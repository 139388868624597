import {
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  FormControlLabel,
  Checkbox,
  Link
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import { captureException } from "@sentry/browser";
import QRcode from "qrcode";
import React, { useEffect, useState } from "react";
import { X } from "react-feather";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { useDispatch, useSelector } from "react-redux";
import { business, clearToastAction, toastAction } from "../../../../actions";
import api from "../../../../utils/api";
import rolename from "../../../../utils/rolename";

const useStyles = makeStyles(theme => ({
  root: {},
  marginNormal: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}));

const AddModal = props => {
  const { open, close, type, userData } = props;
  const classes = useStyles();
  const user = useSelector(state => {
    return state.user;
  });
  const org = useSelector(state => {
    return state.org;
  });
  const [addedId, setAddedId] = useState("");
  const [id, setId] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [designation, setDesignation] = useState("");
  const [isHod, setIsHod] = useState(false);
  const [department, setDepartment] = useState("");
  const [company, setCompany] = useState("");
  const [organizationList, setOrganizationList] = useState([]);
  const [organization, setOrganization] = useState("");
  const [buildings, setBuildings] = useState([]);
  const [building, setBuilding] = useState("");
  const [role, setRole] = useState("employee");
  const [error, setError] = useState("");
  const [qrUrl, setQrUrl] = useState("");
  
  const orgSettings = useSelector(state => {
    return state.orgSettings;
  });
  
  // console.log("type",type);

  const dispatch = useDispatch();
  
  useEffect(() => {
    (async () => {
      if (organization !== "" && (type === "employee" || type === "staff" || type === "admin")) {
        const data = await api(
          "POST",
          "building/find",
          {
            search: [
              {
                key: "organization.organizationId",
                value: organization,
                comparison: "="
              },
              {"key":"organization.isActive","value":"true","comparison":"="}
            ],
            sort: { key: "building.createdAt", order: "ASC" }
          },
          true
          );
          setBuildings(data);
        }
      })();
    }, [organization]);
    
    useEffect(() => {
      (async () => {
        try {
          if (user.role === "businessAdmin") {
            const organizations = await api(
              "POST",
              "organization/find",
              {
                sort: {
                  key: "organization.name",
                  order: "ASC"
                }
              },
              true
              );
              setOrganizationList(organizations.filter(org => org.isActive));
            } else {
              const data = await api(
                "POST",
                "building/find",
                {
                  search: [
                    {
                      key: "organization.organizationId",
                      value: organization,
                      comparison: "="
                    },
                    {"key":"organization.isActive","value":"true","comparison":"="}
                  ],
                  sort: { key: "building.createdAt", order: "ASC" }
                },
                true
                );
                setBuildings(data);
              }
            } catch (error) {
              captureException(error);
            }
          })();
        }, [open]);
        
  useEffect(() => {
    if (userData && userData.id) {
      setId(userData.id);
      setEmail(userData.email || "");
      setName(userData.name || "");
      setMobile(userData.mobile || "");
      setCountryCode(userData.countryCode || "");
      setBuilding(userData.building || "");
      setDesignation(userData.designation || "");
      setDepartment(userData.department || "");
      setRole(userData.role || "");
      setCompany(userData.company || "");
      setOrganization(userData.organization.id || "");
      setIsHod(userData.isHod || false);
    }
  }, [userData]);

  const handleClose = id => {
    setEmail("");
    setName("");
    setMobile("");
    setBuilding("");
    setDesignation("");
    setDepartment("");
    setRole("");
    setCompany("");
    setOrganization("");
    setIsHod(false);
    setError("");
    close(id);
  };

  const handleAdd = () => {
    setError("");
    var body;
    if (type === "employee" ) {
      body = {
        email,
        name,
        mobile,
        countryCode,
        department,
        designation,
        building,
        isHod,
        role,
        organizationId: org && org.id ? org.id : organization
      };
    } else if (type === "staff") {
      body = {
        email,
        name,
        mobile,
        countryCode,
        organizationId: org && org.id ? org.id : organization
      };
    } else {
      body = {
        email,
        name,
        mobile,
        countryCode,
        company,
        organizationId: org && org.id ? org.id : organization
      };
    }

    for (const [key, value] of Object.entries(body)) {
      if (value === "") delete body[key];
    }

    dispatch(
      toastAction({
        status: true,
        message: "Adding " + type + "...",
        type: "info",
        noIcon: true
      })
    );
    (async () => {
      try {
        let res = await api("POST", type, body, true);
        if (type === "employee") {
          setAddedId(res.id);
        }
        if (type === "staff") {
          setAddedId(res.id);
        }
        dispatch(
          toastAction({
            status: true,
            message: "Successfully added " + type,
            type: "success"
          })
        );
        handleClose(res.id);
      } catch (error) {
        dispatch(
          toastAction({
            status: true,
            message: "Something went wrong ",
            type: "error"
          })
        );
        error.response && error.response.data && setError(error.response.data);
      }
    })();
  };

  const handleUpdate = () => {
    dispatch(
      toastAction({ status: true, message: "Updating user", type: "success" })
    );
    setError("");
    var body;
    if (type === "employee" || type === "admin") {
      body = {
        email,
        name,
        mobile,
        countryCode,
        department,
        designation,
        building,
        role,
        isHod,
        organizationId: org && org.id ? org.id : organization
      };
    } else if (type === "staff") {
      body = {
        email,
        name,
        mobile,
        countryCode,
        organizationId: org && org.id ? org.id : organization
      };
    }
     else {
      body = {
        email,
        name,
        mobile,
        countryCode,
        organizationId: org && org.id ? org.id : organization
      };
    }
    console.log(body);
    console.log(organization);
    for (const [key, value] of Object.entries(body)) {
      if (value === "") delete body[key];
    }
    // console.log(body);

    (async () => {
      try {
        await api("PATCH", `${type}/${userData.id}`, body, true);
        dispatch(clearToastAction({}));
        if (type === "employee") {
          handleClose(userData.id);
        } else {
          handleClose();
        }
      } catch (error) {
        error.response && error.response.data && setError(error.response.data);
        dispatch(clearToastAction({}));
      }
    })();
  };
  const handleDelete = () => {
    setError("");
    const body =
      type === "employee" || type === "staff"
        ? {
            isActive: !userData.isActive
          }
        : {
            isActive: false,
            organizationId: user.organizationId
          };
    (async () => {
      try {
        await api("PATCH", `${type}/${userData.id}`, body, true);
        handleClose();
      } catch (error) {
        captureException(error);
        error.response && error.response.data && setError(error.response.data);
      }
    })();
  };
  const qrCodeDownload = async() => {
    try {
      const result = await QRcode.toDataURL(`${window.location.origin}/visitingCard/${id}`)
      setQrUrl(result)
    } catch (error) {
      console.log(error);
    }
  };

  // console.log("userData",userData);


  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle disableTypography>
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={3}
            className={classes.marginNormal}
          >
            <Grid item>
            <Typography component="h1" variant="h3" color="primary">
              {userData ? "Edit" : "Add new "} {type}
            </Typography>
            </Grid>
            <Grid item>
            {type === "employee" || type === "admin" ? (
              <Grid
              container
              direction="row"
              alignItems="center"
              spacing={2}
              className={classes.marginNormal}>
                <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  href={`/visitingCard/${id}`}
                  target="_blank"
                >
                  Visiting Card
                </Button>
                  </Grid>
                 <Grid item>
                 <Button onClick={()=>qrCodeDownload()} variant="contained" color="primary" >                
                  <Link href={qrUrl} download="qrcode.png" color="inherit"  underline="none">
                    Download QR
                  </Link>
                </Button>
                 </Grid>
              </Grid>
            ) : null}
          </Grid>
          <Grid item>
          <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
           
          </Grid>
        </DialogTitle>
        <DialogContent>
          {!userData && (
            <DialogContentText>
              To add a new {type} fill up the following details and click on the
              'Next' button. We will generate a password and notify the new{" "}
              {type}.
            </DialogContentText>
          )}
          {error !== "" && (
            <Alert
              severity="error"
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setError("");
                  }}
                >
                  <X />
                </Button>
              }
            >
              {error}
            </Alert>
          )}
          <TextField
            label="Full name"
            variant="outlined"
            type="text"
            margin="normal"
            required
            fullWidth
            value={name}
            onChange={e => {
              setName(e.target.value);
            }}
          />
          <TextField
            label="Email address"
            variant="outlined"
            type="email"
            margin="normal"
            required={JSON.parse(
              orgSettings[`${type}RequiredFields`] || "[]"
            ).includes("email")}
            fullWidth
            value={email}
            onChange={e => {
              setEmail(e.target.value);
            }}
          />

          <PhoneInput
            enableSearch
            country={org?.countrycode ?? "in"}
            preferredCountries={["sg", "in", "us"]}
            inputStyle={{ width: "100%" }}
            searchClass="MuiOutlinedInput-input MuiOutlinedInput-inputMarginDense"
            specialLabel={
              JSON.parse(orgSettings[`${type}RequiredFields`] || "[]").includes(
                "mobile"
              )
                ? "Phone number *"
                : "Phone number"
            }
            countryCodeEditable={false}
            value={countryCode.replace("+", "") + mobile}
            onChange={(value, country) => {
              setCountryCode("+" + country.dialCode);
              setMobile(value.replace(country.dialCode, ""));
            }}
          />
          {(type === "employee" || type === "admin") && (
            <TextField
              label="Department"
              variant="outlined"
              type="text"
              margin="normal"
              fullWidth
              value={department}
              onChange={e => {
                setDepartment(e.target.value);
              }}
            />
          )}
          {type === "employee"  || type === "admin" ?
            <FormControlLabel
              control={
                <Checkbox
                  checked={isHod}
                  onChange={e => {
                    setIsHod(e.target.checked);
                  }}
                  name="trailingMeetingSkipOnOverlap"
                />
              }
              label="Head of the department"
            />
          : null }
          
          {type === "employee"  || type === "admin" ?
            <TextField
              label="Designation"
              variant="outlined"
              type="text"
              margin="normal"
              fullWidth
              value={designation}
              onChange={e => {
                setDesignation(e.target.value);
              }}
            />
          : null}

          {user.role === "businessAdmin" && (
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              className={classes.marginNormal}
            >
              <InputLabel id="organization"> Organization </InputLabel>
              <Select
                labelId="organization"
                id="organization"
                label="Organization "
                value={organization}
                displayEmpty
                onChange={e => {
                  setOrganization(e.target.value);
                }}
                autoWidth
              >
                {organizationList.map(organization => {
                  return (
                    <MenuItem value={organization.id} key={organization.id}>
                      {organization.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}

          {type === "employee"  || type === "admin" ?
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              className={classes.marginNormal}
            >
              <InputLabel id="building"> Building</InputLabel>
              <Select
                labelId="building"
                id="building"
                label="Building "
                value={building}
                onChange={e => {
                  setBuilding(e.target.value);
                }}
                autoWidth
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {buildings.map(building => {
                  return (
                    <MenuItem value={building.id} key={building.id}>
                      {building.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          : null}
          {
          type === "employee" && (
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              className={classes.marginNormal}
            >
              <InputLabel id="role"> Role</InputLabel>
              <Select
                labelId="role"
                id="role"
                label="Role "
                value={role}
                onChange={e => {
                  setRole(e.target.value);
                }}
                autoWidth
              >
                {user.role === "businessAdmin" && 
                  <MenuItem value="superadmin">
                    {rolename("superadmin")}
                  </MenuItem>
                  }
                {user.role != "businessAdmin" && 
                <MenuItem value="employee">{rolename("employee")}</MenuItem>
                 }
                 {user.role != "businessAdmin" && 
                <MenuItem value="admin">{rolename("admin")}</MenuItem>
                 }
                 {user.role != "businessAdmin" && 
                <MenuItem value="reception">{rolename("reception")}</MenuItem>
                 }
              </Select>
            </FormControl>
          )}

          {type === "visitor" &&
            JSON.parse(orgSettings.visitorAdditionalFields).includes(
              "company"
            ) && (
              <TextField
                label="Company"
                variant="outlined"
                type="text"
                margin="normal"
                required
                fullWidth
                value={company}
                onChange={e => {
                  setCompany(e.target.value);
                }}
              />
            )}

          <Grid
            container
            direction="row-reverse"
            alignItems="center"
            spacing={3}
            className={classes.marginNormal}
          >
            {!userData ? (
              <Grid item>
                <Button onClick={handleAdd} variant="contained" color="primary">
                  {(user.role==="businessAdmin" && type === "employee" ) && "Add" }
                  {(user.role !="businessAdmin" && type === "employee" ) && "Next"}
                  {(type !== "employee" ) && "Add"}
                </Button>
              </Grid>
            ) : (
              <>
                <Grid item>
                  <Button
                    onClick={handleUpdate}
                    variant="contained"
                    color="primary"
                  >
                    {type === "employee" && "Save and next"}
                    {type !== "employee" && "Update"}
                  </Button>
                </Grid>
                {(type === "employee" || type === "staff") &&
                  userData.isActive && (
                    <Grid item>
                      <Button
                        onClick={e => {
                          handleClose(userData.id);
                        }}
                        variant="outlined"
                        color="primary"
                      >
                        Next
                      </Button>
                    </Grid>
                  )}
                <Grid item>
                  <Button
                    onClick={handleDelete}
                    variant="contained"
                    color="secondary"
                  >
                    {type === "employee"  && !userData.isActive
                      ? "Restore"
                      : "Archive"}
                  </Button>
                </Grid>
              </>
            )}
            <Grid item>
              <Button onClick={handleClose} variant="contained">
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddModal;
