import React, { useState } from "react";
import {
  makeStyles,
  Button,
  Dialog,
  TextField,
  Grid,
  DialogTitle,
  DialogContent,
  FormLabel
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { toastAction } from "src/actions";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import api from "src/utils/api";
// import { DropzoneDialog } from "material-ui-dropzone";
// import axios from "axios";
// import baseurl from "../../../utils/baseurl";

const useStyles = makeStyles(theme => ({
  root: {},
  marginNormal: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  formControl: {
    marginTop: "16px"
  },
  formSelect: {
    marginBottom: "5px"
  }
}));

const PantryDeviceLinkModal = props => {
  const classes = useStyles();
  const {
    open,
    close,
    pantryLinkSend,
    activeData,
    fetchDeviceData,
    fetchDeviceCount
  } = props;
  const [deviceLink, setDeviceLink] = useState({
    pantryName:"",
    pantryLinkId: "",
    pantryCat:"",
    pantryType:"",
    pantryPass:""
  });
  const [deviceLinkList, setDeviceLinkList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [isLoading, setLoader] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [employeeList, setEmployeeList] = useState([]);
  const [dropzoneOpen, setDropzoneOpen] = useState(false);
  const [dropzone, setDropzone] = useState({});
  const [viewOpen, setViewOpen] = useState(false);
  const [viewUrl, setViewUrl] = useState("");
  const [viewType, setViewType] = useState("");

  const dispatch = useDispatch();

  React.useEffect(() => {
    fetchDeviceLinkData();
  }, [rowsPerPage, page]);



  const fetchDeviceLinkData = async () => {
    setLoader(true);
    try {
      const payload =
      {
        search: [
          {key:"device.isActive",value:"false",comparison:"="},
          {key:"device.isArchive",value:"false",comparison:"="},
          { key: "device.category", value: "pantry", comparison: "=" },
          { key: "device.pantryId", value: "NULL", comparison: "IS" }
        ],
        sort:
          { key: "device.name", order: "ASC" },
        skip: page * rowsPerPage,
        take: rowsPerPage
      }
      const building = await api("POST", "device/find", payload, true);
      const res = building || [];
      setDeviceLinkList(res);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  const handleClose = () => {
    close();
  };

  const onTagsChange = (event, values) => {
    // console.log("values",values);
    setDeviceLink(deviceLink => ({
      ...deviceLink,
      pantryLinkId: values.id,
      pantryName: values.name,
      pantryCat:values.category,
      pantryType:values.deviceType,
      pantryPass:values.passcode
    }));
  };


  const handleLink = async () => {
    try {
        let body = {
          name: deviceLink.pantryName,
          pantryId: pantryLinkSend.id,
          category:deviceLink.pantryCat,
          deviceType:deviceLink.pantryType
        };

        await api("PATCH", "device/" + deviceLink.pantryPass, body, true);
        dispatch(
            toastAction({
                status: true,
                message: "Device updated successfully",
                type: "success"
            })
        );
        close();

    } catch (error) {
        if (error.response && error.response.data) {
            dispatch(
                toastAction({
                    status: true,
                    message: error.response.data.message,
                    type: "error"
                })
            );
            // setError(error.response.data.message);
        }
    }
};

  // console.log("deviceList", pantryLinkSend)


  return (
    <React.Fragment>
      <Dialog open={open} onClose={close} fullWidth maxWidth="sm">
        <DialogTitle disableTypography>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Typography component="h1" variant="h3" color="primary">
              Link Device
            </Typography>
            <IconButton aria-label="close" onClick={close}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Autocomplete
            id="deviceName"
            options={deviceLinkList}
            getOptionLabel={option => option.name}
            onChange={onTagsChange}
            disableClearable
            className={classes.formSelect}
            renderInput={params => (
              <TextField
                {...params}
                label="Device Name"
                fullWidth
                variant="outlined"
              />
            )}
          />
          <br />
          <Grid container direction="row-reverse" alignItems="center" spacing={3}>
            <Grid item>
              <Button onClick={handleLink} variant="contained">
                Link
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={handleClose} variant="outlined">
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default PantryDeviceLinkModal;