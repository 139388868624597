import { Grid, Typography,IconButton,  Dialog, DialogTitle,makeStyles, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody,} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import api from "src/utils/api";
import { toastAction } from "src/actions";
import { useDispatch } from "react-redux";

const useStyles = makeStyles(theme => ({
    root: {},
    marginNormal: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1)
    },
    formControl: {
      marginTop: "16px"
    },
    formSelect: {
      marginBottom: "5px"
    }
  }));


const AttachedFloorListModel = ({deviceFloorData,open,close}) =>
{
    const dispatch = useDispatch();
    const classes = useStyles();
    const [floorName, setFloorName]= useState([]);


    useEffect(()=>{
            fetchFloorData();
    },[])

    // let floorId = deviceFloorData?.building.map(d=>d.floorId)
    // console.log("+++",floorId);

    const fetchFloorData = async () =>
    {
        let floorIds = deviceFloorData?.building.map(d=>d.floorId)
        try{
            const payload = {
                search: [
                    {key: "floor.id", value:floorIds, comparison: "IN" }
                  ]
            };
            const res = await api("POST", "floor/find", payload, true);
            console.log("res",res);
            setFloorName(res);
        }
        catch(error)
        {
            if (error.response && error.response.data) {
                dispatch(
                    toastAction({
                        status: true,
                        message: error.response.data.message,
                        type: "error"
                    })
                );
            }
        }
    }

    console.log("floorName",floorName);



return(
    <>
    <Dialog  open={open} onClick={close} fullWidth maxWidth="sm">
   <DialogTitle disableTypography>
    <Grid
    container
    direction="row"
    justify="space-between"
    alignItems="center"
    >
    <Typography component="h1" variant="h3" color="primary">
        Floor List 
    </Typography>
    <IconButton aria-label="close" onClick={close}>
        <CloseIcon />
    </IconButton>
    </Grid>
</DialogTitle>
<TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Floor Name</TableCell>
              <TableCell align="left">Building Name</TableCell>
            </TableRow>
            <TableRow>
            </TableRow>
          </TableHead>
          <TableBody>
          {floorName.map((row, ind) => (
              <TableRow key={ind}>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left">{row.building.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
</Dialog>
</>
)
}

export default AttachedFloorListModel;