import Button from "@material-ui/core/Button";
import { makeStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";
import RestoreIcon from "@material-ui/icons/Restore";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import EditIcon from "@material-ui/icons/Edit";
import { captureException } from "@sentry/react";
import React, { useState } from "react";
import { PlusCircle } from "react-feather";
import { KeyboardBackspace } from "@material-ui/icons";
import api from "src/utils/api";
import Loader from "../../../utils/loader";
import OrganizationModal from "./OrganizationModal";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationDialog from "../common/ConfirmDialog";
const useStyles = makeStyles(theme => ({
    root: {},
    marginNormal: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1)
    }
  }));

const IsActiveModal = (props) => {
    const {close} = props;
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const [isInactiveOpen, setInActiveOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [restoreopen, setrestoreOpen] = useState(false);
    const [isLoading, setLoader] = useState(true);
    const [error, setError] = useState("");
    const [isLoadingDelete, setDeleteLoader] = useState(false);
    const [isLoadingRestore, setRestoreLoader] = useState(false);
    const [organizationList, setOrganizationList] = useState([]);
    const [organizationInactiveList, setOrganizationInactiveList] = useState([]);
    const [activeData, setData] = useState({});
    const [countData, setCount] = useState(0);
    const [countDataInactive, setInactiveCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [floorList, setFloorList] = useState([]);
    const [dropzoneOpen, setDropzoneOpen] = useState(false);
    const [dropzone, setDropzone] = useState({});
    const [viewOpen, setViewOpen] = useState(false);
    const [viewUrl, setViewUrl] = useState("");
    const [viewType, setViewType] = useState("");
    const [isActive ,setIsActive] = useState(true)
    const [viewOrganization, setViewOrganization] = useState(
      new Array(rowsPerPage).fill(false)
    );
    const business = useSelector(state => state.business);
    const [businessDetails, setBusinessDetails] = useState(business);
    const dispatch = useDispatch();
  
    React.useEffect(() => {
      (async () => {
        const floorL = await api("POST", "floor/find", null, true);
        setFloorList(floorL);
        organizationInactiveCount()
      })();
    }, []);
  
    const organizationInactiveCount = async () => {
      try {
        const countPayload = {
          search:[{key:"organization.isActive",value:"false",comparison:"="}],
          sort: { key: "organization.createdAt", order: "ASC" },
          skip: 0,
          take: 10
        };
        const res = await api(
          "POST",
          "organization/findCount",
          countPayload,
          true
        );
        setInactiveCount(res);
      } catch (error) {
        captureException(error);
      }
    };
  
    const handleChangePage = (event, page) => {
      setPage(page);
    };
  
    const handleChangeRowsPerPage = event => {
      setRowsPerPage(event.target.value);
      setViewOrganization(new Array(event.target.value).fill(false));
      setPage(0);
    };
  
    React.useEffect(() => {
      fetchInactiveData()
      handleViewDialog(businessDetails.logo, "image")
    }, [rowsPerPage, page]);
  
    const fetchInactiveData = async () => {
      setLoader(true);
      try {
        const payload = {
          search:[{key:"organization.isActive",value:"false",comparison:"="}],
          sort: { key: "organization.createdAt", order: "ASC" },
          skip: page * rowsPerPage,
          take: rowsPerPage
        };
        const organization = await api(
          "POST",
          "organization/find",
          payload,
          true
        );
        const res = organization;
        console.log("res",res);
        setOrganizationInactiveList(res);
        setLoader(false);
      } catch (error) {
        setLoader(false);
      }
    };
    
  
    const handleViewDialog = (url, type) => {
      setViewUrl(url);
      setViewType(type);
      // setViewOpen(true);
    };

    const editOrganizations = data => {
      setData(data);
      setIsOpen(true);
    };
  
    const handleModal = () => {
      setData({});
    //   setIsOpen(true);
    };
  
    const handleActiveModel = () => {
      setData({});
    };
  
    const handleClose = () => {
        close();
    };
  
  
    const conformRoomModal = row => {
      if (row && row.id) {
        setOpen(true);
        setData(row);
      } else {
        setData({});
        setOpen(false);
      }
    };
    const conformOrgRestore = row => {
      if (row && row.id) {
        setrestoreOpen(true);
        setData(row);
      } else {
        setData({});
        setrestoreOpen(false);
      }
    };
    const deleteOrganization = async () => {
        setDeleteLoader(true);
        try {
          const organization = await api(
            "PATCH",
            `organization/${activeData.id}`,
            { isActive: false },
            true
          );
          if (organization.id) {
            organizationInactiveCount();
            fetchInactiveData();
            setOpen(false);
            setError("");
          }
          setDeleteLoader(false);
        } catch (error) {
          setDeleteLoader(false);
          setError("Some things went wrong please try again");
        }
      };
      const restoreOrganization = async () => {
        setRestoreLoader(true);
        try {
          const organization = await api(
            "PATCH",
            `organization/${activeData.id}`,
            { isActive: true },
            true
          );
          if (organization.id) {
            organizationInactiveCount();
            fetchInactiveData();
            setrestoreOpen(false);
            setError("");
          }
          setRestoreLoader(false);
        } catch (error) {
          setRestoreLoader(false);
          setError("Some things went wrong please try again");
        }
      };
    const DialogButtons = (
        <React.Fragment>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => conformRoomModal()}
          >
            NO
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={isLoadingDelete}
            onClick={() => deleteOrganization()}
          >
            {isLoadingDelete ? "Loading..." : "Yes, delete"}
          </Button>
        </React.Fragment>
      );
      const RestoreDialogButtons = (
        <React.Fragment>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => conformOrgRestore()}
          >
            NO
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={isLoadingRestore}
            onClick={() => restoreOrganization()}
          >
            {isLoadingRestore ? "Loading..." : "Yes, Restore"}
          </Button>
        </React.Fragment>
      );
  return (
    <div>
        <Grid alignItems="center" container spacing={3} direction="row">
        <Grid item>
          <IconButton aria-label="close" onClick={handleClose} size="small">
            <KeyboardBackspace />
          </IconButton>
        </Grid>
        </Grid>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Logo</TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {organizationInactiveList.map((row, ind) => (
              <React.Fragment key={ind}>
                <TableRow>
                <TableCell align="left">
                      <img 
                      src={row.logo}
                      style={{
                        backgroundColor: "#fff",
                        height: 50
                      }}
                      />
                  </TableCell>
                  <TableCell align="left">{row.name || "-"}</TableCell>
                 
                  <TableCell align="center">
                    {row.isActive ? "Active" : "Inactive"}
                  </TableCell>
                  <TableCell align="center">
                    {/* <IconButton
                      aria-label="edit"
                      onClick={() => editOrganizations(row)}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton> */}
                    {row.isActive ? (
                      <IconButton
                        aria-label="delete"
                        onClick={() => conformRoomModal(row)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    ) : (
                      <IconButton
                        aria-label="restore"
                        onClick={() => conformOrgRestore(row)}
                      >
                        <RestoreIcon fontSize="small" />
                      </IconButton>
                    )}
                    <IconButton
                      aria-label="edit"
                      onClick={() => {
                        setViewOrganization(
                          viewOrganization.map((vitem, vindex) => {
                            if (vindex === ind) {
                              return !vitem;
                            } else {
                              return vitem;
                            }
                          })
                        );
                      }}
                    >
                      {!viewOrganization[ind] && (
                        <ExpandMoreIcon fontSize="small" />
                      )}
                      {viewOrganization[ind] && (
                        <ExpandLessIcon fontSize="small" />
                      )}
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ padding: 0 }} colSpan={4}>
                    <Collapse
                      in={viewOrganization[ind]}
                      style={{ display: "block" }}
                    >
                      <Box margin={3}>
                        <Typography variant="h4">{row.name}</Typography>
                        <Typography variant="h5">
                          Buildings and Floors
                        </Typography>
                        <List>
                          {row.building.map((bitem, bindex) => (
                            <ListItem style={{ paddingLeft: 0 }} key={bindex}>
                              <ListItemText
                                primary={
                                  floorList.find(
                                    floor => floor.id === bitem.floorId
                                  )?.name
                                }
                                secondary={
                                  floorList.find(
                                    floor => floor.id === bitem.floorId
                                  )?.building?.name
                                }
                              />
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={countDataInactive}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
      {open && (
        <ConfirmationDialog
          open={open}
          error={error}
          handleClose={() => setOpen(false)}
          title={"Delete Organization"}
          content={"Are you sure you want to delete this organization?"}
          btnActions={DialogButtons}
        />
      )}
      {restoreopen && (
        <ConfirmationDialog
          open={restoreopen}
          error={error}
          handleClose={() => setrestoreOpen(false)}
          title={"Restore Organization"}
          content={"Are you sure you want to restore this organization?"}
          btnActions={RestoreDialogButtons}
        />
      )}
    </div>
  );
};

export default IsActiveModal;
