import {
    Card,
    CardContent,
    Divider,
    Grid,
    IconButton,
    makeStyles,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Chip,
    Dialog,
    FormLabel
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { KeyboardBackspace } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/material.css";
import { useDispatch, useSelector } from "react-redux";
import { toastAction } from "../../../actions";
import api from "../../../utils/api";
import DeviceSettings from "./DeviceSettings";
import { DropzoneDialog } from "material-ui-dropzone";
import axios from "axios";
import baseurl from "../../../utils/baseurl";
import { captureException } from "@sentry/browser";
import { Router } from "react-router";
import DeviceRegistration from "./DeviceRegistration";
import { AddCircleOutline } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { X } from "react-feather";

const useStyles = makeStyles(theme => ({
    root: {},
    marginNormal: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        minWidth: 300,
        justifyContent: "space-between"
    },
    formControl: {
        marginTop: "16px"
    },
    formSelect: {
        marginBottom: "5px"
    },
    deviceModal: {
        "& .MuiDialog-paper": {
            maxWidth: 800,
            width: "100%"
        }
    },
    deviceTable: {
        padding: "0 50px"
    },
    addDeviceSetting: {
        marginBottom: 20
    },
    openAddSettingBtn: {
        padding: "0 25px",
        "& button": {
            float: "right"
        }
    },
    backButton: {
        cursor: "pointer"
    }
}));


const DeviceModal = props => {
    const classes = useStyles();
    const { open, close, dataList, deviceCount, fetchData } = props;
    const [deviceSettingsData, setDeviceSettingsData] = useState([]);
    const [pantryList, setPantryList] = useState([]);
    const [pantrySelected, setPantrySelected] = useState({
        id: null,
        name: "None"
    });;
    const org = useSelector(state => {
        return state.org;
    });
    const user = useSelector(state => {
        return state.user;
    });
    const [deviceData, setDeviceData] = useState();
    const [isLoading, setLoader] = useState(false);
    const [error, setError] = useState("");
    const [category, setCategory] = useState("");
    const [subCategory, setSubCategory] = useState();

    const [device, setDevice] = useState({
        category: "",
        subCategory: [""],
        deviceType: "",
    });

    const dispatch = useDispatch();


    const handleClose = () => {
        close();
    };


    useEffect(() => {
        if (props.dataList) {
            setDevice(props.dataList)
        }
    }, [])


    const updateDevice = async () => {
        try {
            let body = {
                name: device.name,
                isActive: device.isActive,
                category: device.category,
                subCategory: device.subCategory,
                deviceType: device.deviceType,
                pantryId: pantrySelected?.id ?? null,

            };

            await api("PATCH", "device/" + dataList.passcode, body, true);
            dispatch(
                toastAction({
                    status: true,
                    message: "Device updated successfully",
                    type: "success"
                })
            );

        } catch (error) {
            if (error.response && error.response.data) {
                dispatch(
                    toastAction({
                        status: true,
                        message: error.response.data.message,
                        type: "error"
                    })
                );
            }
        }
    };

    const addDevice = async () => {
        setLoader(true);
        if(device.name && 
            device.category && 
            device.deviceType){
        let body = {
            name: device.name,
            category: device.category,
            subCategory: device.subCategory,
            deviceType: device.deviceType,
        }
        try {
            const res = await api("POST", "device", body, true);
            dispatch(
                toastAction({
                    status: true,
                    message: "Device added successfully",
                    type: "success"
                })
            );
            console.log("res", res);
            if (res.apiKey) {
                setDeviceData(res)
                deviceCount();
                fetchData();
                handleClose();
                setLoader(false);
            }
        } catch (error) {
            if (error.response && error.response.data) {
                dispatch(
                    toastAction({
                        status: true,
                        message: error.response.data.message,
                        type: "error"
                    })
                )
            }
            setLoader(false);
        }
    }
    else
    {
        setError("Please fill all the field");
    }
    };

    useEffect(() => {
        if (dataList) {
            (async () => {
                const data = await api(
                    "GET",
                    "deviceSetting/device/" + dataList.id,
                    null,
                    true
                );
                setDeviceSettingsData(data);
            })();
        }
    }, []);

    const handleAction = () => {
        if (props.dataList) {
            updateDevice();
        } else {
            addDevice();
        }
    };


    return (
        <div>
            <Grid alignItems="center" container spacing={3} direction="row">
                <Grid item>
                    <IconButton aria-label="close" onClick={handleClose} size="small">
                        <KeyboardBackspace />
                    </IconButton>
                </Grid>
                <Grid item>
                    <Typography
                        variant="h6"
                        onClick={handleClose}
                        color="textPrimary"
                        className={classes.backButton}
                    >
                        Back to All Devices
                    </Typography>
                </Grid>
            </Grid>
            <br />
            <Card>
                <CardContent>
                    <Typography component="h1" variant="h3" color="primary">
                        {props.dataList ? "Device Detail" : "Add Device"}
                    </Typography>
                    {error !== "" && (
            <Alert
              severity="error"
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setError("");
                  }}
                >
                  <X />
                </Button>
              }
            >
              {error}
            </Alert>
          )}
                    <br />
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                onChange={e => setDevice({ ...device, name: e.target.value })}
                                label="Device Name"
                                value={device.name}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl variant="outlined" style={{ width: "100%" }}>
                                <InputLabel> Device Type</InputLabel>
                                <Select
                                    labelId="deviceType"
                                    id="deviceType"
                                    label="Device Type"
                                    value={device.deviceType}
                                    onChange={e => {
                                        setDevice({ ...device, deviceType: e.target.value });
                                    }}
                                    autoWidth
                                >
                                    <MenuItem value="Android">Android</MenuItem>
                                    <MenuItem value="TelpoAndroid">Telpo - Android</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl variant="outlined" style={{ width: "100%" }}>
                                <InputLabel id="category"> Category</InputLabel>
                                <Select
                                    labelId="category"
                                    id="category"
                                    label="Category"
                                    value={device.category}
                                    displayEmpty
                                    onChange={e => {
                                        let update = { category: e.target.value };
                                        setCategory(e.target.value);
                                        if (
                                            !["insideVIPRoom", "outsideVIPRoom"].includes(
                                                e.target.value
                                            )
                                        ) {
                                            update.employeeId = null;
                                        }
                                        if (
                                            ![
                                                "authenticator",
                                                "entrance",
                                                "parkingAuthenticator"
                                            ].includes(e.target.value)
                                        ) {
                                            update.subCategory = [];
                                        }
                                        if (!["pantry"].includes(e.target.value)) {
                                            update.pantryId = null;
                                            setPantrySelected(null);
                                        }
                                        if (!["outdoorMeetingRoom"].includes(e.target.value)) {
                                            update.videoUrl = null;
                                        }

                                        setDevice({ ...device, ...update });
                                    }}
                                    autoWidth
                                >
                                    <MenuItem value="entrance">Entrance</MenuItem>
                                    <MenuItem value="authenticator">Authenticator</MenuItem>
                                    <MenuItem value="parkingAuthenticator">
                                        Parking Authenticator
                                    </MenuItem>
                                    <MenuItem value="tempScanner">Temperature Scanner</MenuItem>
                                    <MenuItem value="uvcKiosk">UVC Kiosk</MenuItem>
                                    <MenuItem value="outdoorMeetingRoom">
                                        Meeting Room Entrance
                                    </MenuItem>
                                    <MenuItem value="insideMeetingRoom">
                                        Inside Meeting Room
                                    </MenuItem>
                                    <MenuItem value="insideVIPRoom">Inside VIP Room</MenuItem>
                                    <MenuItem value="outsideVIPRoom">Outside VIP Room</MenuItem>
                                    <MenuItem value="pantry">Pantry Tab</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl variant="outlined" style={{ width: "100%" }}>
                                <InputLabel id="subCategory">Sub category</InputLabel>
                                <Select
                                    labelId="subCategory"
                                    disabled={
                                        ![
                                            "authenticator",
                                            "entrance",
                                            "parkingAuthenticator"
                                        ].includes(device.category)
                                    }
                                    id="subCategory"
                                    label="Sub category"
                                    value={device.subCategory}
                                    onChange={e =>
                                        setDevice({ ...device, subCategory: [e.target.value] })
                                    }
                                    autoWidth
                                >
                                    <MenuItem value="checkIn">Check In</MenuItem>
                                    <MenuItem value="checkOut">Check Out</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={12}>
                            <Box display="flex" justifyContent="flex-end">
                                <Button
                                    onClick={handleAction}
                                    color="primary"
                                    variant="contained"
                                >
                                    {props.dataList ? "Update" : "Add"}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <br />
            <Divider />
            <br />
            {(props.dataList) && (
                <Card>
                    <CardContent>
                        <DeviceSettings
                            dataList={deviceSettingsData}
                            deviceId={dataList.id}
                        />
                    </CardContent>
                </Card>
            )}
        </div>
    );
};

export default DeviceModal;