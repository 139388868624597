import {
  AppBar,
  Box,
  Button,
  Hidden,
  IconButton,
  makeStyles,
  Toolbar
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { LogOut } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Logo from "src/components/Logo";
import api from "src/utils/api";
import { logout } from "../../actions";

const useStyles = makeStyles(() => ({
  root: {
    // background: "#FFF",
    // borderBottom: "1px solid rgba(0, 0, 0, 0.12);"
  },
  menu: {
    marginLeft: -20
  }
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [logo,setLogo]=useState("")
  const org = useSelector(state => state.org);
  const business = useSelector(state => state.business);
  const navigate = useNavigate();
  
(async ()=>{
      if(org){
        const data = await api(
          "GET",
          "organization/" + org.id,
          null,
          false
        );
        setLogo(data.logo)
      }else {
        const data = await api(
          "GET",
          "business/" + business.id,
          null,
          false
        );
        setLogo(data.logo)
      }
  })()

  const handleSignOut = () => {
    localStorage.clear("token");
    navigate("/login");
    dispatch(logout());
  };

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
            className={classes.menu}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
        <RouterLink to="/">
          {logo ? <Logo src={logo} /> : <Logo />}
        </RouterLink>
        <Box flexGrow={1} />
        <Button color="inherit" startIcon={<LogOut />} onClick={handleSignOut}>
          Sign out
        </Button>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
