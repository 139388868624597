import React, { useState } from "react";
import {
  makeStyles,
  Button,
  Dialog,
  TextField,
  Grid,
  DialogTitle,
  DialogContent,
  FormLabel
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { toastAction } from "../../../actions";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import api from "src/utils/api";
import { DropzoneDialog } from "material-ui-dropzone";
import axios from "axios";
import baseurl from "../../../utils/baseurl";

const useStyles = makeStyles(theme => ({
  root: {},
  marginNormal: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  formControl: {
    marginTop: "16px"
  },
  formSelect: {
    marginBottom: "5px"
  }
}));

const RoomDeviceLinkModal = props => {
  const classes = useStyles();
  const {
    open,
    close,
    deviceList,
    activeData,
    fetchDeviceData,
    fetchDeviceCount,
    buildingList,
    floorList
  } = props;
  const [deviceLink, setDeviceLink] = useState({
    deviceLinkId: "",
    employeeId: "",
    // videoUrl: "",
    deviceCat:"",
    floorIds:[],
    employeeName:""
  });
  const [deviceLinkList, setDeviceLinkList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [isLoading, setLoader] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [employeeList, setEmployeeList] = useState([]);
  const [dropzoneOpen, setDropzoneOpen] = useState(false);
  const [dropzone, setDropzone] = useState({});
  const [viewOpen, setViewOpen] = useState(false);
  const [viewUrl, setViewUrl] = useState("");
  const [viewType, setViewType] = useState("");
  const [floorsList, setFloorsList] = useState([]);
  const [selectableFloorList, setSelectableFloorList] = useState([]);
  const [buildingsSelected, setBuildingsSelected] = useState([]);
  const [floorSelected, setFloorSelected] = useState([]);
  const [allBuildings, setAllBuildings] = useState([]);
  const [allBuildingsFloorMap, setAllBuildingsFloorMap] = useState({});
  const [availableFloors, setAvailableFloors] = useState([]);

  const dispatch = useDispatch();

  const handleViewDialog = (url, type) => {
    setViewUrl(url);
    setViewType(type);
    setViewOpen(true);
  };

  const handleUploadDalog = (title, key, type, flag) => {
    setDropzone({
      title,
      key,
      type,
      flag
    });
    setDropzoneOpen(true);
  };

  const handleUpload = async files => {
    dispatch(
      toastAction({
        status: true,
        message: "Uploading video ... ",
        type: "info",
        noIcon: true
      })
    );
    const url = await upload(files[0], "meeting_room_device_video");
    setDeviceLink({ ...deviceLink, videoUrl: url });
    setDropzoneOpen(false);
    dispatch(
      toastAction({
        status: true,
        message: "Video uploaded. Click update to confirm",
        type: "success",
        noIcon: true
      })
    );
  };

  const upload = async (file, folder) => {
    const token = localStorage ? localStorage.getItem("token") : null;
    let filenameSplit = file.name.split(".");
    const formData = new FormData();
    formData.append(
      "file",
      file,
      Date.now() + "." + filenameSplit[filenameSplit.length - 1]
    );
    formData.append("fileStorage", folder);

    try {
      let response = await axios({
        method: "POST",
        url: baseurl + "file/upload",
        data: formData,
        headers: {
          Authorization: "Bearer " + token
        },
        onUploadProgress: progressEvent => {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          dispatch(
            toastAction({
              status: true,
              message: "Uploading file ... " + percentCompleted + "%",
              type: "info",
              noIcon: true
            })
          );
        }
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  React.useEffect(() => {
    fetchDeviceLinkData();
  }, [rowsPerPage, page]);



  const fetchDeviceLinkData = async () => {
    setLoader(true);
    try {
      const roomType = await api("GET", "quickSettings/roomSettings", null, true);
      let premiseTypeSetting = roomType.data[activeData.premiseType];
      let sdCategories = premiseTypeSetting.supportedDeviceCategories;
      let currentCategories = deviceList.map(d => d.category);
      var needDeviceTypes = premiseTypeSetting.allowDuplicate ? sdCategories : sdCategories.filter(function(obj) { return currentCategories.indexOf(obj) == -1; });
      const payload =
      {
        search: [
          {key:"device.isActive",value:"false",comparison:"="},
          {key:"device.isArchive",value:"false",comparison:"="},
          { key: "device.category", value: needDeviceTypes, comparison: "IN" },
          { key: "device.roomId", value: "NULL", comparison: "IS" }
        ],
        sort:
          { key: "device.name", order: "ASC" },
        skip: page * rowsPerPage,
        take: rowsPerPage
      }
      const building = await api("POST", "device/find", payload, true);
      const res = building || [];
      setDeviceLinkList(res);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  React.useEffect(() => {
    (async () => {
      const employee = await api(
        "POST",
        "employee/find",
        {
          search: [
            {
              key: "employee.name",
              value: searchInput,
              comparison: "LIKE"
            }
          ],
        },
        true
      );
      setEmployeeList(employee);
    })();
  }, [searchInput]);


  const handleClose = () => {
    close();
  };

  React.useEffect(()=>{
    const buildingIdFloorMap = {};
    floorList.map (f => {
      if(!buildingIdFloorMap[f.building.id]) {
        buildingIdFloorMap[f.building.id] = [f]
      } else {
        let existingFloors = buildingIdFloorMap[f.building.id];
        existingFloors.push(f);
      }
    });

    buildingList.map(b => {
      if(!buildingIdFloorMap[b.id]) {
        buildingIdFloorMap[b.id] = [];
      }
    }); 
    setAllBuildingsFloorMap(buildingIdFloorMap);  
    setAllBuildings(buildingList);
  },[])

  const onTagsChange = (event, values) => {
    setDeviceLink(deviceLink => ({
      ...deviceLink,
      deviceLinkId: values.id,
      deviceCat:values.category
    }));
  };

  const onEmployeeChange = (event, values) => {
    setDeviceLink(deviceLink => ({
      ...deviceLink,
      employeeId: values.id,
      employeeName: values.name
    }));
  };

  const onBuildingChange = (event, values) => {
    event.preventDefault();  
    let lastCount = buildingsSelected.length;
    setBuildingsSelected(values);
    availableFloors.length = 0;
    values.map(building => {
      availableFloors.push(...allBuildingsFloorMap[building.id]);
    });
    setAvailableFloors(availableFloors);
    if(lastCount > values.length) {
      setFloorSelected(availableFloors);
    }
  };

  const onFloorChange = (event, values) => {
    setFloorSelected(values);
    setDeviceLink(deviceLink => ({
      ...deviceLink,
      floorIds: values.map(item => item.id)
    }));
  };


  const handleLink = async () => {
    setLoader(true);
    try {
      const data = await api(
        "PATCH", 
      `room/${activeData.id}/link/device`,
      {
        id: deviceLink.deviceLinkId,
        employeeId:deviceLink.employeeId,
        videoUrl: deviceLink.videoUrl,
        floorIds: deviceLink.floorIds
      },
       true);
      if (data.code===200) {
        dispatch(
          toastAction({
            status: true,
            message: "Device updated successfully",
            type: "success"
          })
        );
        fetchDeviceLinkData();
        fetchDeviceCount();
        close();
        fetchDeviceData();
        setLoader(false);
      }
      else if(data.code===400)
      {
        dispatch(
          toastAction({
            status: true,
            message: data.message,
            type: "error"
          })
        );
      }
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(
            toastAction({
                status: true,
                message: error.response.data.message,
                type: "error"
            })
        );
    }
    }
  };



  // console.log("deviceList", deviceLink)
  // console.log("deviceList", buildingList)


  return (
    <React.Fragment>
      <Dialog open={open} onClose={close} fullWidth maxWidth="sm">
        <DialogTitle disableTypography>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Typography component="h1" variant="h3" color="primary">
              Link Device
            </Typography>
            <IconButton aria-label="close" onClick={close}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Autocomplete
            id="deviceName"
            options={deviceLinkList}
            getOptionLabel={option => option.name}
            onChange={onTagsChange}
            disableClearable
            className={classes.formSelect}
            renderInput={params => (
              <TextField
                {...params}
                label="Device Name"
                fullWidth
                variant="outlined"
              />
            )}
          />
          {/* <br /> */}
          {activeData.premiseType === "vipRoom" && (
            <>
            <Autocomplete
              id="employee"
              options={employeeList}
              getOptionLabel={option => option.name}
              onChange={onEmployeeChange}
              disableClearable
              renderInput={params => (
                <TextField
                  {...params}
                  label="Employee"
                  onChange={e => setSearchInput(e.target.value)}
                  fullWidth
                  variant="outlined"
                />
              )}
            />
            <br />
            </>
          )}
          
          {activeData.premiseType === "accessZone" &&(
            <>
              <Autocomplete
                id="building"
                options={allBuildings}
                getOptionLabel={option => {
                  return option.name;
                }}
                onChange={onBuildingChange}
                value={buildingsSelected}
                multiple
                disableClearable
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Buildings"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                )}
              />
              <Autocomplete
                id="floor"
                options={availableFloors}
                getOptionLabel={option => option.name}
                onChange={onFloorChange}
                value={floorSelected}
                multiple
                disableClearable
                renderInput={params=>(
                  <TextField
                  {...params}
                  label="Floors"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  />
                )}
              />
            </>
          )}
          {deviceLink.deviceCat === "outdoorMeetingRoom" && (
            <Grid item xs={6}>
              <Grid container direction="row" spacing={3}>
                <Grid item>
                  <FormLabel component="legend">
                    Video to display:{" "}
                  </FormLabel>
                </Grid>
                <Grid item>
                  <Grid container direction="row" spacing={3}>
                    {deviceLink.videoUrl && (
                      <>
                        <Grid item>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={e =>
                              handleViewDialog(deviceLink.videoUrl, "video")
                            }
                          >
                            View
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={e => {
                              toastAction({
                                status: true,
                                message:
                                  "Video removed. Click update to confirm",
                                type: "success",
                                noIcon: true
                              });
                              setDeviceLink({ ...deviceLink, videoUrl: "" });
                            }}
                          >
                            Remove
                          </Button>
                        </Grid>
                      </>
                    )}

                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={e =>
                          handleUploadDalog(
                            "Meeting Room Video",
                            "meetingroomvideo",
                            "video",
                            "meetingroomvideo"
                          )
                        }
                      >
                        Upload
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          <Grid container direction="row-reverse" alignItems="center" spacing={3}>
            <Grid item>
              <Button onClick={handleLink} variant="contained">
                Link
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={handleClose} variant="outlined">
                Close
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>


      <DropzoneDialog
        open={dropzoneOpen}
        onSave={files => {
          handleUpload(files);
        }}
        acceptedFiles={[dropzone.type + "/*"]}
        showPreviews={true}
        filesLimit={1}
        maxFileSize={5000000000}
        dialogTitle={dropzone.title}
        onClose={() => setDropzoneOpen(false)}
        submitButtonText="Upload"
        cancelButtonText="Cancel"
      />
      <Dialog
        open={viewOpen}
        onClose={() => setViewOpen(false)}
        disableAutoFocus={true}
      >
        {viewType === "video" && (
          <video src={viewUrl} width="100%" height="100%" controls></video>
        )}
      </Dialog>
    </React.Fragment>
  );
}

export default RoomDeviceLinkModal;