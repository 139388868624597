import axios from "axios";

const urls = {
  local: "https://mqttnginx.thesentinel.io/reporting/v1/",
  dev: "https://mqttnginx.thesentinel.io/reporting/v1/",
  staging: "https://stage.thesentinel.io/reporting/v1/",
  prod: "https://api.thesentinel.io/reporting/v1/",
}

const baseurl = urls[process.env.REACT_APP_ENV.trim()];

export const api = async (method, url, data, token) => {
  try {
    const reponse = await axios({
      method: method,
      url:baseurl + url,
      data: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: token ? `Bearer ${localStorage.getItem("token")}` : "",
      },
    });
    return reponse.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};