import { Grid, makeStyles, CardContent, Card, Typography, FormGroup, FormControlLabel, Checkbox, InputAdornment } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import axios from "axios";
import baseurl from "../../../utils/baseurl";
import { business as businessDispatch } from "../../../actions";
import {X } from "react-feather";
import "react-phone-input-2/lib/material.css";
import api from "src/utils/api";
import { KeyboardBackspace } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { DropzoneDialog } from "material-ui-dropzone";
import { useDispatch } from "react-redux";
import { toastAction } from "src/actions";

const useStyles = makeStyles(theme => ({
  root: {},
  marginNormal: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}));

const initialState = {
  name: "",
  logo: "",
  country:"",
  timezone: "",
  timezoneName: "",
  countrycode: "",
  defaultPhoneCountryCode:"",
  floorIds: [],
  emailDomainId: "",
  exchangeId: "",
  isAdEnabled: false,
  isCustomUserCreationEnabled: true
};

const OrganizationModal = props => {
  const classes = useStyles();
  const {
    open,
    close,
    organizationCount,
    addOrganizationsToArray,
    editOrganizationsToArray,
    activeData,
    countryDetails,
    orgDetails,
    exchangeDetail,
  } = props;
  const [formData, setFormData] = useState(initialState);
  const [dropzoneOpen, setDropzoneOpen] = useState(false);
  const [dropzone, setDropzone] = useState({});
  const [isLoading, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [floorList, setFloorList] = useState([]);
  const [selectableFloorList, setSelectableFloorList] = useState([]);
  const [buildingsSelected, setBuildingsSelected] = useState([]);
  const [floorSelected, setFloorSelected] = useState([]);
  const [allBuildings, setAllBuildings] = useState([]);
  const [allBuildingsFloorMap, setAllBuildingsFloorMap] = useState({});
  const [availableFloors, setAvailableFloors] = useState([]);
  const [timeZoneName, setTimeZoneName] = useState('');
  const [countryPhoneCode, setCountryPhoneCode] = useState('');
  const [timeZone, setTimeZone] = useState('');
  const [timeZoneValue, setTimeZoneValue] = useState('');
  const [timeZoneValueName, setTimeZoneValueName] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [exchangeId, setExchangeId] = useState()
  const [eId, setId] = useState()
  const [selectedExchangeId, setSelectedExchangeId] = useState("")
  const [checked, setChecked] = useState(false);
  const [countryName, setCountryName] = useState('');
  const [domain, setDomain] = useState('');
  const user = useSelector(state => state.user)
  const org = useSelector(state => state.org)
  const handleClose = () => {
    close();
  };

  const dispatch = useDispatch();

  React.useEffect(() => {
    setSelectableFloorList(
      floorList.filter(floor =>
        buildingsSelected.map(item => item.id).includes(floor.building.id)
      )
    );
  }, [floorList, buildingsSelected]);

  useEffect(() => {
    if (checked) {
      setId(exchangeId)
    }
    setId(exchangeId)
  });

  useEffect(() => {
    if (user.role != "businessAdmin" && activeData) {
      setCountryName(activeData.countryCode)
      setDomain(org?.emailDomainId)
      setSelectedExchangeId(exchangeDetail.find((ele) => {
        if (ele.id === org?.exchangeId) {
          return ele.name
        }
      }))
      setChecked(org?.isAdEnabled);
    } else if(user.role === "businessAdmin" && activeData)
    {
      setDomain(activeData.emailDomainId)
    }
  }, [])

  React.useEffect(() => {
    (async () => {
      try {
        const payload = {
          search: [
            { key: "building.name", value: "", comparison: "LIKE" },
            { key: "building.isActive", value: "", comparison: "=" },
            { key: "organization.id", value: "", comparison: "=" }
          ],
          "sort":
            { key: "building.createdAt", order: "ASC" },
          skip: 0,
          take: 100
        }
        const buildings = await api("POST", "building/find", payload, true);
        setAllBuildings(buildings);
        const allFloors = await api("POST", "floor/find", null, true);

        const buildingIdFloorMap = {};
        let alreadyAssignedFloors = await getAssignedFloors();
        allFloors.map(f => {
          if(alreadyAssignedFloors[f.id]) {
            return;
          }
          if (!buildingIdFloorMap[f.building.id]) {
            buildingIdFloorMap[f.building.id] = [f]
          } else {
            let existingFloors = buildingIdFloorMap[f.building.id];
            existingFloors.push(f);
          }
        });

        buildings.map(b => {
          if (!buildingIdFloorMap[b.id]) {
            buildingIdFloorMap[b.id] = [];
          }
        });
        setAllBuildingsFloorMap(buildingIdFloorMap);
        setFloorList(allFloors);

        if (activeData.id) {

          let uniqueBuliding = {};
          let uniqueFloors = {};
          let existingBuldings = [];
          let existingFloors = [];

          activeData.building.map(bfloorMap => {
            if (!bfloorMap.isActive) {
              return;
            }
            if (!uniqueFloors[bfloorMap.floorId] && bfloorMap.floor) {
              existingFloors.push(bfloorMap.floor);
              uniqueFloors[bfloorMap.floorId] = true;
            }
            if (!uniqueBuliding[bfloorMap.buildingId] && bfloorMap.building) {
              existingBuldings.push(bfloorMap.building);
              uniqueBuliding[bfloorMap.buildingId] = true;
            }
          });
          setBuildingsSelected(existingBuldings);
          setFloorSelected(existingFloors);
          setFormData({
            ...activeData,
            floorIds: activeData.building
              .filter(item => item.building && item.floor)
              .map(item => item.floorId),
          });
        }
      } catch (error) {
        if (error.response && error.response.data) {
          dispatch(
            toastAction({
                status: true,
                message: error.response.data.message,
                type: "error"
            })
        )
          setError(error.response.data);
        }
        console.log(error);
      }
    })();
  }, []);

  const getAssignedFloors = async () => {
    const payload = {
      search: [{ key: "organization.isActive", value: true, comparison: "=" }],

      sort: { key: "organization.createdAt", order: "ASC" },

      skip: 0
    };

    let organizations = await api(
      "POST",

      "organization/find",

      payload,

      true
    );

    let assignedFloorMap = {};

    organizations.map(activeData => {
      activeData.building.map(bfloorMap => {
        if (!bfloorMap.isActive) {
          return;
        }

        assignedFloorMap[bfloorMap.floorId] = true;
      });
    });

    return assignedFloorMap;
  };


  const handleAction = () => {
    if (activeData.id) {
      handleUpdate();
    } else {
      handleAdd();
    }
  };

  const handleAdd = async () => {
    if (formData.name && formData.logo) {
      setLoader(true);
      try {
        const data = await api(
          "POST",
          "organization",
          {
            name: formData.name,
            logo: formData.logo,
            timezone: formData.timezone,
            timezoneName:formData.timezoneName,
            defaultPhoneCountryCode:formData.defaultPhoneCountryCode,
            countrycode: formData.countrycode,
            floorIds: floorSelected.map(f => f.id),
            emailDomainId: domain
          },
          true
        );
        // console.log("data",data);
        if (data.id) {
          handleClose();
          organizationCount();
          addOrganizationsToArray(data);
          setLoader(false);
        }
      } catch (error) {
        if (error.response && error.response.data) {
          dispatch(
            toastAction({
                status: true,
                message: error.response.data.message,
                type: "error"
            })
        )
          setError(error.response.data.message);
        }
        setLoader(false);
      }
    } else {
      setError("Please fill all the fields");
    }
  };

  const handleUpdate = async () => {
    if (formData.name) {
      setLoader(true);
      try {
        const data = await api(
          "PATCH",
          `organization/${activeData.id}`,
          {
            name: formData.name,
            logo: formData.logo,
            timezone: formData.timezone,
            timezoneName:formData.timezoneName,
            defaultPhoneCountryCode:formData.defaultPhoneCountryCode,
            countrycode: formData.countrycode,
            floorIds: floorSelected.map(f => f.id),
            emailDomainId: domain,
            exchangeId: selectedExchangeId?.id,
            isAdEnabled: checked,
            isCustomUserCreationEnabled: checked ? false : true
          },
          true
        );
        if (data.id) {
          handleClose();
          organizationCount();
          window.location.reload();
          editOrganizationsToArray(data);
          setLoader(false);
        }
      } catch (error) {
        if (error.response && error.response.data) {
          dispatch(
            toastAction({
                status: true,
                message: error.response.data.message,
                type: "error"
            })
        )
          setError(error.response.data.message);
        }
        setLoader(false);
      }
    } else {
      setError("Please fill all the fields");
    }
  };

  const handleInputChange = event => {
    event.persist();
    const { name, value } = event.target;
    if (name === "number" || name === "subLocationId") {
      const re = /^[0-9\b]+$/;
      if (value === "" || re.test(value)) {
        setFormData(formData => ({
          ...formData,
          [name]: value
        }));
      }
    } else {
      setFormData(formData => ({
        ...formData,
        [name]: value
      }));
    }
  };

  const handleDomainChange = event =>
  {
    event.persist();
    const { name, value } = event.target;
    setDomain(value.trim().toLowerCase());
  }

  const handleExchangeId = (event, values) => {
    setSelectedExchangeId(exchangeDetail.find((ele) => {
      if (ele.id === values.id) {
        return ele.name
      }
    }))
  }

  const handleAdEnabled = (event) => {
    setChecked(event.target.checked);
  }

  const onBuildingChange = (event, values) => {
    event.preventDefault();
    let lastCount = buildingsSelected.length;
    setBuildingsSelected(values);
    availableFloors.length = 0;
    values.map(building => {
      availableFloors.push(...allBuildingsFloorMap[building.id]);
    });
    setAvailableFloors(availableFloors);
    let attachedFloor=activeData?.building?.filter(f=>f.isActive).map(f=>f.floor)
    let attachedBuilding= activeData?.building?.filter(f=>f.isActive).map(f=>f.building);
    if (lastCount > values.length) {
      if(values.length != 0 && values[0]?.name===attachedBuilding[0]?.name)
      {
        setFloorSelected(attachedFloor);
      }
      else
      {
        setFloorSelected(availableFloors);
      }
    }
  };


  const handleCountryChange = (event, values) => {
    // console.log("values",values);
    setFormData({...formData,
      country:values.name,
      countrycode:values.code,
      defaultPhoneCountryCode:values.countryCode
    })
    setTimeZone(values.timeZone)
  };

  const handleTimeZoneChange = (event, values) =>
  {
    setFormData({
      ...formData,
      timezone:values.value,
      timezoneName:values.name
    })
  }

  const onFloorChange = (event, values) => {
    setFloorSelected(values);
  };

  const handleUpload = async files => {
    dispatch(
      toastAction({
        status: true,
        message: "Uploading file ... ",
        type: "info",
        noIcon: true
      })
    );
    const url = await upload(files[0], "businessLogo");
    dispatch(
      toastAction({
        status: true,
        message: "Updating identity..",
        type: "info",
        noIcon: true
      })
    );
    setDropzoneOpen(false);
    setFormData({
      ...formData,
      logo: url
    });
    dispatch(
      toastAction({
        status: true,
        message: "Identity updated",
        type: "success"
      })
    );
  };


  const upload = async (file, folder) => {
    const token = localStorage ? localStorage.getItem("token") : null;
    let filenameSplit = file.name.split(".");
    const formData = new FormData();
    formData.append(
      "file",
      file,
      Date.now() + "." + filenameSplit[filenameSplit.length - 1]
    );
    formData.append("fileStorage", folder);

    try {
      let response = await axios({
        method: "POST",
        url: baseurl + "file/upload",
        data: formData,
        headers: {
          Authorization: "Bearer " + token
        },
        onUploadProgress: progressEvent => {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          dispatch(
            toastAction({
              status: true,
              message: "Uploading file ... " + percentCompleted + "%",
              type: "info",
              noIcon: true
            })
          );
        }
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const handleUploadDalog = (title, key, type, flag) => {
    setDropzone({
      title,
      key,
      type,
      flag
    });
    setDropzoneOpen(true);
  };


  // console.log("activeData",activeData.building.filter(f=>f.isActive).map(f=>f.floor));



  return (
    <div>
      <Grid alignItems="center" container spacing={3} direction="row">
        <Grid item>
          <IconButton aria-label="close" onClick={handleClose} size="small">
            <KeyboardBackspace />
          </IconButton>
        </Grid>
      </Grid>
      <Card>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Typography component="h1" variant="h3" color="primary" m={2}>
            {activeData.id ? "Edit" : "Add"} Organizations
          </Typography>
        </Grid>
        <CardContent>
          {error !== "" && (
            <Alert
              severity="error"
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setError("");
                  }}
                >
                  <X />
                </Button>
              }
            >
              {error}
            </Alert>
          )}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Name"
                variant="outlined"
                type="email"
                margin="normal"
                required
                fullWidth
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Logo URL"
                variant="outlined"
                type="text"
                margin="normal"
                required
                fullWidth
                name="logo"
                value={formData.logo}
                onChange={handleInputChange}
                InputProps={
                  {
                      endAdornment: (
                          <InputAdornment position="end">
                              <Button
                                  aria-label="Add"
                                  edge="end"
                                  color="primary"
                                  variant="contained"
                                  onClick={e => handleUploadDalog("Logo", "logo", "image")}
                              >
                                  ADD
                              </Button>
                          </InputAdornment>
                      )
                  }
              }
              />
            </Grid>
            {user.role !== "businessAdmin" &&
            activeData?.id && (
              <>
                <Grid item xs={6}>
                <TextField
                  label="Country"
                  variant="outlined"
                  type="text"
                  margin="normal"
                  fullWidth
                  name="country"
                  inputProps={
                    { readOnly: true, }
                  }
                  value={activeData.country}
                />
              </Grid>
              <Grid item xs={6}>
                    <TextField
                      label="Country Code"
                      variant="outlined"
                      type="text"
                      margin="normal"
                      required
                      fullWidth
                      inputProps={
                        { readOnly: true, }
                      }
                      name="countryCode"
                      value={activeData.countrycode}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Time Zone"
                      variant="outlined"
                      type="text"
                      margin="normal"
                      required
                      fullWidth
                      inputProps={
                        { readOnly: true, }
                      }
                      name="timezone"
                      value={activeData.timezone+" "+ activeData.timezoneName}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Country Phone Code"
                      variant="outlined"
                      type="text"
                      margin="normal"
                      required
                      fullWidth
                      inputProps={
                        { readOnly: true, }
                      }
                      name="countryPhoneCode"
                      value={activeData.defaultPhoneCountryCode}
                      // onChange={handleInputChange}
                    />
                  </Grid>
              </>
            )}
            {user.role === "businessAdmin" && (
              <>
              <Grid item xs={6}>
                <Autocomplete
                  id="country-select-demo"
                  sx={{ width: 300 }}
                  options={countryDetails}
                  autoHighlight
                  getOptionLabel={(option) => option.name}
                  onChange={handleCountryChange}
                  defaultValue={{name:activeData?.country}}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="text"
                      required
                      margin="normal"
                      {...params}
                      label="Country"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
              <TextField
                label="Country Code"
                variant="outlined"
                type="text"
                margin="normal"
                required
                fullWidth
                name="countryCode"
                value={formData.countrycode}
                onChange={handleInputChange}
              />
            </Grid>
            {!activeData.id && (
            <Grid item xs={6}>
                <Autocomplete
                  id="timezone"
                  sx={{ width: 300 }}
                  options={timeZone}
                  autoHighlight
                  getOptionLabel={(options) => options.value+" "+ options.name}
                  onChange={handleTimeZoneChange}
                  disableClearable
                  disabled={!formData.country}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="text"
                      required
                      margin="normal"
                      {...params}
                      label="Time Zone"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </Grid>
              )}
              {activeData.id &&(
                <Grid item xs={6}>
                <Autocomplete
                  id="timezone"
                  sx={{ width: 300 }}
                  options={timeZone}
                  autoHighlight
                  getOptionLabel={(options) => options.value+" "+ options.name}
                  onChange={handleTimeZoneChange}
                  defaultValue={{value:activeData.timezone,name:activeData.timezoneName}}
                  disableClearable
                  disabled={!timeZone}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="text"
                      required
                      margin="normal"
                      {...params}
                      label="Time Zone"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </Grid>
              )}
            <Grid item xs={6}>
            <TextField
              label="Country Phone Code"
              variant="outlined"
              type="text"
              margin="normal"
              required
              fullWidth
              name="countryPhoneCode"
              value={formData.defaultPhoneCountryCode}
              onChange={handleInputChange}
            />
          </Grid>
            </>
            )}
            <Grid item xs={6}>
              <Autocomplete
                id="building"
                options={allBuildings}
                getOptionLabel={option => {
                  return option.name;
                }}
                onChange={onBuildingChange}
                value={buildingsSelected}
                multiple
                disableClearable
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Buildings"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="floor"
                options={availableFloors}
                getOptionLabel={option => option.name}
                onChange={onFloorChange}
                value={floorSelected}
                multiple
                disableClearable
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Floors"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
                  <TextField
                    label="Domain Id"
                    variant="outlined"
                    type="text"
                    margin="normal"
                    required
                    fullWidth
                    name="emailDomainId"
                    value={domain}
                    onChange={handleDomainChange}
                  />
                </Grid>
            {user.role === 'superadmin' ? (
              <>
                <Grid item xs={6}>
                  <Autocomplete
                    id="country-select-demo"
                    options={exchangeDetail}
                    getOptionLabel={(option) => option.name}
                    onChange={handleExchangeId}
                    value={selectedExchangeId}
                    disableClearable
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        required
                        margin="normal"
                        {...params}
                        label="Exchange Details"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                      <Checkbox 
                      checked={checked}
                      onChange={(e) => handleAdEnabled(e) } 
                      />
                    }
                      label="IsAdEnabled"
                    />
                  </FormGroup>
                </Grid>
              </>
            ) : null}
          </Grid>
          <Grid
            container
            direction="row-reverse"
            alignItems="center"
            spacing={3}
            className={classes.marginNormal}
          >
            <Grid item>
              <Button
                onClick={handleAction}
                disabled={isLoading}
                variant="contained"
                color="primary"
              >
                {isLoading ? "Loading..." : activeData.id ? "Update" : "Add"}
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={handleClose} variant="contained">
                Cancel
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <DropzoneDialog
        open={dropzoneOpen}
        onSave={files => {
          handleUpload(files);
        }}
        acceptedFiles={[dropzone.type + "/*"]}
        showPreviews={true}
        filesLimit={1}
        maxFileSize={5000000000}
        dialogTitle={dropzone.title}
        onClose={() => setDropzoneOpen(false)}
        submitButtonText="Upload"
        cancelButtonText="Cancel"
      />
    </div>
  );
};

export default OrganizationModal;