import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  Link,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import LogRocket from "logrocket";
import React, { useEffect, useState } from "react";
import { LogIn } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Page from "src/components/Page";
import {
  login,
  business,
  organizationSettings,
  organization
} from "../../actions";
import api from "../../utils/api";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: "100%"
  },
  container: {
    padding: theme.spacing(3),
    backgroundColor: "#fff"
  },
  img: {
    padding: theme.spacing(1)
  },
  forgotLink: {
    cursor: "pointer"
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const org = useSelector(state => state.org);
  const businessOnState = useSelector(state => state.business);
  const orgSettings = useSelector(state => state.orgSettings);
  const [accountType, setAccountType] = useState("");
  const [accountAdmin, setAccountAdmin] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [domain, setDomain] = useState(
    businessOnState && businessOnState.id ? businessOnState.domain : ""
  );
  const [error, setError] = useState("");
  const navigate = useNavigate();

  
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  useEffect(() => {
    (async () => {
      try {
        if (businessOnState) {
          setDomain(businessOnState.domain);
          const orgList = await api(
            "GET",
            "organization/getOrganization/" + businessOnState.id,
            null,
            false
          );
          setCompanyList(orgList.filter(org => org.isActive));
          if (orgList.length === 1) {
            dispatch(organization(orgList[0]));
          }
        }
      } catch (error) {
        setError("Could not fetch organization list in this for this account");
      }
    })();
  }, [businessOnState]);

  const handleSelectOrganization = async orgId => {
    dispatch(organization(companyList.find(item => (item.id == orgId))));
  };

  const findDomainFromInput = async () => {
    try {
      const validateDomain = await api(
        "GET",
        "business/searchByDomain/" + domain,
        null,
        false
      );
      if (validateDomain.id) {
        dispatch(business(validateDomain));
        setError("");
      } else {
        setError("Could not find account for this domain");
      }
    } catch (error) {
      setError("Could not find account for this domain");
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setError("");
    if (
      email.trim() === "" ||
      password === "" ||
      domain.trim() === "" ||
      accountType === ""
    ) {
      setError("Please fill up all the fields");
      return;
    }
    if (!accountAdmin && !org) {
      setError("Please select an organization");
      return;
    }
    try {
      let validator = await api(
        "POST",
        "auth/login",
        accountAdmin
          ? { contact: email, password, domain, loginType: "businessAdmin" }
          : {
              contact: email,
              password,
              domain,
              organizationId: org.id
            },
        // credentials,
        false
      );

      localStorage.setItem("token", validator.token);
      var orgSettings = {};
      if (validator.data.loginType !== "businessAdmin") {
        orgSettings = await api(
          "GET",
          "organization/getOrgSettings/" + validator.data.organizationId,
          null,
          true
        );
        dispatch(organizationSettings(orgSettings));
      }

      const businessSettings = await api(
        "GET",
        "business/getBusinessSettings/" + validator.data.businessId,
        null,
        true
      );
      dispatch(organizationSettings({ ...orgSettings, ...businessSettings }));

      dispatch(login(validator));

      LogRocket.identify("User", {
        name: validator.data.name,
        email: validator.data.email,
        mobile: validator.data.mobile,
        id: validator.data.id,
        org: validator.data.organizationId
      });
      navigate("/");
    } catch (error) {
      if (error.response && error.response.data) setError(error.response.data);
    }
  };

  return (
    <Page className={classes.root} title="Login">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="flexStart"
        className={classes.container}
      >
        <Box>
          <img
            src={
              businessOnState?.logo ??
              "https://s3.ap-south-1.amazonaws.com/img.thesentinel.io/businessLogo/1648206672744.png"
            }
            style={{ height: 60 }}
            className={classes.img}
            alt="logo"
          />
        </Box>

        <Grid container direction="row" spacing={3} alignItems="start">
          <Grid item md={8} xs={12} style={{ padding: 16 }}>
            <br />
            <Typography
              color="primary"
              variant="h2"
              component="h2"
              style={{ fontWeight: 900 }}
            >
              The Sentinel
            </Typography>
            <br />
            <Typography variant="h4" component="h2" style={{ fontWeight: 300 }}>
              Powerful features built to create unforgettable experiences. Amaze
              visitor, employees and contractors
            </Typography>
            <img
              src="/images/dashboard.png"
              style={{ maxWidth: "100%" }}
              alt="logo"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Box p={3}>
              {businessOnState && businessOnState.id && (
                <Box mb={3}>
                  <Typography
                    color="primary"
                    variant="h1"
                    component="span"
                    style={{ fontWeight: 900 }}
                  >
                    Welcome to {businessOnState.name}
                  </Typography>
                </Box>
              )}

              <form onSubmit={handleSubmit}>
                <Box mb={1}>
                  <Typography color="textPrimary" variant="h2">
                    Sign in
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Sign in using your email/phone number and password
                  </Typography>
                </Box>

                {error !== "" && (
                  <Alert
                    onClose={() => {
                      setError("");
                    }}
                    severity="error"
                  >
                    {error}
                  </Alert>
                )}

                {(!businessOnState || !businessOnState.id) && (
                  <TextField
                    fullWidth
                    label="Domain"
                    margin="normal"
                    name="domain"
                    id="domain"
                    onBlur={findDomainFromInput}
                    onChange={e => setDomain(e.target.value)}
                    type="text"
                    value={domain}
                    variant="outlined"
                  />
                )}
                <FormControl component="fieldset" margin="normal">
                  <FormLabel component="legend">
                    <b>Sign in as</b>
                  </FormLabel>
                  <RadioGroup
                    row
                    onChange={e => {
                      setAccountType(e.target.value);
                      if (e.target.value === "accountAdmin")
                        setAccountAdmin(true);
                      else setAccountAdmin(false);
                    }}
                  >
                    <FormControlLabel
                      value="visitor"
                      control={<Radio />}
                      label="Visitor"
                    />
                    <FormControlLabel
                      value="employee"
                      control={<Radio />}
                      label="Employee"
                    />
                    <FormControlLabel
                      value="accountAdmin"
                      control={<Radio />}
                      label="Global Admin"
                    />
                  </RadioGroup>
                </FormControl>

                {!accountAdmin && companyList.length > 1 && (
                  <FormControl
                    variant="outlined"
                    style={{ width: "100%" }}
                    margin="normal"
                  >
                    <InputLabel id="company"> Select company</InputLabel>
                    <Select
                      labelId="company"
                      id="company"
                      label="Select company"
                      displayEmpty
                      onChange={e => {
                        handleSelectOrganization(e.target.value);
                      }}
                      autoWidth
                    >
                      {companyList.map(item => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}

                <TextField
                  fullWidth
                  label="Email or Phone number"
                  margin="normal"
                  name="email"
                  id="email"
                  onChange={e => setEmail(e.target.value)}
                  type="text"
                  value={email}
                  variant="outlined"
                />
                <TextField
                  fullWidth
                  label="Password"
                  margin="normal"
                  name="password"
                  id="password"
                  onChange={e => setPassword(e.target.value)}
                  type="password"
                  value={password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    fullWidth
                    size="large"
                    id="submit"
                    type="submit"
                    variant="contained"
                    endIcon={<LogIn />}
                  >
                    Sign in
                  </Button>
                </Box>
              </form>
              <Box>
                <Grid container direction="row" justify="space-between">
                  <Grid item>
                    <Typography
                      color="primary"
                      variant="h6"
                      component="span"
                      style={{ fontWeight: 900 }}
                      className={classes.forgotLink}
                      onClick={() => navigate("/forgotpassword")}
                    >
                      <Link>Forgot password?</Link>
                    </Typography>
                  </Grid>
                  <Grid item>
                    {/* <Typography
                      color="primary"
                      variant="h6"
                      component="span"
                      style={{ fontWeight: 900 }}
                      className={classes.forgotLink}
                      onClick={() => navigate("/register")}
                    >
                      <Link>Book a meeting</Link>
                    </Typography> */}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <br />
        <Typography variant="h2" color="primary" align="center">
          A simple pick and choose model with effortless plug and play
          implementation.
        </Typography>
        <br />
        <br />
        <Box px={3}>
          <Grid container direction="row" spacing={3} alignItems="center">
            <Grid item md={4} xs={12}>
              <Box
                p={3}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center"
                }}
              >
                <img
                  src={"/images/image1.svg"}
                  style={{ height: 48, padding: "6px 12px" }}
                  alt="logo"
                />
                <Typography variant="h5" component="h2">
                  All the integrations you need
                </Typography>
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <Box
                p={3}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center"
                }}
              >
                <img
                  src={"/images/image2.svg"}
                  style={{ height: 48, padding: "6px 12px" }}
                  alt="logo"
                />
                <Typography variant="h5" component="h2">
                  End-to-end space management from 1 dashboard
                </Typography>
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <Box
                p={3}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center"
                }}
              >
                <img
                  src={"/images/image3.svg"}
                  style={{ height: 48, padding: "6px 12px" }}
                  alt="logo"
                />
                <Typography variant="h5" component="h2">
                  Real-time notifications (Text, in-app, Mail)
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box px={3} my={3}>
          <Grid container direction="row" spacing={3} alignItems="center">
            <Grid item md={6} xs={12}>
              <Typography variant="h2" component="h2" color="primary">
                Visitor Management
              </Typography>
              <Typography variant="h4" component="h2" color="textPrimary">
                Delight visitor and contractors with swift check-in.
              </Typography>
              <br />
              <List>
                <ListItem disableGutters style={{ padding: 0 }}>
                  <ListItemIcon style={{ minWidth: 32 }}>
                    <CheckCircle color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Real-time notifications" />
                </ListItem>
                <ListItem disableGutters style={{ padding: 0 }}>
                  <ListItemIcon style={{ minWidth: 32 }}>
                    <CheckCircle color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="In-office navigation" />
                </ListItem>
                <ListItem disableGutters style={{ padding: 0 }}>
                  <ListItemIcon style={{ minWidth: 32 }}>
                    <CheckCircle color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Microsoft and Google integration" />
                </ListItem>
                <ListItem disableGutters style={{ padding: 0 }}>
                  <ListItemIcon style={{ minWidth: 32 }}>
                    <CheckCircle color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Visitor-tracking" />
                </ListItem>
                <ListItem disableGutters style={{ padding: 0 }}>
                  <ListItemIcon style={{ minWidth: 32 }}>
                    <CheckCircle color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Ad-hoc & meeting workflows" />
                </ListItem>
              </List>
              <br />
              <br />
              <Typography variant="h2" component="h2" color="primary">
                Employee Check-in & attendance
              </Typography>
              <Typography variant="h4" component="h2" color="textPrimary">
                Enable your team with more time and less documentation.
              </Typography>
              <br />
              <List>
                <ListItem disableGutters style={{ padding: 0 }}>
                  <ListItemIcon style={{ minWidth: 32 }}>
                    <CheckCircle color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Rapid temp. & safety check-in" />
                </ListItem>
                <ListItem disableGutters style={{ padding: 0 }}>
                  <ListItemIcon style={{ minWidth: 32 }}>
                    <CheckCircle color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Check-in and Check-out" />
                </ListItem>
                <ListItem disableGutters style={{ padding: 0 }}>
                  <ListItemIcon style={{ minWidth: 32 }}>
                    <CheckCircle color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Microsoft and Google integration" />
                </ListItem>
                <ListItem disableGutters style={{ padding: 0 }}>
                  <ListItemIcon style={{ minWidth: 32 }}>
                    <CheckCircle color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Facial Recognition based attendance" />
                </ListItem>
                <ListItem disableGutters style={{ padding: 0 }}>
                  <ListItemIcon style={{ minWidth: 32 }}>
                    <CheckCircle color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Cloud-native employee management" />
                </ListItem>
              </List>
              <br />
              <br />
              <Typography variant="h2" component="h2" color="primary">
                Meeting Room Management
              </Typography>
              <Typography variant="h4" component="h2" color="textPrimary">
                Preview, schedule and manage meeting room bookings.
              </Typography>
              <br />
              <List>
                <ListItem disableGutters style={{ padding: 0 }}>
                  <ListItemIcon style={{ minWidth: 32 }}>
                    <CheckCircle color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Smart meeting-room allocation" />
                </ListItem>
                <ListItem disableGutters style={{ padding: 0 }}>
                  <ListItemIcon style={{ minWidth: 32 }}>
                    <CheckCircle color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Microsoft & Google Integrations" />
                </ListItem>
                <ListItem disableGutters style={{ padding: 0 }}>
                  <ListItemIcon style={{ minWidth: 32 }}>
                    <CheckCircle color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Meeting start & end time reminders" />
                </ListItem>
                <ListItem disableGutters style={{ padding: 0 }}>
                  <ListItemIcon style={{ minWidth: 32 }}>
                    <CheckCircle color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Integration with building management systems for energy efficiency" />
                </ListItem>
              </List>
              <br />
              <br />
              <Typography variant="h2" component="h2" color="primary">
                Parking management
              </Typography>
              <Typography variant="h4" component="h2" color="textPrimary">
                Number-plate recognition for documentation and spot-blocking.
                Parking-space occupancy metrics.
              </Typography>
              <br />
              <List>
                <ListItem disableGutters style={{ padding: 0 }}>
                  <ListItemIcon style={{ minWidth: 32 }}>
                    <CheckCircle color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Number-plate recognition" />
                </ListItem>
                <ListItem disableGutters style={{ padding: 0 }}>
                  <ListItemIcon style={{ minWidth: 32 }}>
                    <CheckCircle color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Easy-to-read dashboards" />
                </ListItem>
                <ListItem disableGutters style={{ padding: 0 }}>
                  <ListItemIcon style={{ minWidth: 32 }}>
                    <CheckCircle color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Microsoft & Google Integrations" />
                </ListItem>
                <ListItem disableGutters style={{ padding: 0 }}>
                  <ListItemIcon style={{ minWidth: 32 }}>
                    <CheckCircle color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Navigation for visitors to available parking space" />
                </ListItem>
              </List>
            </Grid>
            <Grid item md={6} xs={12}>
              <img
                src="/images/meetingList.png"
                style={{ maxWidth: "100%" }}
                alt="logo"
              />
              <br />
              <img
                src="/images/activities.png"
                style={{ maxWidth: "100%" }}
                alt="logo"
              />
              <br />
              <img
                src="/images/visitList.png"
                style={{ maxWidth: "100%" }}
                alt="logo"
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Page>
  );
};

export default LoginView;
