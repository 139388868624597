
import { Box, Button, Card, CardContent, Checkbox, FormControlLabel, Grid, makeStyles, TextField, Typography } from "@material-ui/core";
import { captureException } from "@sentry/react";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
// import { Box } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import api from "src/utils/api";
import Loader from "src/utils/loader";
// import Loader from "../../../utils/loader";

const useStyles = makeStyles({
    root: {},
    item: {
      display: "flex",
      flexDirection: "column"
    }
  });

const Roster = ({ className, ...rest }) =>
{
    const classes = useStyles();
    const [newOrgSettings, setNewOrgSettings] = useState({});
    const [loading, setLoading] = useState(true);
    const user = useSelector(state => state.user);
    const orgSettings = useSelector(state => state.orgSettings);
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
          try {
            const newdata =
              user.role !== "businessAdmin"
                ? await api(
                    "POST",
                    "organization/getOrgSettingsFilteredByKeys/" +
                      user.organizationId,
                    {
                      keys: [
                        "rosterManagementEnabledOrg",
                        "rosterManagementAdminEnabled",
                        "individualSupervisorRosteringEnabled",
                        "rosterManagementAutoApproveRequests",
                        "rosterManagementDailyCapacityPercentage",
                        "rosterManagementAdminInitiated",
                        "rosterManagementEmployeeInitiated",
                        "customInstantMeetingMessage"
                      ]
                    },
                    true
                  )
                : {};
            const newdatabusiness = await api(
              "POST",
              "business/getBusinessSettingsFilteredByKeys/" + user.businessId,
              {
                keys: [
                  "rosterManagementEnabled"
                  
                ]
              },
              true
            );
            setNewOrgSettings({ ...newdata, ...newdatabusiness });
            setLoading(false);
          } catch (error) {
            captureException(error);
          }
        })();
      }, []);

      const handleChange = e => {
        setNewOrgSettings({ ...newOrgSettings, [e.target.name]: e.target.checked });
      };

      const handleSubmit = async e => {
        setLoading(true);
        e.preventDefault();
        try {
          user.role !== "businessAdmin" &&
            (await api(
              "PATCH",
              "organization/settings/" + user.organizationId,
              {
                rosterManagementEnabledOrg:
                  newOrgSettings.rosterManagementEnabledOrg,
                rosterManagementAdminEnabled:
                  newOrgSettings.rosterManagementAdminEnabled,
                individualSupervisorRosteringEnabled:
                  newOrgSettings.individualSupervisorRosteringEnabled,
                rosterManagementAutoApproveRequests:
                  newOrgSettings.rosterManagementAutoApproveRequests,
                rosterManagementDailyCapacityPercentage:
                  newOrgSettings.rosterManagementDailyCapacityPercentage,
                rosterManagementAdminInitiated:
                  newOrgSettings.rosterManagementAdminInitiated,
                rosterManagementEmployeeInitiated:
                  newOrgSettings.rosterManagementEmployeeInitiated
              },
              true
            ));
          user.role === "businessAdmin" &&
            (await api(
              "PATCH",
              "business/settings/" + user.businessId,
              {
                rosterManagementEnabled: newOrgSettings.rosterManagementEnabled
              },
              true
            ));
          const newdata =
            user.role !== "businessAdmin"
              ? await api(
                  "POST",
                  "organization/getOrgSettingsFilteredByKeys/" +
                    user.organizationId,
                  {
                    keys: [
                      "rosterManagementEnabledOrg",
                      "rosterManagementAdminEnabled",
                      "individualSupervisorRosteringEnabled",
                      "rosterManagementDailyCapacityPercentage",
                      "rosterManagementAutoApproveRequests",
                      "rosterManagementAdminInitiated",
                      "rosterManagementEmployeeInitiated",
                    ]
                  },
                  true
                )
              : {};
          const newdatabusiness = await api(
            "POST",
            "business/getBusinessSettingsFilteredByKeys/" + user.businessId,
            {
              keys: [
                "rosterManagementEnabled"
              ]
            },
            true
          );
          setNewOrgSettings({ ...newdata, ...newdatabusiness });
          setLoading(false);
        } catch (error) {
          captureException(error);
        }
      };

    return loading ? (<Loader />):(
        <>
        <Box width="100%" className={clsx(classes.root, className)} {...rest}>
        <form onSubmit={handleSubmit}>
        <Grid container direction="row" spacing={3}>
        <Grid item sm={12} md={12}>
        <Card>
        <CardContent>
        {newOrgSettings.rosterManagementEnabled && (
            <>
              <Typography
                color="textPrimary"
                gutterBottom
                variant="h5"
              >
                Roster Management
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      newOrgSettings.rosterManagementEnabledOrg
                    }
                    onChange={handleChange}
                    name="rosterManagementEnabledOrg"
                  />
                }
                label="Enable"
              />
              <br />
              {newOrgSettings.rosterManagementEnabledOrg && (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          newOrgSettings.rosterManagementAdminEnabled
                        }
                        onChange={e => {
                          if (e.target.checked === true) {
                            setNewOrgSettings({
                              ...newOrgSettings,
                              rosterManagementAdminEnabled: true,
                              individualSupervisorRosteringEnabled: false
                            });
                          } else {
                            setNewOrgSettings({
                              ...newOrgSettings,
                              rosterManagementAdminEnabled: false
                            });
                          }
                        }}
                        name="rosterManagementAdminEnabled"
                      />
                    }
                    label="Enable department level admin for roster management"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          newOrgSettings.individualSupervisorRosteringEnabled
                        }
                        onChange={e => {
                          if (e.target.checked === true) {
                            setNewOrgSettings({
                              ...newOrgSettings,
                              individualSupervisorRosteringEnabled: true,
                              rosterManagementAdminEnabled: false
                            });
                          } else {
                            setNewOrgSettings({
                              ...newOrgSettings,
                              individualSupervisorRosteringEnabled: false
                            });
                          }
                        }}
                        name="individualSupervisorRosteringEnabled"
                      />
                    }
                    label="Enable roster management for individual supervisors"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          newOrgSettings.rosterManagementAutoApproveRequests
                        }
                        onChange={handleChange}
                        name="rosterManagementAutoApproveRequests"
                      />
                    }
                    label="Auto-approve incoming requests"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          newOrgSettings.rosterManagementAdminInitiated
                        }
                        onChange={handleChange}
                        name="rosterManagementAdminInitiated"
                      />
                    }
                    label="Allow admin to initiate new roster requests"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          newOrgSettings.rosterManagementEmployeeInitiated
                        }
                        onChange={handleChange}
                        name="rosterManagementEmployeeInitiated"
                      />
                    }
                    label="Allow employees to enroll in daily rosters"
                  />
                </>
              )}
              {newOrgSettings.rosterManagementEnabledOrg && (
                <TextField
                  label="Roster Daily Capacity Percentage"
                  fullWidth
                  variant="outlined"
                  type="number"
                  margin="normal"
                  name="rosterManagementDailyCapacityPercentage"
                  onChange={e =>
                    setNewOrgSettings({
                      ...newOrgSettings,
                      rosterManagementDailyCapacityPercentage:
                        e.target.value
                    })
                  }
                  value={
                    newOrgSettings.rosterManagementDailyCapacityPercentage
                  }
                />
              )}
         </>
          )}
        </CardContent>
          </Card>
          </Grid>
          </Grid>
          <br/>
            <Card>
            <CardContent>
                <Box display="flex" justifyContent="flex-end">
                <Button color="primary" variant="contained" type="submit">
                    Update
                </Button>
                </Box>
            </CardContent>
            </Card>       
          </form>
          </Box>
          </>
    );
};

Roster.propTypes = {
    className: PropTypes.string
  };
  
  export default Roster;