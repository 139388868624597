import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@material-ui/core";
import { captureException } from "@sentry/browser";
import axios from "axios";
import clsx from "clsx";
import { DropzoneDialog } from "material-ui-dropzone";
import PropTypes from "prop-types";
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { business, organizationSettings, toastAction } from "../../../actions";
import api from "../../../utils/api";
import baseurl from "../../../utils/baseurl";
import Loader from "../../../utils/loader";
import Questions from "../question/Question";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Checkout from "../checkout/Checkout";

const useStyles = makeStyles({
  root: {},
  item: {
    display: "flex",
    flexDirection: "column"
  }
});

const Checkin = ({ className, ...rest }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [newOrgSettings, setNewOrgSettings] = useState({});
  const [customQrMessage, setCustomeQRMessage] = useState(false);
  const [customBaggageMessage, setCustomeBaggageMessage] = useState(false);
  const [customQrImage, setCustomQRImage] = useState(false);
  const [customCheckOutMessage, setCustomCheckOutMessage] = useState(false);
  const [customCheckInMessage, setCustomCheckInMessage] = useState(false);
  const [customCheckInImage, setCustomCheckInImage] = useState(false);
  const [dropzoneOpen, setDropzoneOpen] = useState(false);
  const [dropzone, setDropzone] = useState({});
  const [viewOpen, setViewOpen] = useState(false);
  const [viewUrl, setViewUrl] = useState("");
  const [viewType, setViewType] = useState("");
  const [questionModalOpen, setQuestionModalOpen] = useState(false);
  const [valueTab, setValueTab] = useState("one");
  const [toggleValue, setToggleValue] = useState("none");
  const [
    customInstantMeetingMessage,
    setCustomInstantMeetingMessage
  ] = useState(false);
  const user = useSelector(state => state.user);
  const orgSettings = useSelector(state => state.orgSettings);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(organizationSettings({ ...orgSettings, ...newOrgSettings }));
  }, [loading]);

  useEffect(() => {
    (async () => {
      try {
        const newdata =
          user.role !== "businessAdmin"
            ? await api(
                "POST",
                "organization/getOrgSettingsFilteredByKeys/" +
                  user.organizationId,
                {
                  keys: [
                    "visitorCheckInApprovalType",
                    "customQrMessage",
                    "customQrImage",
                    "customCheckOutMessage",
                    "customCheckInMessage",
                    "customCheckInImage",
                    "customCheckInDelay",
                    "skipCheckinForEmployeeMeeting",
                    "skipCheckinForVisitorMeeting",
                    "trailingMeetingEnabledOrg",
                    "trailingMeetingAllowOverlap",
                    "trailingMeetingSkipOnOverlap",
                    "trailingMeetingDurationMinutes",
                    "rosterManagementEnabledOrg",
                    "rosterManagementAdminEnabled",
                    "individualSupervisorRosteringEnabled",
                    "rosterManagementAutoApproveRequests",
                    "rosterManagementDailyCapacityPercentage",
                    "rosterManagementAdminInitiated",
                    "rosterManagementEmployeeInitiated",
                    "customInstantMeetingMessage"
                  ]
                },
                true
              )
            : {};
        const newdatabusiness = await api(
          "POST",
          "business/getBusinessSettingsFilteredByKeys/" + user.businessId,
          {
            keys: [
              "showSafetySurvey",
              "showSafetyVideo",
              "safetyVideoLink",
              "captureCheckInPhoto",
              "captureCheckInIdPhoto",
              "captureIdPhotoType",
              "customCheckInDelay",
              "checkoutDispenseType",
              "checkInVisitProfilePhoto",
              "checkInVisitIdPhoto",
              "visitProfilePhotoWhileCheckIn",
              "visitIdPhotoWhileCheckIn",
              "instantMeetingEnable",
              "showHelp",
              "addParticipantCountEnable",
              "checkInSystemEnable",
              "tempMeasure",
              "tempLog",
              "temperatureDelay",
              "deviceContactType",
              "showHomeScreen",
              "baggageBusinessEnable",
              "baggageFields",
              "autoCheckin",
              "safetySurveyWhileBooking",
              "allowAllAnswers",
              "trailingMeetingEnabled",
              "rosterManagementEnabled"
              
            ]
          },
          true
        );
        setNewOrgSettings({ ...newdata, ...newdatabusiness });
        setLoading(false);
      } catch (error) {
        captureException(error);
      }
    })();
  }, []);

  const handleSubmit = async e => {
    setLoading(true);
    e.preventDefault();
    try {
      user.role !== "businessAdmin" &&
        (await api(
          "PATCH",
          "organization/settings/" + user.organizationId,
          {
            customQrMessage: newOrgSettings.customQrMessage,
            customQrImage: newOrgSettings.customQrImage,
            customCheckOutMessage: newOrgSettings.customCheckOutMessage,
            customCheckInMessage: newOrgSettings.customCheckInMessage,
            customCheckInImage: newOrgSettings.customCheckInImage,
            customCheckInDelay: newOrgSettings.customCheckInDelay,
            skipCheckinForEmployeeMeeting:
              newOrgSettings.skipCheckinForEmployeeMeeting,
            skipCheckinForVisitorMeeting:
              newOrgSettings.skipCheckinForVisitorMeeting,
            visitorCheckInApprovalType:
              newOrgSettings.visitorCheckInApprovalType,
            trailingMeetingEnabledOrg: newOrgSettings.trailingMeetingEnabledOrg,
            trailingMeetingAllowOverlap:
              newOrgSettings.trailingMeetingAllowOverlap,
            trailingMeetingSkipOnOverlap:
              newOrgSettings.trailingMeetingSkipOnOverlap,
            trailingMeetingDurationMinutes:
              newOrgSettings.trailingMeetingDurationMinutes,
            rosterManagementEnabledOrg:
              newOrgSettings.rosterManagementEnabledOrg,
            rosterManagementAdminEnabled:
              newOrgSettings.rosterManagementAdminEnabled,
            individualSupervisorRosteringEnabled:
              newOrgSettings.individualSupervisorRosteringEnabled,
            rosterManagementAutoApproveRequests:
              newOrgSettings.rosterManagementAutoApproveRequests,
            rosterManagementDailyCapacityPercentage:
              newOrgSettings.rosterManagementDailyCapacityPercentage,
            rosterManagementAdminInitiated:
              newOrgSettings.rosterManagementAdminInitiated,
            rosterManagementEmployeeInitiated:
              newOrgSettings.rosterManagementEmployeeInitiated,
            customInstantMeetingMessage: 
              newOrgSettings.customInstantMeetingMessage
          },
          true
        ));
      user.role === "businessAdmin" &&
        (await api(
          "PATCH",
          "business/settings/" + user.businessId,
          {
            showSafetySurvey: newOrgSettings.showSafetySurvey,

            showSafetyVideo: newOrgSettings.showSafetyVideo,
            safetyVideoLink: newOrgSettings.safetyVideoLink,
            captureCheckInPhoto: newOrgSettings.captureCheckInPhoto,
            captureCheckInIdPhoto: newOrgSettings.captureCheckInIdPhoto,
            captureIdPhotoType: newOrgSettings.captureIdPhotoType,
            customCheckInDelay: newOrgSettings.customCheckInDelay,
            checkoutDispenseType: newOrgSettings.checkoutDispenseType,
            checkInVisitProfilePhoto: newOrgSettings.checkInVisitProfilePhoto,
            checkInVisitIdPhoto: newOrgSettings.checkInVisitIdPhoto,
            visitProfilePhotoWhileCheckIn:
              newOrgSettings.visitProfilePhotoWhileCheckIn,
            visitIdPhotoWhileCheckIn: newOrgSettings.visitIdPhotoWhileCheckIn,
            instantMeetingEnable: newOrgSettings.instantMeetingEnable,
            showHelp: newOrgSettings.showHelp,
            addParticipantCountEnable: newOrgSettings.addParticipantCountEnable,
            checkInSystemEnable: newOrgSettings.checkInSystemEnable,
            tempMeasure: newOrgSettings.tempMeasure,
            tempLog: newOrgSettings.tempLog,
            temperatureDelay: newOrgSettings.temperatureDelay,
            deviceContactType: newOrgSettings.deviceContactType,
            showHomeScreen: newOrgSettings.showHomeScreen,
            baggageBusinessEnable: newOrgSettings.baggageBusinessEnable,
            baggageFields: newOrgSettings.baggageFields,
            autoCheckin: newOrgSettings.autoCheckin,
            safetySurveyWhileBooking: newOrgSettings.safetySurveyWhileBooking,
            allowAllAnswers: newOrgSettings.allowAllAnswers,
            trailingMeetingEnabled: newOrgSettings.trailingMeetingEnabled,
            rosterManagementEnabled: newOrgSettings.rosterManagementEnabled
          },
          true
        ));
      const newdata =
        user.role !== "businessAdmin"
          ? await api(
              "POST",
              "organization/getOrgSettingsFilteredByKeys/" +
                user.organizationId,
              {
                keys: [
                  "visitorCheckInApprovalType",
                  "customQrMessage",
                  "customQrImage",
                  "customCheckOutMessage",
                  "customCheckInMessage",
                  "customCheckInImage",
                  "customCheckInDelay",
                  "skipCheckinForEmployeeMeeting",
                  "skipCheckinForVisitorMeeting",
                  "trailingMeetingEnabledOrg",
                  "trailingMeetingAllowOverlap",
                  "trailingMeetingSkipOnOverlap",
                  "trailingMeetingDurationMinutes",
                  "rosterManagementEnabledOrg",
                  "rosterManagementAdminEnabled",
                  "individualSupervisorRosteringEnabled",
                  "rosterManagementDailyCapacityPercentage",
                  "rosterManagementAutoApproveRequests",
                  "rosterManagementAdminInitiated",
                  "rosterManagementEmployeeInitiated",
                  "customInstantMeetingMessage"
                ]
              },
              true
            )
          : {};
      const newdatabusiness = await api(
        "POST",
        "business/getBusinessSettingsFilteredByKeys/" + user.businessId,
        {
          keys: [
            "showSafetySurvey",
            "showSafetyVideo",
            "safetyVideoLink",
            "captureCheckInPhoto",
            "captureCheckInIdPhoto",
            "captureIdPhotoType",
            "customCheckInDelay",
            "checkoutDispenseType",
            "checkInVisitProfilePhoto",
            "checkInVisitIdPhoto",
            "visitProfilePhotoWhileCheckIn",
            "visitIdPhotoWhileCheckIn",
            "instantMeetingEnable",
            "showHelp",
            "addParticipantCountEnable",
            "checkInSystemEnable",
            "tempMeasure",
            "tempLog",
            "temperatureDelay",
            "deviceContactType",
            "showHomeScreen",
            "baggageBusinessEnable",
            "baggageFields",
            "autoCheckin",
            "safetySurveyWhileBooking",
            "allowAllAnswers",
            "trailingMeetingEnabled",
            "rosterManagementEnabled"
          ]
        },
        true
      );
      setNewOrgSettings({ ...newdata, ...newdatabusiness });
      setLoading(false);
    } catch (error) {
      captureException(error);
    }
  };

  const handleChange = e => {
    setNewOrgSettings({ ...newOrgSettings, [e.target.name]: e.target.checked });
  };

  const handletoggleChange = (e, values) => {
    setNewOrgSettings({ ...newOrgSettings, [e.target.name]: e.target.checked });
  };

  const handleUploadDalog = (title, key, type, flag) => {
    setDropzone({
      title,
      key,
      type,
      flag
    });
    setDropzoneOpen(true);
  };

  const handleViewDialog = (url, type) => {
    setViewUrl(url);
    setViewType(type);
    setViewOpen(true);
  };

  const handleUpload = async files => {
    dispatch(
      toastAction({
        status: true,
        message: "Uploading file ... ",
        type: "info",
        noIcon: true
      })
    );
    const url = await upload(files[0], "orgSettings");
    setLoading(true);
    dispatch(
      toastAction({
        status: true,
        message: "Updating settings..",
        type: "success",
        noIcon: true
      })
    );
    await api(
      "PATCH",
      "organization/settings/" + user.organizationId,
      dropzone.flag
        ? { [dropzone.key]: url, [dropzone.flag]: true }
        : { [dropzone.key]: url },
      true
    );
    const newdata = await api(
      "POST",
      "organization/getOrgSettingsFilteredByKeys/" + user.organizationId,
      {
        keys: dropzone.flag ? [dropzone.key, dropzone.flag] : [dropzone.key]
      },
      true
    );
    setDropzoneOpen(false);
    setNewOrgSettings({ ...newOrgSettings, ...newdata });
    setLoading(false);
    dispatch(
      toastAction({
        status: true,
        message: "Settings updated",
        type: "success"
      })
    );
  };

  const upload = async (file, folder) => {
    const token = localStorage ? localStorage.getItem("token") : null;
    let filenameSplit = file.name.split(".");
    const formData = new FormData();
    formData.append(
      "file",
      file,
      Date.now() + "." + filenameSplit[filenameSplit.length - 1]
    );
    formData.append("fileStorage", folder);

    try {
      let response = await axios({
        method: "POST",
        url: baseurl + "file/upload",
        data: formData,
        headers: {
          Authorization: "Bearer " + token
        },
        onUploadProgress: progressEvent => {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          dispatch(
            toastAction({
              status: true,
              message: "Uploading file ... " + percentCompleted + "%",
              type: "info",
              noIcon: true
            })
          );
        }
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const handletabChange = (event, newValue) => {
    event.persist();
    setValueTab(newValue);
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={valueTab}
          onChange={handletabChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab value="one" label="General" />
          <Tab value="two" label="Check in" />
          {user.role === "businessAdmin" && (
          <Tab value="three" label="Checkout" />)}
        </Tabs>
      </Box>

      {valueTab === "one" && (
        <Box width="100%" className={clsx(classes.root, className)} {...rest}>
          <form onSubmit={handleSubmit}>
            <Grid container direction="row" spacing={3}>
              <Grid item sm={12} md={12}>
                <Card>
                  <CardContent>
                  {user.role === "businessAdmin" && (
                    <>
                    <Typography color="textPrimary" gutterBottom variant="h6">
                      VMS Screen Saver (Touchpoint type)
                    </Typography>
                    <FormControl component="fieldset">
                      <FormGroup>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            ml: 3
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  newOrgSettings.deviceContactType ===
                                  "withOutContact"
                                }
                                onChange={e =>
                                  e.target.checked &&
                                  setNewOrgSettings({
                                    ...newOrgSettings,
                                    deviceContactType: "withOutContact"
                                  })
                                }
                              />
                            }
                            label="Contactless"
                          />
                        </Box>
                        {newOrgSettings.deviceContactType ===
                          "withOutContact" && (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              ml: 6
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={newOrgSettings.showHomeScreen}
                                  onChange={handleChange}
                                  name="showHomeScreen"
                                />
                              }
                              label="Show Homescreen"
                            />
                          </Box>
                        )}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            ml: 3
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled
                                checked={
                                  newOrgSettings.deviceContactType ===
                                  "withContact"
                                }
                                onChange={e =>
                                  e.target.checked &&
                                  setNewOrgSettings({
                                    ...newOrgSettings,
                                    deviceContactType: "withContact"
                                  })
                                }
                              />
                            }
                            label="With Contact"
                          />
                        </Box>
                      </FormGroup>
                    </FormControl>
                    <br />
                    <br />

                    {/* <Typography color="textPrimary" variant="h6">
                      Auto Checkin
                    </Typography>
                    <Typography
                      color="textPrimary"
                      gutterBottom
                      variant="body2"
                    >
                      Visitor will be automatically marked as checked in when
                      request is approved.
                    </Typography>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", ml: 3 }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={newOrgSettings.autoCheckin}
                            onChange={handleChange}
                            name="autoCheckin"
                          />
                        }
                        label="Enable"
                      />
                    </Box> */}
                    <br />
                        <Typography color="textPrimary" variant="h6">
                          Baggage registration for check in
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            ml: 3
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={newOrgSettings.baggageBusinessEnable}
                                onChange={e => {
                                  setNewOrgSettings({
                                    ...newOrgSettings,
                                    baggageBusinessEnable: e.target.checked
                                  });
                                }}
                                name="baggageBusinessEnable"
                              />
                            }
                            label="Enable"
                          />
                        </Box>

                        {newOrgSettings.baggageBusinessEnable && (
                          <>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                ml: 6
                              }}
                            >
                              <Typography color="textPrimary" variant="h6">
                                Baggage Fields
                              </Typography>
                              <FormControl component="fieldset">
                                <FormGroup row>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        disabled
                                        checked={JSON.parse(
                                          newOrgSettings.baggageFields
                                        ).includes("name")}
                                        onChange={e => {
                                          let newArr = JSON.parse(
                                            newOrgSettings.baggageFields
                                          ).filter(item => item !== "name");
                                          if (e.target.checked)
                                            newArr.push("name");
                                          setNewOrgSettings({
                                            ...newOrgSettings,
                                            baggageFields: JSON.stringify(
                                              newArr
                                            )
                                          });
                                        }}
                                        name="name"
                                      />
                                    }
                                    label="Type"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        disabled
                                        checked={JSON.parse(
                                          newOrgSettings.baggageFields
                                        ).includes("description")}
                                        onChange={e => {
                                          let newArr = JSON.parse(
                                            newOrgSettings.baggageFields
                                          ).filter(
                                            item => item !== "description"
                                          );
                                          if (e.target.checked)
                                            newArr.push("description");
                                          setNewOrgSettings({
                                            ...newOrgSettings,
                                            baggageFields: JSON.stringify(
                                              newArr
                                            )
                                          });
                                        }}
                                        name="description"
                                      />
                                    }
                                    label="Description"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={JSON.parse(
                                          newOrgSettings.baggageFields
                                        ).includes("model")}
                                        onChange={e => {
                                          let newArr = JSON.parse(
                                            newOrgSettings.baggageFields
                                          ).filter(item => item !== "model");
                                          if (e.target.checked)
                                            newArr.push("model");
                                          setNewOrgSettings({
                                            ...newOrgSettings,
                                            baggageFields: JSON.stringify(
                                              newArr
                                            )
                                          });
                                        }}
                                        name="model"
                                      />
                                    }
                                    label="Model"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={JSON.parse(
                                          newOrgSettings.baggageFields
                                        ).includes("serial")}
                                        onChange={e => {
                                          let newArr = JSON.parse(
                                            newOrgSettings.baggageFields
                                          ).filter(item => item !== "serial");
                                          if (e.target.checked)
                                            newArr.push("serial");
                                          setNewOrgSettings({
                                            ...newOrgSettings,
                                            baggageFields: JSON.stringify(
                                              newArr
                                            )
                                          });
                                        }}
                                        name="serial"
                                      />
                                    }
                                    label="Serial No."
                                  />
                                </FormGroup>
                              </FormControl>
                            </Box>
                          </>
                        )}
                        <br />
                        <Typography color="textPrimary" variant="h6">
                          Show safety survey
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            ml: 3
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={newOrgSettings.showSafetySurvey}
                                onChange={handleChange}
                                name="showSafetySurvey"
                              />
                            }
                            label="Enable"
                          />
                        </Box>
                        {newOrgSettings.showSafetySurvey && (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              ml: 6
                            }}
                          >
                            <Grid container direction="row" spacing={0}>
                              <Grid item xs={12}>
                                <Button
                                  onClick={e => setQuestionModalOpen(true)}
                                  variant="contained"
                                  color="primary"
                                >
                                  Set Survey Questions
                                </Button>
                              </Grid>
                              <Grid item xs={12}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        newOrgSettings.safetySurveyWhileBooking
                                      }
                                      onChange={handleChange}
                                      name="safetySurveyWhileBooking"
                                    />
                                  }
                                  label="Safety survey at the time of booking a meeting"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={newOrgSettings.allowAllAnswers}
                                      onChange={handleChange}
                                      name="allowAllAnswers"
                                    />
                                  }
                                  label="Allow submission on all types of answers"
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        )}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            ml: 3
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={newOrgSettings.showSafetyVideo}
                                onChange={handleChange}
                                name="showSafetyVideo"
                              />
                            }
                            label="Show safety video"
                          />
                        </Box>
                        {newOrgSettings.showSafetyVideo && (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              ml: 6
                            }}
                          >
                            <Grid container direction="row" spacing={3}>
                              {newOrgSettings.safetyVideoLink && (
                                <Grid item>
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={e =>
                                      handleViewDialog(
                                        newOrgSettings.safetyVideoLink,
                                        "video"
                                      )
                                    }
                                  >
                                    View
                                  </Button>
                                </Grid>
                              )}

                              <Grid item>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={e =>
                                    handleUploadDalog(
                                      "Safety Video",
                                      "safetyVideoLink",
                                      "video",
                                      "showSafetyVideo"
                                    )
                                  }
                                >
                                  Upload
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        )}

                        <br />

                        <Typography color="textPrimary" variant="h6">
                          Scan visitor's temperature
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            ml: 3
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={newOrgSettings.tempMeasure}
                                onChange={handleChange}
                                name="tempMeasure"
                              />
                            }
                            label="Enable"
                          />
                        </Box>
                        {newOrgSettings.tempMeasure && (
                          <>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                ml: 6
                              }}
                            >
                              <TextField
                                label="Temperature scanning time (in seconds)"
                                fullWidth
                                variant="outlined"
                                type="number"
                                margin="normal"
                                name="temperatureDelay"
                                onChange={e =>
                                  setNewOrgSettings({
                                    ...newOrgSettings,
                                    temperatureDelay: e.target.value
                                  })
                                }
                                value={newOrgSettings.temperatureDelay}
                              />
                            </Box>
                            <br />
                            <br />
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                ml: 6
                              }}
                            >
                              <Typography color="textPrimary" variant="h6">
                                Log all temperature scans
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                ml: 6
                              }}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={newOrgSettings.tempLog}
                                    onChange={handleChange}
                                    name="tempLog"
                                  />
                                }
                                label="Enable"
                              />
                            </Box>
                          </>
                        )}
                        <br />
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="h5"
                        >
                          Check In Token
                        </Typography>
                        <FormControl component="fieldset">
                          <FormGroup>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                ml: 3
                              }}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      newOrgSettings.checkoutDispenseType ===
                                      "none"
                                    }
                                    onChange={e =>
                                      e.target.checked &&
                                      setNewOrgSettings({
                                        ...newOrgSettings,
                                        checkoutDispenseType: "none"
                                      })
                                    }
                                  />
                                }
                                label="None"
                              />

                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      newOrgSettings.checkoutDispenseType ===
                                      "blecard"
                                    }
                                    onChange={e =>
                                      e.target.checked &&
                                      setNewOrgSettings({
                                        ...newOrgSettings,
                                        checkoutDispenseType: "blecard"
                                      })
                                    }
                                  />
                                }
                                label="BLE Card"
                              />
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={
                                      newOrgSettings.checkoutDispenseType ===
                                      "printpass"
                                    }
                                    onChange={e =>
                                      e.target.checked &&
                                      setNewOrgSettings({
                                        ...newOrgSettings,
                                        checkoutDispenseType: "printpass"
                                      })
                                    }
                                  />
                                }
                                label="Visitor's Pass"
                              />
                            </Box>
                          </FormGroup>
                        </FormControl>

                        <br />
                        <br />
                        <TextField
                          label="Custom Checkin delay"
                          variant="outlined"
                          type="number"
                          margin="normal"
                          name="customCheckInDelay"
                          onChange={e =>
                            setNewOrgSettings({
                              ...newOrgSettings,
                              customCheckInDelay: e.target.value
                            })
                          }
                          value={newOrgSettings.customCheckInDelay}
                        />
                        <br />
                      </>
                    )}
                    {user.role !== "businessAdmin" && (
                      <>
                        <Typography color="textPrimary" variant="h6">
                          Custom message on QR scan
                        </Typography>

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                newOrgSettings.customQrMessage !== null ||
                                customQrMessage
                              }
                              onChange={e => {
                                setCustomeQRMessage(e.target.checked);
                                if (!e.target.checked)
                                  setNewOrgSettings({
                                    ...newOrgSettings,
                                    customQrMessage: null
                                  });
                              }}
                              name="customQrMessage"
                            />
                          }
                          label="Enable"
                        />

                        {(newOrgSettings.customQrMessage !== null ||
                          customQrMessage) && (
                          <TextField
                            label="Message"
                            fullWidth
                            variant="outlined"
                            type="text"
                            margin="normal"
                            value={newOrgSettings.customQrMessage || ""}
                            name="customQrMessage"
                            onChange={e =>
                              setNewOrgSettings({
                                ...newOrgSettings,
                                customQrMessage: e.target.value
                              })
                            }
                          />
                        )}

                        <br />
                        <br />

                        <Typography color="textPrimary" variant="h6">
                          Custom image on QR scan
                        </Typography>

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                newOrgSettings.customQrImage !== null ||
                                customQrImage
                              }
                              onChange={e => {
                                setCustomQRImage(e.target.checked);
                                if (!e.target.checked)
                                  setNewOrgSettings({
                                    ...newOrgSettings,
                                    customQrImage: null
                                  });
                              }}
                              name="customQrImage"
                            />
                          }
                          label="Enable"
                        />
                        {(newOrgSettings.customQrImage !== null ||
                          customQrImage) && (
                          <Grid container direction="row" spacing={3}>
                            {newOrgSettings.customQrImage && (
                              <Grid item>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={e =>
                                    handleViewDialog(
                                      newOrgSettings.customQrImage,
                                      "image"
                                    )
                                  }
                                >
                                  View
                                </Button>
                              </Grid>
                            )}

                            <Grid item>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={e =>
                                  handleUploadDalog(
                                    "Custom image on QR scan",
                                    "customQrImage",
                                    "image"
                                  )
                                }
                              >
                                Upload
                              </Button>
                            </Grid>
                          </Grid>
                        )}

                        <br />
                        <br />
                        <Typography color="textPrimary" variant="h6">
                          Skip checkin for employee initiated meeting
                        </Typography>
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="body2"
                        >
                          Visitor with an employee initiated approved meeting
                          will be able to access all the relevant entry points
                          without checking in at the recepetion/entrance.
                        </Typography>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                newOrgSettings.skipCheckinForEmployeeMeeting
                              }
                              onChange={handleChange}
                              name="skipCheckinForEmployeeMeeting"
                            />
                          }
                          label="Enable"
                        />
                        <br />
                        <br />
                        <Typography color="textPrimary" variant="h6">
                          Skip checkin for visitor initiated meeting
                        </Typography>
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="body2"
                        >
                          Visitor with an approved meeting initiated by themself
                          will be able to access all the relevant entry points
                          without checking in at the recepetion/entrance.
                        </Typography>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                newOrgSettings.skipCheckinForVisitorMeeting
                              }
                              onChange={handleChange}
                              name="skipCheckinForVisitorMeeting"
                            />
                          }
                          label="Enable"
                        />
                        <br />
                        <br />
                        <Typography color="textPrimary" variant="h6">
                          Custom message on successfull Check In
                        </Typography>

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                newOrgSettings.customCheckInMessage !== null ||
                                customCheckInMessage
                              }
                              onChange={e => {
                                setCustomCheckInMessage(e.target.checked);
                                if (!e.target.checked)
                                  setNewOrgSettings({
                                    ...newOrgSettings,
                                    customCheckInMessage: null
                                  });
                              }}
                              name="customCheckInMessage"
                            />
                          }
                          label="Enable"
                        />

                        {(newOrgSettings.customCheckInMessage !== null ||
                          customCheckInMessage) && (
                          <TextField
                            label="Message"
                            fullWidth
                            variant="outlined"
                            type="text"
                            margin="normal"
                            value={newOrgSettings.customCheckInMessage || ""}
                            name="customCheckInMessage"
                            onChange={e =>
                              setNewOrgSettings({
                                ...newOrgSettings,
                                customCheckInMessage: e.target.value
                              })
                            }
                          />
                        )}

                        <br />
                        <br />
                        <Typography color="textPrimary" variant="h6">
                          Custom message on successfull Check Out
                        </Typography>

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                newOrgSettings.customCheckOutMessage !== null ||
                                customCheckOutMessage
                              }
                              onChange={e => {
                                setCustomCheckOutMessage(e.target.checked);
                                if (!e.target.checked)
                                  setNewOrgSettings({
                                    ...newOrgSettings,
                                    customCheckOutMessage: null
                                  });
                              }}
                              name="customCheckOutMessage"
                            />
                          }
                          label="Enable"
                        />

                        {(newOrgSettings.customCheckOutMessage !== null ||
                          customCheckOutMessage) && (
                          <TextField
                            label="Message"
                            fullWidth
                            variant="outlined"
                            type="text"
                            margin="normal"
                            value={newOrgSettings.customCheckOutMessage || ""}
                            name="customCheckOutMessage"
                            onChange={e =>
                              setNewOrgSettings({
                                ...newOrgSettings,
                                customCheckOutMessage: e.target.value
                              })
                            }
                          />
                        )}

                        <br />
                        <br />
                        <Typography color="textPrimary" variant="h6">
                         Custom instant meeting message
                        </Typography>

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        newOrgSettings.customInstantMeetingMessage !== null ||
                        customInstantMeetingMessage
                      }
                      onChange={e => {
                        setCustomInstantMeetingMessage(e.target.checked);
                        if (!e.target.checked)
                          setNewOrgSettings({
                            ...newOrgSettings,
                            customInstantMeetingMessage: null
                          });
                      }}
                      name="customInstantMeetingMessage"
                    />
                  }
                  label="Enable"
                />

                 {(newOrgSettings.customInstantMeetingMessage !== null ||
                  customInstantMeetingMessage) && (
                  <TextField
                    label="Message"
                    fullWidth
                    variant="outlined"
                    type="text"
                    margin="normal"
                    value={newOrgSettings.customInstantMeetingMessage || ""}
                    name="customInstantMeetingMessage"
                    onChange={e =>
                      setNewOrgSettings({
                        ...newOrgSettings,
                        customInstantMeetingMessage: e.target.value
                      })
                    }
                  />
                )}
                <br/>
                <br/>

                        <Typography color="textPrimary" variant="h6">
                          Custom image on on successfull Check In
                        </Typography>

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                newOrgSettings.customCheckInImage !== null ||
                                customCheckInImage
                              }
                              onChange={e => {
                                setCustomCheckInImage(e.target.checked);
                                if (!e.target.checked)
                                  setNewOrgSettings({
                                    ...newOrgSettings,
                                    customCheckInImage: null
                                  });
                              }}
                              name="customCheckInImage"
                            />
                          }
                          label="Enable"
                        />
                        {(newOrgSettings.customCheckInImage !== null ||
                          customCheckInImage) && (
                          <Grid container direction="row" spacing={3}>
                            {newOrgSettings.customCheckInImage && (
                              <Grid item>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={e =>
                                    handleViewDialog(
                                      newOrgSettings.customCheckInImage,
                                      "image"
                                    )
                                  }
                                >
                                  View
                                </Button>
                              </Grid>
                            )}

                            <Grid item>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={e =>
                                  handleUploadDalog(
                                    "Custom image on on successfull Check In",
                                    "customCheckInImage",
                                    "image"
                                  )
                                }
                              >
                                Upload
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                      </>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <br />
            <Card>
              <CardContent>
                <Box display="flex" justifyContent="flex-end">
                  <Button color="primary" variant="contained" type="submit">
                    Update
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </form>
          <DropzoneDialog
            open={dropzoneOpen}
            onSave={files => {
              handleUpload(files);
            }}
            acceptedFiles={[dropzone.type + "/*"]}
            showPreviews={true}
            filesLimit={1}
            maxFileSize={5000000000}
            dialogTitle={dropzone.title}
            onClose={() => setDropzoneOpen(false)}
            submitButtonText="Upload"
            cancelButtonText="Cancel"
          />

          <Dialog
            open={viewOpen}
            onClose={() => setViewOpen(false)}
            disableAutoFocus={true}
          >
            {viewType === "image" && (
              <img
                src={viewUrl}
                alt="preview"
                style={{ maxHeight: "90vh", maxWidth: "100%" }}
              />
            )}
            {viewType === "video" && (
              <video src={viewUrl} width="100%" height="100%" controls></video>
            )}
          </Dialog>
          <Dialog
            open={questionModalOpen}
            onClose={() => setQuestionModalOpen(false)}
          >
            <DialogContent>
              <Questions close={() => setQuestionModalOpen(false)} />
            </DialogContent>
          </Dialog>
        </Box>
      )}

      {valueTab === "two" && (
        <Box width="100%" className={clsx(classes.root, className)} {...rest}>
          <form onSubmit={handleSubmit}>
            <Grid container direction="row" spacing={3}>
            {user.role === "businessAdmin" && (
              <>
              <Grid item sm={12} md={12}>
                <Card>
                  <CardContent>
                    <Typography color="primary" gutterBottom variant="h4">
                      Check in
                    </Typography>
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="h6"
                        >
                          Meeting based check in
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            ml: 3
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={newOrgSettings.instantMeetingEnable}
                                // disabled={newOrgSettings.showHelp}
                                onChange={e => {
                                  let update = {
                                    instantMeetingEnable:
                                      e.target.checked
                                  };
                                  if (e.target.checked) {
                                    update = {
                                      ...update,
                                      showHelp: false
                                    };
                                  }
                                  setNewOrgSettings({
                                    ...newOrgSettings,
                                    ...update
                                  });
                                }}
                                name="instantMeetingEnable"
                              />
                            }
                            label="Meeting Based Checkin"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={newOrgSettings.showHelp}
                                onChange={e => {
                                  let update = {
                                    showHelp:
                                      e.target.checked
                                  };
                                  if (e.target.checked) {
                                    update = {
                                      ...update,
                                      instantMeetingEnable: false
                                    };
                                  }
                                  setNewOrgSettings({
                                    ...newOrgSettings,
                                    ...update
                                  });
                                }}
                                // disabled={newOrgSettings.instantMeetingEnable}
                                name="showHelp"
                              />
                            }
                            label="Meeting Based Checkin from Visitor Device"
                          />
                        </Box>
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="h6"
                        >
                          Request based check in
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            ml: 3
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={newOrgSettings.checkInSystemEnable}
                                onChange={handleChange}
                                name="checkInSystemEnable"
                              />
                            }
                            label="Enable"
                          />
                        </Box>
                        <br />

                        <br />
                        <br />
                  </CardContent>
                </Card>
              </Grid>
              </>
              )}


              {user.role !== "businessAdmin" && (
                 <>
              <Grid item sm={12} md={6}>
                <Card>
                  <CardContent>
                    <Typography color="primary" gutterBottom variant="h4">
                      Check In
                    </Typography>
                        <FormControl component="fieldset">
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h6"
                          >
                            Request approval
                          </Typography>
                          <FormGroup row>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    newOrgSettings.visitorCheckInApprovalType ===
                                    "admin"
                                  }
                                  onChange={e => {
                                    e.target.checked &&
                                      setNewOrgSettings({
                                        ...newOrgSettings,
                                        visitorCheckInApprovalType: "admin"
                                      });
                                  }}
                                />
                              }
                              label="Admin"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    newOrgSettings.visitorCheckInApprovalType ===
                                    "none"
                                  }
                                  onChange={e => {
                                    e.target.checked &&
                                      setNewOrgSettings({
                                        ...newOrgSettings,
                                        visitorCheckInApprovalType: "none"
                                      });
                                  }}
                                />
                              }
                              label="Auto approve"
                            />
                          </FormGroup>
                        </FormControl>
                        <br />
                        <br />

                  </CardContent>
                </Card>
              </Grid>
              </>
              )}
            </Grid>


            {user.role === "businessAdmin" && (
            <Grid container direction="row" spacing={1}>
              <Grid item sm={12} md={6}>
                <Card>
                  <CardContent>
                    <Typography color="textPrimary" gutterBottom variant="h5">
                      Meeting based check in
                    </Typography>
                  </CardContent>
                </Card>
                {(newOrgSettings.instantMeetingEnable ||
                  newOrgSettings.showHelp) && (
                  <>
                    <Card>
                      <CardContent>
                        <FormControl component="fieldset">
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h6"
                          >
                            Visitor Checkin Process
                          </Typography>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={newOrgSettings.captureCheckInPhoto}
                                onChange={handleChange}
                                name="captureCheckInPhoto"
                              />
                            }
                            label="Capture Visitor's Photo"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={newOrgSettings.captureCheckInIdPhoto}
                                name="captureCheckInIdPhoto"
                                onChange={handleChange}
                              />
                            }
                            label="Capture Visitor's Id Photo"
                          />
                        </FormControl>
                      </CardContent>
                    </Card>
                  </>
                )}
                {(newOrgSettings.instantMeetingEnable ||
                  newOrgSettings.showHelp) && (
                  <Card>
                    <CardContent>
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                    </CardContent>
                  </Card>
                )}
                {!(
                  newOrgSettings.instantMeetingEnable || newOrgSettings.showHelp
                ) && (
                  <Card>
                    <CardContent>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        minHeight="55vh"
                      >
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="h5"
                        >
                          No Setting to Display
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                )}
                {(newOrgSettings.instantMeetingEnable ||
                  newOrgSettings.showHelp) && (
                  <>
                  <Card>
                    <CardContent>
                    <Typography color="textPrimary" variant="h6">
                      Auto Checkin
                    </Typography>
                    <Typography
                      color="textPrimary"
                      gutterBottom
                      variant="body2"
                    >
                      Visitor will be automatically marked as checked in when
                      request is approved.
                    </Typography>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", ml: 3 }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={newOrgSettings.autoCheckin}
                            onChange={handleChange}
                            name="autoCheckin"
                          />
                        }
                        label="Enable"
                      />
                    </Box>
                    </CardContent>
                  </Card>
                    <Card>
                      <CardContent>
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="h6"
                        >
                          Enable Additional Participant
                        </Typography>
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="body2"
                        >
                          Enter additional count instead of individual details.
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            ml: 3
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  newOrgSettings.addParticipantCountEnable
                                }
                                onChange={handleChange}
                                name="addParticipantCountEnable"
                              />
                            }
                            label="Enable"
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </>
                )}
              </Grid>
              <Grid item sm={12} md={6}>
                <Card>
                  <CardContent>
                    <Typography color="textPrimary" gutterBottom variant="h5">
                      Request based check in
                    </Typography>
                  </CardContent>
                </Card>
                {newOrgSettings.checkInSystemEnable && (
                  <>
                    <Card>
                      <CardContent>
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="h6"
                        >
                          While Checkin Request
                        </Typography>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={newOrgSettings.checkInVisitProfilePhoto}
                              onChange={handleChange}
                              name="checkInVisitProfilePhoto"
                            />
                          }
                          label="Profile photo on checkin request"
                        />
                        <br />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={newOrgSettings.checkInVisitIdPhoto}
                              onChange={handleChange}
                              name="checkInVisitIdPhoto"
                            />
                          }
                          label="Visitor's id photo on checkin request"
                        />
                      </CardContent>
                    </Card>
                  </>
                )}

                {newOrgSettings.checkInSystemEnable && (
                  <>
                    <Card>
                      <CardContent>
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="h6"
                        >
                          Pre scheculed visit
                        </Typography>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                newOrgSettings.visitProfilePhotoWhileCheckIn
                              }
                              onChange={handleChange}
                              name="visitProfilePhotoWhileCheckIn"
                            />
                          }
                          label="Profile photo while checking in"
                        />
                        <br />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={newOrgSettings.visitIdPhotoWhileCheckIn}
                              onChange={handleChange}
                              name="visitIdPhotoWhileCheckIn"
                            />
                          }
                          label="Visitor's id photo while checking in"
                        />
                      </CardContent>
                    </Card>
                  </>
                )}
                {newOrgSettings.checkInSystemEnable && (
                  <Card>
                    <CardContent>
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                    </CardContent>
                  </Card>
                )}
                {newOrgSettings.checkInSystemEnable && (
                  <Card>
                    <CardContent>
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                    </CardContent>
                  </Card>
                )}
                {!newOrgSettings.checkInSystemEnable && (
                  <Card>
                    <CardContent>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        minHeight="55vh"
                      >
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="h5"
                        >
                          No Setting to Display
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                )}
              </Grid>
            </Grid>
            )}
            <br />
            <Card>
              <CardContent>
                <Box display="flex" justifyContent="flex-end">
                  <Button color="primary" variant="contained" type="submit">
                    Update
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </form>
        </Box>
      )}
      {valueTab === "three" && <Checkout />}
    </>
  );
};

Checkin.propTypes = {
  className: PropTypes.string
};

export default Checkin;
