import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { captureException } from "@sentry/browser";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { organizationSettings } from "../../../actions";
import api from "../../../utils/api";
import Loader from "../../../utils/loader";

const useStyles = makeStyles({
  root: {},
  item: {
    display: "flex",
    flexDirection: "column"
  }
});

const Meeting = ({ className, ...rest }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [newOrgSettings, setNewOrgSettings] = useState({});
  // const [
  //   customInstantMeetingMessage,
  //   setCustomInstantMeetingMessage
  // ] = useState(false);
  const [defaultMeetingRoom, setDefaultMeetingRoom] = useState(false);
  // const [roomList, setRoomList] = useState([]);
  const user = useSelector(state => state.user);
  const orgSettings = useSelector(state => state.orgSettings);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(organizationSettings({ ...orgSettings, ...newOrgSettings }));
  }, [loading]);

  useEffect(() => {
    (async () => {
      try {
        const newdata = await api(
          "POST",
          "organization/getOrgSettingsFilteredByKeys/" + user.organizationId,
          {
            keys: [
              "visitorSignIn",
              "visitorApprovalType",
              "employeeApprovalType",
              "searchInstantMeetingUser",
              "searchInstantMeetingUserFields",
              "customInstantMeetingMessage",
              "defaultMeetingRoom",
              "editMeetingUser",
              "customResponseMessage"
            ]
          },
          true
        );
        setNewOrgSettings(newdata);
        // const availablerooms = await api(
        //   "POST",
        //   "room/find",
        //   {
        //     search: [],
        //     sort: { key: "room.createdAt", order: "ASC" }
        //   },
        //   true
        // );
        // setRoomList(availablerooms);
        setLoading(false);
      } catch (error) {
        captureException(error);
      }
    })();
  }, []);

  const handleSubmit = async e => {
    setLoading(true);
    e.preventDefault();
    try {
      await api(
        "PATCH",
        "organization/settings/" + user.organizationId,
        newOrgSettings,
        true
      );
      const newdata = await api(
        "POST",
        "organization/getOrgSettingsFilteredByKeys/" + user.organizationId,
        {
          keys: [
            "visitorSignIn",
            "visitorApprovalType",
            "employeeApprovalType",
            "searchInstantMeetingUser",
            "searchInstantMeetingUserFields",
            "customInstantMeetingMessage",
            "defaultMeetingRoom",
            "editMeetingUser",
            "customResponseMessage"
          ]
        },
        true
      );
      setNewOrgSettings(newdata);
    } catch (error) {
      captureException(error);
    }
    setLoading(false);
  };

  const handleChange = e => {
    setNewOrgSettings({ ...newOrgSettings, [e.target.name]: e.target.checked });
  };


  return loading ? (
    <Loader />
  ) : (
    <Box width="100%" className={clsx(classes.root, className)} {...rest}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={6} wrap="wrap">
          <Grid className={classes.item} item md={6} sm={12}>
            <Card>
              <CardContent>
                <Typography color="textPrimary" gutterBottom variant="h4">
                  Employee Flow
                </Typography>
                <FormControl component="fieldset">
                  <Typography color="textPrimary" gutterBottom variant="h6">
                    Request approval
                  </Typography>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            newOrgSettings.employeeApprovalType === "none"
                          }
                          onChange={e => {
                            e.target.checked &&
                              setNewOrgSettings({
                                ...newOrgSettings,
                                employeeApprovalType: "none"
                              });
                          }}
                          name="Auto"
                        />
                      }
                      label="Auto"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            newOrgSettings.employeeApprovalType === "invitee"
                          }
                          onChange={e => {
                            e.target.checked &&
                              setNewOrgSettings({
                                ...newOrgSettings,
                                employeeApprovalType: "invitee"
                              });
                          }}
                          name="Invitee"
                        />
                      }
                      label="Invitee"
                    />
                  </FormGroup>
                </FormControl>
              </CardContent>
            </Card>
            <br />
            <Card>
              <CardContent>
                <Typography color="textPrimary" gutterBottom variant="h4">
                  Visitor Flow
                </Typography>
                <Typography color="textPrimary" variant="h6">
                  Visitor dashboard access
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={newOrgSettings.visitorSignIn}
                      onChange={handleChange}
                      name="visitorSignIn"
                    />
                  }
                  label="Allow"
                />
                <br />
                <br />
                <FormControl component="fieldset">
                  <Typography color="textPrimary" gutterBottom variant="h6">
                    Request approval
                  </Typography>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            newOrgSettings.visitorApprovalType === "none"
                          }
                          onChange={e => {
                            e.target.checked &&
                              setNewOrgSettings({
                                ...newOrgSettings,
                                visitorApprovalType: "none"
                              });
                          }}
                          name="Auto"
                        />
                      }
                      label="Auto"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            newOrgSettings.visitorApprovalType === "host"
                          }
                          onChange={e => {
                            e.target.checked &&
                              setNewOrgSettings({
                                ...newOrgSettings,
                                visitorApprovalType: "host"
                              });
                          }}
                          name="Host"
                        />
                      }
                      label="Host"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            newOrgSettings.visitorApprovalType === "admin"
                          }
                          onChange={e => {
                            e.target.checked &&
                              setNewOrgSettings({
                                ...newOrgSettings,
                                visitorApprovalType: "admin"
                              });
                          }}
                          name="Admin"
                        />
                      }
                      label="Admin"
                    />
                  </FormGroup>
                </FormControl>
                <br />
                {newOrgSettings.visitorApprovalType !== "none" && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={newOrgSettings.customResponseMessage}
                        onChange={handleChange}
                        name="customResponseMessage"
                      />
                    }
                    label="Allow custom message with approval"
                  />
                )}
                <br />
                <br />
              </CardContent>
            </Card>
            <br />
          </Grid>
          {/* <Grid className={classes.item} item md={6} sm={12}>
            <Card>
              <CardContent>
                <Typography color="textPrimary" gutterBottom variant="h4">
                  Meeting Settings
                </Typography>
                <Typography color="textPrimary" gutterBottom variant="h6">
                  Users allowed to edit a meeting
                </Typography>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={JSON.parse(
                          newOrgSettings.editMeetingUser
                        ).includes("organizer")}
                        onChange={e => {
                          e.target.checked &&
                            setNewOrgSettings({
                              ...newOrgSettings,
                              editMeetingUser: JSON.stringify([
                                ...JSON.parse(newOrgSettings.editMeetingUser),
                                "organizer"
                              ])
                            });
                          !e.target.checked &&
                            setNewOrgSettings({
                              ...newOrgSettings,
                              editMeetingUser: JSON.stringify(
                                JSON.parse(
                                  newOrgSettings.editMeetingUser
                                ).filter(item => item !== "organizer")
                              )
                            });
                        }}
                      />
                    }
                    label="Organizer"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={JSON.parse(
                          newOrgSettings.editMeetingUser
                        ).includes("employee")}
                        onChange={e => {
                          e.target.checked &&
                            setNewOrgSettings({
                              ...newOrgSettings,
                              editMeetingUser: JSON.stringify([
                                ...JSON.parse(newOrgSettings.editMeetingUser),
                                "employee"
                              ])
                            });
                          !e.target.checked &&
                            setNewOrgSettings({
                              ...newOrgSettings,
                              editMeetingUser: JSON.stringify(
                                JSON.parse(
                                  newOrgSettings.editMeetingUser
                                ).filter(item => item !== "employee")
                              )
                            });
                        }}
                      />
                    }
                    label="Employee participants"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={JSON.parse(
                          newOrgSettings.editMeetingUser
                        ).includes("visitor")}
                        onChange={e => {
                          e.target.checked &&
                            setNewOrgSettings({
                              ...newOrgSettings,
                              editMeetingUser: JSON.stringify([
                                ...JSON.parse(newOrgSettings.editMeetingUser),
                                "visitor"
                              ])
                            });
                          !e.target.checked &&
                            setNewOrgSettings({
                              ...newOrgSettings,
                              editMeetingUser: JSON.stringify(
                                JSON.parse(
                                  newOrgSettings.editMeetingUser
                                ).filter(item => item !== "visitor")
                              )
                            });
                        }}
                      />
                    }
                    label="Visitor participants"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={JSON.parse(
                          newOrgSettings.editMeetingUser
                        ).includes("admin")}
                        onChange={e => {
                          e.target.checked &&
                            setNewOrgSettings({
                              ...newOrgSettings,
                              editMeetingUser: JSON.stringify([
                                ...JSON.parse(newOrgSettings.editMeetingUser),
                                "admin"
                              ])
                            });
                          !e.target.checked &&
                            setNewOrgSettings({
                              ...newOrgSettings,
                              editMeetingUser: JSON.stringify(
                                JSON.parse(
                                  newOrgSettings.editMeetingUser
                                ).filter(item => item !== "admin")
                              )
                            });
                        }}
                      />
                    }
                    label="Admin"
                  />
                </FormGroup>
              </CardContent>
            </Card>
          </Grid>*/}
        </Grid> 

        <br />
        <Card>
          <CardContent>
            <Box display="flex" justifyContent="flex-end">
              <Button color="primary" variant="contained" type="submit">
                Update
              </Button>
            </Box>
          </CardContent>
        </Card>
      </form>
    </Box>
  );
};

Meeting.propTypes = {
  className: PropTypes.string
};

export default Meeting;
