import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  TextField,
  Grid,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import CloseIcon from "@material-ui/icons/Close";
import { toastAction } from "../../../actions";
import api from "src/utils/api";
import { PlusCircle } from "react-feather";

function RoomControlDetailsDialog(props) {
  const { close, dataList, open,fetchRoomControlData } = props; //datalist name
  const [actionList, setActionList] = useState([]);
  const [error, setError] = useState("");
  const [controlName, setControlName] = useState("");
  const [controlType, setControlType] = useState("");
  const [statusUrl, setStatusUrl] = useState("");
  const [newAction, setNewAction] = useState({ name: "", url: "" });

  const handleClose = () => {
    setActionList([]);
    // setControlType("");
    // setControlName("");
    // setStatusUrl("");
    setNewAction({ name: "", url: "" });
    close();
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (dataList.id) {
      setControlName(dataList.controlName);
      setControlType(dataList.controlType);
      setStatusUrl(dataList.value.statusUrl);
      setActionList(dataList.value.actions);
    }
  }, [dataList.id]);
  const handleRemoveAction = i => {
    setActionList(actionList.filter((item, index) => index !== i));
  };

  //call update APi
  const handleUpdate = () => {
    setError("");
    let body = {
      roomId: dataList.roomId,
      value: { statusUrl: statusUrl, actions: actionList },
      controlName: controlName,
      controlType: controlType
    };
    dispatch(
      toastAction({
        status: true,
        message: "Updating " + "...",
        type: "info",
        noIcon: true
      })
    );
    (async () => {
      try {
        await api("PATCH", "room/controls/" + dataList.id, body, true);
        dispatch(
          toastAction({
            status: true,
            message: "Successfully updated ",
            type: "success"
          })
        );
        handleClose();
      } catch (error) {
        dispatch(
          toastAction({
            status: true,
            message: "Something went wrong",
            type: "error"
          })
        );
        error.response && error.response.data && setError(error.response.data);
        setError("Please fill all the fields");
      }
    })();
  };
  //call Add APi
  const handleAdd = () => {
    setError("");
    let body = {
      roomId: dataList.roomId,
      key: "control",
      value: { statusUrl: statusUrl, actions: actionList },
      controlName: controlName,
      controlType: controlType,
      relativeTimeMin: 2,
      relativeTimeSec: 2
    };

    dispatch(
      toastAction({
        status: true,
        message: "Adding " + "...",
        type: "info",
        noIcon: true
      })
    );
    (async () => {
      try {
        await api("POST", "room/controls", body, true);
        dispatch(
          toastAction({
            status: true,
            message: "Successfully Added ",
            type: "success"
          })
        );
        handleClose();
        fetchRoomControlData();
      } catch (error) {
        dispatch(
          toastAction({
            status: true,
            message: error.response.data.message,
            type: "error"
          })
        );
        error.response && error.response.data && setError(error.response.data);
      }
    })();
  };
  //onChange of new name and url update state for newAction
  //onclick of + button add new Action to actions array,clear new action
  // call Delete Api
  const handleDelete = () => {
    setError("");
    dispatch(
      toastAction({
        status: true,
        message: "Deleting Access",
        type: "info",
        noIcon: true
      })
    );
    (async () => {
      try {
        await api("DELETE", "room/controls/" + dataList.id, null, true);
        dispatch(
          toastAction({
            status: true,
            message: "Successfully Deleted ",
            type: "success"
          })
        );

        handleClose();
      } catch (error) {
        dispatch(
          toastAction({
            status: true,
            message: "Something went wrong",
            type: "error"
          })
        );
        error.response && error.response.data && setError(error.response.data);
      }
    })();
  };
  const type = ["light", "sheer", "blinds"];

  useEffect(() => {
    console.log({ actionList });
  }, [actionList]);

  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="sm">
      <DialogTitle disableTypography>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Typography component="h1" variant="h3" color="primary">
            {dataList.id ? "Edit" : "Add"} Control
          </Typography>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <TextField
          id="name"
          label="Control Name"
          value={controlName}
          onChange={e => setControlName(e.target.value)}
          variant="outlined"
          type="text"
          fullWidth
          name="name"
        />
        <br />
        <br />
        <FormControl
          variant="outlined"
          style={{ width: "100%" }}
          id="Controltype"
        >
          <InputLabel id="controlType"> Control Type</InputLabel>
          <Select
            labelId="controlType"
            id="controlType"
            label="Control Type"
            value={controlType}
            onChange={e => setControlType(e.target.value)}
            autoWidth
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {type.map(controltype => {
              return (
                <MenuItem value={controltype} key={controltype}>
                  {controltype}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <br />
        <br />
        <TextField
          id="name"
          label="Status URL"
          value={statusUrl}
          onChange={e => {
            setStatusUrl(e.target.value);
          }}
          variant="outlined"
          type="text"
          fullWidth
          name="name"
        />
        <br />
        <br />
        <Typography component="h1" variant="h4" color="primary">
          Actions
        </Typography>
        <br />
        <Grid container direction="row" alignItems="center" spacing={1}>
          {actionList &&
            actionList.map((item, index) => {
              return (
                <>
                  <Grid item xs={3}>
                    <TextField
                      id="name"
                      label="Name"
                      variant="outlined"
                      type="text"
                      value={item.name}
                      fullWidth
                      name="name"
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      id="name"
                      label="URL"
                      variant="outlined"
                      value={item.url}
                      type="text"
                      fullWidth
                      name="name"
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      aria-label="close"
                      onClick={() => {
                        handleRemoveAction(index);
                      }}
                    >
                      <DeleteOutlinedIcon />
                    </IconButton>
                  </Grid>
                </>
              );
            })}
          <>
            <Grid item xs={3}>
              <TextField
                id="name"
                label="Name"
                variant="outlined"
                type="text"
                value={newAction.name}
                onChange={e =>
                  setNewAction({ ...newAction, name: e.target.value })
                }
                fullWidth
                name="name"
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="name"
                label="URL"
                variant="outlined"
                type="text"
                value={newAction.url}
                onChange={e =>
                  setNewAction({ ...newAction, url: e.target.value })
                }
                fullWidth
                name="name"
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton
                aria-label="close"
                onClick={() => {
                  setActionList([newAction, ...actionList]);
                  setNewAction({ name: "", url: "" });
                }}
              >
                <PlusCircle />
              </IconButton>
            </Grid>
          </>
        </Grid>
        <br />
        <br />
        <Grid container direction="row-reverse" alignItems="center" spacing={1}>
          <Grid item>
            <Button
              onClick={dataList?.id ? handleUpdate : handleAdd}
              variant="contained"
              color="primary"
            >
              {dataList?.id ? "Update" : "Add"}
            </Button>
          </Grid>
          {dataList?.id && (
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleDelete}
              >
                Delete
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button onClick={handleClose} variant="outlined">
              Close
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default RoomControlDetailsDialog;
