import React from "react";
import "./legal.css";

const TermsOfUse = () => {
  return (
    <div className="legal">
      <div>
        <h3 style={{ textAlign: "center" }}>Terms of usage</h3>
        <p style={{ textAlign: "center" }}>Last updated on March 12, 2020</p>
      </div>
      <p>
        Hipla Technologies Pte Ltd and its affiliated companies ("Hipla" or
        "we", "us", or "our") are pleased to give you access to the Hipla
        application along with related or linked websites, applications,
        services, interfaces, tools, software, and other functionality provided
        on or through the hipla application (collectively the " Hipla app")
        subject to your agreement to comply with these Terms and Conditions.
        Please read these Terms and Conditions carefully before using the Hipla
        app. By accessing or using the Hipla app on any computer, mobile phone,
        tablet, console or other device (together "Devices" and each a
        "Device"), you confirm that you have read, understood and agreed to be
        bound by these Terms and Conditions and any other applicable law.
      </p>
      <p>
        If you do not agree to these Terms and Conditions, please do not access
        or use the Hipla app or any functionality thereof.
      </p>
      <p>
        Note 1: The Hipla app may only be used by a person who is 18 years old
        or over. If you are under 18 years old please do not use this app or
        send us any personal data (e.g.: your name, age, address or email
        address).
      </p>
      <p>&nbsp;</p>
      <h3 class="font-weight-bold">Hipla may change this Agreement</h3>
      <p>
        Hipla reserves the right, at its sole discretion, to change, modify,
        add, or delete portions of this Agreement at any time and without any
        further notice to you. If we do this, we will post the updated version
        of this Agreement in the Hipla app. It is your responsibility to
        regularly check for changes to this Agreement and to review such
        changes. Any new features or updated content or applications that we
        make available as a part of the Hipla app will be subject to this
        Agreement.
      </p>
      <p>
        Your use of the Hipla app after any such changes constitutes your
        acceptance of the new Agreement. In some cases you may be asked to agree
        to revised terms when accessing the Hipla app and if you do not agree to
        the revised terms you will not be allowed to use the Hipla app.
      </p>
      <p>&nbsp;</p>
      <h3 class="font-weight-bold">Agreement effective until terminated</h3>
      <p>
        You may terminate this Agreement at any time by uninstalling the Hipla
        app. Your rights under this Agreement will terminate automatically
        without notice from Hipla, if you fail to comply with any of the terms,
        conditions, or guidelines of this Agreement. Upon the termination of
        this Agreement, you shall cease all use of the Hipla app.
      </p>
      <p>
        You understand and agree that some of your User Content may continue to
        appear on or through the Hipla app or may persist in backup copies for a
        reasonable period of time even after your account and/or access to the
        Hipla app is terminated.
      </p>
      <p>
        Hipla will close or deactivate inactive customer accounts and close
        accounts that are used in violation of these Terms and Conditions or the
        Privacy Statement.
      </p>
      <p>&nbsp;</p>
      <h3 class="font-weight-bold">Changes to the Hipla app</h3>
      <p>
        The Hipla app is currently made available to you free of charge only for
        personal, non-commercial, non-exclusive use. Hipla reserves the right,
        in its sole discretion, without notice to you, at any time and from time
        to time, temporarily or permanently to: (a) modify, suspend, terminate,
        withdraw, or discontinue all or any part of the Hipla app without
        compensation to you, including, but not limited to (i) restricting the
        time all or any part of the Hipla app is available, (ii) restricting the
        amount of permitted use of all or any part of the Hipla app, and (iii)
        restricting or terminating any user's right to use all or any part of
        the Hipla app; or (b) offer opportunities to some or all users of all or
        any part of the Hipla app. You agree that neither Hipla nor any of our
        affiliates shall be liable to you or to any third party for any
        modification, suspension, or discontinuance of all or any part of the
        Hipla app, in whole or in part, or of any service, content, or feature
        offered through the Hipla app.
      </p>
      <p>&nbsp;</p>
      <h3 class="font-weight-bold">Ownership of the Hipla app</h3>
      <p>
        The Hiplai app (including, without limitation, any material, software,
        code, files, content, and images contained in or generated by the Hipla
        app, accompanying data, and other embedded software, including
        third-party software, Hipla app updates and upgrades, (collectively
        "Hipla Content")), — whether in read-only memory, on any other media, or
        in any other form — are owned by Hipla or its licensors and are
        protected by applicable law. No title or any intellectual property
        rights are transferred to you.
      </p>
      <p>
        Hipla Content is protected by copyright. No Hipla Content may be copied,
        reproduced, republished, uploaded, broadcast, posted, transmitted, or
        distributed except as expressly permitted by this Agreement. Use of
        Hipla Content in any way or for any purpose other than as permitted in
        this Agreement is a violation of Hipla's or an affiliate's copyrights or
        other.
      </p>
      <p>
        proprietary rights. Permission in writing for all other uses of Hipla
        Content must be obtained from Hipla in advance. All content, graphics,
        and other intellectual property rights, in each case, whether registered
        or unregistered, other than the marks of third parties, and related
        goodwill are the property of Hipla.
      </p>
      <p>
        You understand that you have no rights to the Hipla app, Hipla Content,
        Hipla Marks, or any other Hipla property except as indicated in this
        Agreement. Hipla reserves all other rights with respect to its
        intellectual property.
      </p>
      <p>&nbsp;</p>
      <h3 class="font-weight-bold">Limited right to use the Hipla app</h3>
      <p>
        Solely to enable your use of the Hipla app for non-profit making,
        non-exclusive purposes, subject to third-party terms (as described
        below) Hipla hereby grants you a limited, revocable, non-sub-licensable
        licence to use the Hipla app as provided in this Agreement. This licence
        entitles you to install the Hipla app on your Device and to use the
        Hipla app subject to this Agreement. Further, this Agreement does not
        grant you any rights to use Hipla's proprietary interfaces and other
        intellectual property in the design, development, manufacture,
        licensing, or distribution of third-party devices and accessories for
        use with the Hipla app. Any use of the Hipla app in any manner not
        allowed under this Agreement, including, without limitation, resale,
        transfer, modification, or distribution of the Hipla app or copying or
        distribution of text, pictures, music, video, data, hyperlinks,
        displays, and other content provided by the Hipla app is prohibited.
      </p>
      <p>&nbsp;</p>
      <h3 class="font-weight-bold">
        Hipla may collect information about you when you use the Hipla app
      </h3>
      <p>
        While using the Hipla app, Hipla may record information including, but
        not limited to, your geographic location, Device address, type of
        Device, your Device's unique ID, the IP address session start and stop
        times, network status and information about the way you use the Hipla
        app ("Device Information"). Your use of the Hipla app constitutes your
        consent to allow Hipla to process and record your Device Information.
      </p>
      <ul>
        <li>To comply with a legal obligation</li>
        <li>To protect and defend the rights or property of Hipla</li>
        <li>
          To prevent or investigate possible wrongdoing in connection with the
          Service
        </li>
        <li>
          To protect the personal safety of users of the Service or the public
        </li>
        <li>To protect against legal liability</li>
      </ul>
      <p>&nbsp;</p>
      <h3 class="font-weight-bold">Hipla app rules</h3>
      <ol>
        <li>
          User accounts:
          <p>
            In order to use certain features of the Hipla app, you must register
            by providing Hipla with certain User Content, \ as requested by
            Hipla, including your name, email address, and a password. Hipla
            reserves the right to refuse registration or cancel a user name or
            an account, in its sole discretion. You agree as follows:
          </p>
          <ol>
            <li>
              <p>I am aged 18 years or over.</p>
            </li>
            <li>
              <p>I will create only one account for my own use.</p>
            </li>
            <li>
              <p>
                I will provide accurate, truthful, current, and complete
                information when creating my account.
              </p>
            </li>
            <li>
              <p>
                I will not create an account for anyone other than myself
                without permission.
              </p>
            </li>
            <li>
              <p>
                I will maintain and promptly update my account information when
                any of my account information changes.
              </p>
            </li>
            <li>
              <p>
                I will maintain the security of my account by not sharing my
                account information with any other person and by using the
                available mobile device security features.
              </p>
            </li>
            <li>
              <p>I will protect the confidentiality of my password.</p>
            </li>
            <li>
              <p>
                I will not share my password or let anyone else access my
                account.
              </p>
            </li>
            <li>
              <p>I will not use another person's account without permission.</p>
            </li>
            <li>
              <p>
                I will not solicit account information from other users or
                access another user's account.
              </p>
            </li>
            <li>
              <p>
                I will not sell, or transfer my account to any other person, and
                any such purported transfer shall be null and void.
              </p>
            </li>
            <li>
              <p>
                I will take responsibility for all activities that occur on my
                account and for all User Content (defined below) (including, for
                example, photos, text, information, ratings, reviews, and links)
                posted using my account.
              </p>
            </li>
            <li>
              <p>
                I will immediately notify Hipla of any unauthorized use of my
                account of which I become aware.
              </p>
            </li>
            <li>
              <p>
                I understand that although Hipla will not be liable for any
                losses causes by any unauthorized use of my account, I may be
                liable for the losses of Hipla or others due to the unauthorized
                access and use of my account.
              </p>
            </li>
            <li>
              <p>
                I understand that Hipla may disable, suspend, or terminate my
                use of the Hipla app at any time in its sole discretion and
                without any notice to me.
              </p>
            </li>
            <li>
              <p>
                I understand that any violation of this Agreement may result in
                the termination of my use of the Hipla app.
              </p>
            </li>
          </ol>
        </li>
        <li>
          Safe use of the Hipla app
          <p>You agree as follows:</p>
          <ol>
            <li>
              <p>
                I agree not to post or submit any content or link to a website
                which is illegal, fraudulent, deceptive, misleading, libellous,
                infringing, harassing, hateful, threatening, abusive,
                pornographic, and offensive in a sexual, racial, cultural or
                ethnic consent or is otherwise objectionable.
              </p>
            </li>
            <li>
              <p>
                I will not harass, bully, abuse, threaten, or intimidate any
                other Hipla app users.
              </p>
            </li>
            <li>
              <p>
                I will not use the Hipla app to harm minors in any way or to
                encourage interactions with minors of a sexual nature.
              </p>
            </li>
            <li>
              <p>
                I will not use the Hipla app to send unauthorized or unwanted
                communications to any other person (i.e. I will not "spam" other
                people).
              </p>
            </li>
            <li>
              <p>
                I will not participate in any action that, in Hipla’s sole
                opinion, results or may result in any authorized user of the
                Hipla app being scammed or defrauded in any way in connection
                with such user's use of the Hipla app.
              </p>
            </li>
            <li>
              <p>
                I will not use the Hipla app to transmit viruses, worms, bots,
                Trojans, or other malicious code.
              </p>
            </li>
            <li>
              <p>
                I will not do anything that could overburden, disable, or impair
                the appearance, functionality, or proper working of the Hipla
                app or any software, hardware, equipment, or materials used in
                connection with the Hipla app.
              </p>
            </li>
            <li>
              <p>
                I will not use or exploit any errors in design, features that
                have not been documented, or "bugs" to gain access in any way
                that is not generally known and intentionally made available by
                Hipla, and I will promptly report to Hipla my discovery of any
                such errors, features, or bugs.
              </p>
            </li>
            <li>
              <p>
                I will not hack or spoof the Hipla app, or modify any other
                software or website to imply falsely that the other software or
                website is associated with Hipla or the Hipla app.
              </p>
            </li>
            <li>
              <p>
                I will not collect user account information or User Content (as
                defined below), or otherwise access the Hipla app through an
                automated means (i.e., through bots, robots, spiders, scrapers,
                etc.).
              </p>
            </li>
            <li>
              <p>
                I will not collect or store personal information about any other
                individual using the Hipla app, or otherwise stalk, repeatedly
                contact, or harass other Hipla app users.
              </p>
            </li>
            <li>
              <p>
                I will not impersonate any other person or misrepresent a
                relationship with any person or entity, including
                misrepresenting a relationship with Hipla.
              </p>
            </li>
            <li>
              <p>
                I will not manipulate the Hipla app so as to hide my identity or
                my use of the Hipla app.
              </p>
            </li>
            <li>
              <p>
                I will not violate any laws in my use of the Hipla app, and I
                will not use the Hipla app to do anything unlawful, misleading,
                discriminatory, or malicious.
              </p>
            </li>
            <li>
              <p>
                I will not encourage or facilitate any violations of this
                Agreement.
              </p>
            </li>
            <p>
              We reserve the right in our sole discretion to refuse, delete,
              suspend, or terminate—without notice—your access to or use of the
              Hipla app upon a violation or attempted violation of this
              Agreement, including, without limitation, any of the rules listed
              above. Indirect or attempted violations of this Agreement, and
              actual or attempted violations by a third party acting on your
              behalf, shall be deemed violations by you of this Agreement.
              Furthermore, it is a violation of this Agreement to use the
              services of another company for the purpose of facilitating any of
              the activities that violate this Agreement if such use of another
              company's service could reasonably be expected to adversely affect
              the Hipla app in any manner.
            </p>
          </ol>
        </li>
        <li>
          Posting user content
          <p>
            The Hipla app may allow you to enter, post, submit, or link to
            certain content (including, for example, text, data, software,
            photographs, images, graphics, files, videos, audio, sound, music,
            messages, materials, works of authorship, etc.) and provide certain
            comments, ratings, and reviews for others to see (collectively "User
            Content"). Hipla does not claim any ownership rights in any User
            Content that you enter, post, submit, provide, or link to on or
            through the Hipla app. By entering, posting, submitting, providing,
            or linking to User Content on or through the Hipla app, however, you
            grant Hipla and Hipla hereby accepts a worldwide, perpetual,
            irrevocable, non-exclusive, transferrable, sub-licensable,
            fully-paid up, and royalty free license to use, sell, reproduce,
            create derivative works from, combine with other works, alter,
            modify, delete from, add to, translate, distribute copies, display,
            perform, and publish the User Content and your name in connection
            with such use of your User Content in any and all media and in any
            manner, in whole or in part, without any restriction or
            responsibilities to you. In addition, by entering, posting,
            submitting, providing, or linking to User Content, you hereby
            release Hipla from any claims that such use, as authorized above,
            violates any rights which you may have under applicable law, and you
            understand that you will not be entitled to any compensation for any
            use of your User Content. Further, by entering, posting, submitting,
            providing, or linking to User Content, you hereby represent,
            warrant, and agree as follows:
          </p>
          <ol>
            <li>
              <p>
                I own and control all of the rights to the User Content or
                otherwise have (a) the legal rights to enter, post, submit,
                provide, or link to the User Content using the Hipla app and (b)
                the right to grant Hipla the license specified in this
                Agreement.
              </p>
            </li>
            <li>
              <p>
                The User Content that I enter, post, submit, provide, or link to
                does not, and will not, result in a breach of contract between
                me and any other person.
              </p>
            </li>
            <li>
              <p>
                I will pay all royalties, fees, and any other monies owing to
                any person due to any of the User Content that I enter, post,
                submit, provide, or link to using the Hipla app.
              </p>
            </li>
            <li>
              <p>
                None of the User Content is subject to confidentiality
                obligations.
              </p>
            </li>
            <li>
              <p>
                The User Content that I enter, post, submit, provide, or link to
                does not violate the privacy rights, publicity rights,
                copyrights, contract rights, intellectual property rights, or
                any other rights of any person.
              </p>
            </li>
            <li>
              <p>
                The User Content is truthful, accurate, not misleading, and
                offered in good faith.
              </p>
            </li>
            <li>
              <p>
                I am solely responsible for all of the User Content that I
                enter, post, submit, provide, link to, or otherwise make
                available in any way by or through the Hipla app.
              </p>
            </li>
            <li>
              <p>
                I will not post another person's sensitive identification
                documents, financial information, or other personal data without
                that person's permission.
              </p>
            </li>
            <li>
              <p>
                I will not enter, post, submit, provide, or link to User Content
                that is obscene or may be hateful or offensive on racial,
                ethnic, sexual, or any other grounds, is harmful, vulgar, or
                distasteful, or is defamatory, libellous, or invades another
                person's privacy.
              </p>
            </li>
            <li>
              <p>
                I will not use Hipla 's intellectual property without Hipla's
                permission in writing.
              </p>
            </li>
            <li>
              <p>
                I understand that Hipla can, in its sole discretion, monitor
                User Content that I and other users enter, post, submit,
                provide, or link to using the Hipla app.
              </p>
            </li>
            <li>
              <p>
                I understand that Hipla can, without notice and in its sole
                discretion, remove any User Content that we believe is obscene,
                offensive, libellous, defamatory, or violates the law or any of
                the terms and conditions of this Agreement.
              </p>
            </li>
            <li>
              <p>
                I understand that Hipla reserves the right to force forfeiture
                the username of any account that becomes inactive or any
                username that violates the trademark or other intellectual
                property rights of any other person.
              </p>
            </li>
            <li>
              <p>
                I understand that Hipla can suspend or terminate my use of the
                Hipla app if I fail to follow the rules in this Agreement.
              </p>
            </li>
          </ol>
        </li>
        <li>
          Mobile device data services
          <p>
            The Hipla app requires use of the internet through a mobile signal
            or wireless internet connection to operate correctly, thus the
            availability of the Hipla app may be affected by factors beyond
            Hipla's control. Hipla does not accept responsibility for
            unavailability of the Hipla app. Further, the terms of your
            agreement with your respective mobile network provider apply when
            using the Hipla app. You may be charged by your mobile provider for
            access to network connection services for the duration of the
            connection while accessing the Hipla app. All such charges are your
            responsibility.
          </p>
        </li>
      </ol>
      <p>&nbsp;</p>
      <h3 class="font-weight-bold">External websites and applications</h3>
      <p>
        The Hipla app may include link(s) to external websites or applications.
        When you follow such link(s) the external website or application may
        appear as a full screen (in which case you may need to use the Device
        button to return to the Hipla app) or in some cases it may appear within
        the frame of the Hipla app (in which case you should be able to return
        to the Hipla app by using the navigation buttons within the Hipla app).
        These links are provided in order to help you find relevant websites,
        applications, services and/or products which may be of interest to you.
        It is your responsibility to decide whether any services and/or products
        available through any of these websites or applications are suitable for
        your purposes. Hipla is not responsible for the content of such third
        party applications or websites, the owners or operators of these
        applications or websites or for any goods or services they supply and
        does not give or enter into any conditions, warranties or other terms or
        representations in relation to any of these or accept any liability in
        relation to any of these (including any liability arising out of any
        claim that the content of any external website or application to which
        the Hipla app includes a link infringes the intellectual property rights
        of any third party). By visiting or using these third party applications
        you agree to the respective terms thereof including but not limited, to
        Google Maps terms of service and the Google privacy policy.
      </p>
      <p>&nbsp;</p>
      <h4 class="font-weight-bold">Miscellaneous</h4>
      <ol type="a">
        <li>
          <p>Special offers, news and information from Hipla</p>
          <p>
            From time to time, we may send you via app notifications information
            on special offers and news relating to Hipla generally, unless you
            opt-out of receiving such communication when you register to use the
            Hipla app or through the account settings within the Hipla app.
          </p>
        </li>
        <li>
          <p>Liability and warranty disclaimer</p>
          <p>
            You expressly acknowledge that use of the Hipla app and any loss of
            data or damage to your Device is at your sole risk.
          </p>
          <p>
            Hipla hereby disclaims all warranties and conditions, express or
            implied, with respect to the Hipla app and your downloading and use
            of the Hipla app. Hipla does not warrant against interference with
            your enjoyment of the Hipla app, that the operation of the Hipla app
            will be uninterrupted, or error-free, or that any defects in it will
            be corrected.
          </p>
          <p>
            Hipla does not endorse or recommend any person, organization, name,
            product or service concerning or supplied by any third party and
            referred to in all information stored on or receivable through the
            Hipla app ("Information"), nor does the Information constitute
            Hipla's views or opinions. Hipla will not be a party to any
            transaction between you and any third party in any way relating to
            the Information or made through the Hipla app unless and only to the
            extent expressly stated in writing otherwise.
          </p>
          <p>
            To the extent not prohibited by law, in no event shall Hipla be
            liable for personal injury, loss of profits, loss of data, loss of
            business, business interruption, procurement of substitute goods or
            substitute services, or any other commercial damages or losses, or
            any incidental, special, indirect, consequential, or punitive
            damages whatsoever, arising out of or related to your use or
            inability to use the Hipla app, however caused.
          </p>
          <p>
            Save in respect of claims for fraudulent misrepresentation and death
            or personal injury caused by the negligence of Hipla or an affiliate
            or their respective employees or contractors, in no event shall
            Hipla's total liability to you for all damages (other than as may be
            required by applicable law) exceed one hundred Singapore dollars
            (SG$100).
          </p>
        </li>
        <li>
          <p>Indemnification</p>
          <p>
            You understand that you are personally responsible for your use of
            the Hipla app and for all User Content provided using your account.
            Accordingly, you agree to indemnify, defend, and hold Hipla and its
            affiliates and their officers, directors, employees, and agents
            harmless from and against any loss, damage, liability, cost, or
            expense of any kind (including attorneys' fees) that Hipla may incur
            in connection with a third-party claim, or otherwise, in relation to
            your use of the Hipla app, your User Content or your violation of
            either this agreement or the rights of any third party.
          </p>
        </li>
        <li>
          <p>Limitations on use of the Hipla app</p>
          <p>
            We reserve the right not to accept any use of the Hipla app or
            otherwise limit use of the Hipla app if we reasonably believe that
            the use is unauthorized, fraudulent, or otherwise unlawful.
          </p>
        </li>
        <li>
          <p>Data privacy policy</p>
          <p>
            Any information that Hipla may collect from you during your use of
            the Hipla app is subject to our Privacy Statement, which is a part
            of this Agreement. We encourage you to read and understand our
            Privacy Statement, which will be updated from time to time and made
            available in the Hipla app.
          </p>
        </li>
        <li>
          <p>Security policy.</p>
          <p>
            Hipla uses commercially reasonable security measures to protect your
            User Content and your account information and has security measures
            in place to help protect against the unauthorized access of
            personally identifiable information under our control. We utilize
            both online and offline security methods, including firewalls,
            passwords, and restricted physical access to the places where your
            information is stored to help protect personally identifiable
            information.
          </p>
        </li>
        <li>
          <p>Disputes and governing Law</p>
          <p>
            This Agreement is governed by and is to be interpreted in accordance
            with the laws of the Republic of Singapore and in the event of any
            dispute arising out of or in connection with this Agreement or any
            dispute arising in relation to the Hipla app, the courts of
            Singapore will have exclusive jurisdiction over such dispute.
          </p>
        </li>
        <li>
          <p>Bluetooth Data Policy</p>
          <p>
            Any information that Hipla collect via Bluetooth is subject to our
            privacy policy. The data is collected by bluetooth only to identify
            the position or location of the user within the premises. Hipla uses
            Bluetooth only to geofence the solution within the assessed
            premises.
          </p>
        </li>
        <li>
          <p>Location Data Policy</p>
          <p>
            Any information that Hipla collect for location service is subject
            to our privacy policy. The data is collected to identify the
            position or location of the user within the premises. Hipla uses
            geolocation only to geofence the solution within the assessed
            premises.
          </p>
        </li>
        <li>
          <p>Miscellaneous</p>
          <p>
            This Agreement is the entire agreement between you and Hipla. It
            supersedes and replaces any and all prior agreements between you and
            Hipla relating to your use of the Hipla app. The failure of Hipla to
            exercise or enforce any right of this Agreement shall not operate as
            a waiver of such right. This Agreement operates to the fullest
            extent permissible by law. If any provision of this Agreement is
            found unlawful, void, or unenforceable by a court, that provision is
            deemed severed from this Agreement and shall not affect the validity
            and enforceability of any remaining provisions. We can change this
            Agreement at any time, and your continued use of the Hipla app after
            any change we make will mean that you agreed with the changes.
            Notice to you may be made by displaying notices or links to notices
            to you generally on the Hipla app or through email communication to
            the registered account holder.
          </p>
        </li>
        <li>
          <p>How to contact us</p>
          <p>
            If you have any questions or concerns about the Hipla app you may
            contact us at:
          </p>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>
        <span>
          <strong>Tel: +65 92332199</strong>
        </span>
      </p>
      <p>
        (Operating hours: 9am to 6pm, Mondays to Fridays, closed on weekends and
        public holidays)
      </p>
      <p>
        <span>
          <strong>Email: info@hipla.io</strong>
        </span>
      </p>
    </div>
  );
};

export default TermsOfUse;
