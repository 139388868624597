import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Badge,
  Select,
  MenuItem,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  TextField,
  FormControl,
  CircularProgress
} from "@material-ui/core";
import { toastAction } from "../../../actions";
import { makeStyles, withStyles } from "@material-ui/styles";
import rolename from "../../../utils/rolename";
import getInitials from "../../../utils/getInitials";
import getAvatarStyle from "../../../utils/getAvatarStyle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import EditIcon from "@material-ui/icons/Edit";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import api from "src/utils/api";
import moment from "moment";
import VipRoomAccessDetail from "./VipRoomAccessDetail";
import { KeyboardBackspace } from "@material-ui/icons";
import { AddCircleOutline } from "@material-ui/icons";

const StyledBadge = withStyles(theme => ({
  badge: {
    right: -48,
    top: 10,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px"
  }
}))(Badge);

function VipAccessManagement(props) {
  const { close, modalData } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedVip, setSelectedVip] = useState({});
  const [accessData, setAccessData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [countData, setCount] = useState(0);
  const [openSuggestion, setopenSuggestion] = useState(false);
  const [suggestionList, setSuggestionList] = useState([]);
  const [filterEmail, setFilterEmail] = useState("");
  const [suggestionLoading, setSuggestionLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filterUserType, setFilterUserType] = useState("");
  const [loading, setloading] = useState(false);
  const orgSettings = useSelector(state => state.orgSettings);
  const [vipUser, setVipUser] = useState({});
  const [searchInput, setSearchInput] = useState("");
  const dispatch = useDispatch();
  const [searchQuery, setsearchQuery] = useState([
    {
      key: "premiseAccess.premiseId",
      value: modalData.id,
      comparison: "="
    }
  ]);
  useEffect(() => {
    if (openSuggestion) {
      (async () => {
        try {
          let dataEmployee = await api(
            "POST",
            "employee/find",
            {
              search: [
                {
                  key: "employee.name",
                  value: searchInput,
                  comparison: "LIKE"
                }
              ],
              sort: {
                key: "employee.name",
                order: "DESC"
              },
              skip: 0,
              take: 10
            },
            true
          );
          let dataStaff = await api(
            "POST",
            "staff/find",
            {
              search: [
                {
                  key: "staff.name",
                  value: searchInput,
                  comparison: "LIKE"
                }
              ],
              sort: {
                key: "staff.name",
                order: "DESC"
              },
              skip: 0,
              take: 10
            },
            true
          );
          // console.log(data);
          setSuggestionList([...dataEmployee, ...dataStaff]);
        } catch (error) {
          console.log(error);
        }
      })();
    } else {
      setSuggestionList([]);
    }
  }, [searchInput]);
  useEffect(() => {
    dispatch(
      toastAction({
        status: true,
        message: "Loading...",
        type: "info",
        noIcon: true
      })
    );
    (async () => {
      try {
        if (modalData.id) {
          const data = await api(
            "POST",
            "premise/vip/find",
            {
              search: searchQuery,
              sort: {
                key: "premiseAccess.createdAt",
                order: "DESC"
              },
              take: rowsPerPage,
              skip: page * rowsPerPage
            },
            true
          );
          const dataCount = await api(
            "POST",
            "premise/vip/findCount",
            {
              search: searchQuery
            },
            true
          );
          setAccessData(data);
          setCount(dataCount);
        }
        dispatch(
          toastAction({
            status: true,
            message: "Data loaded",
            type: "success",
            noIcon: true
          })
        );
      } catch (error) {
        console.log(error);
        dispatch(
          toastAction({
            status: true,
            message: "Data cannot be loaded",
            type: "error",
            noIcon: true
          })
        );
      }
    })();
  }, [modalData, modalOpen, page, rowsPerPage, searchQuery]);
  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  return (
    <div>
      <>
        <Grid container alignItems="center" spacing={3} direction="row">
          <Grid item>
            <IconButton aria-label="close" onClick={close} size="small">
              <KeyboardBackspace />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography>Back to All Premises</Typography>
          </Grid>
        </Grid>
        <br />
        <Grid
          container
          alignItems="center"
          direction="row"
          justifyContent="space-between"
        >
          <Grid item>
            <Typography variant="h4" color="primary">
              {modalData.name}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              startIcon={<AddCircleOutline />}
              color="primary"
              onClick={() => {
                setModalOpen(true);
                setSelectedVip({});
              }}
            >
              Add Users
            </Button>
          </Grid>
        </Grid>
        <br />
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">User Name</TableCell>
                <TableCell align="left">User Type</TableCell>
                <TableCell align="left">Start Date</TableCell>
                <TableCell align="left">End Date</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  <Autocomplete
                    open={openSuggestion}
                    options={suggestionList}
                    noOptionsText={"No user found"}
                    onOpen={() => {
                      setopenSuggestion(true);
                    }}
                    onClose={() => {
                      setopenSuggestion(false);
                    }}
                    onChange={(event, newValue) => {
                      setVipUser(newValue);
                      if (newValue?.accessDetails) {
                        setsearchQuery([
                          ...searchQuery.filter(
                            item => item.key !== "premiseAccess.userAccessId"
                          ),
                          {
                            key: "premiseAccess.userAccessId",
                            value: newValue.accessDetails.id,
                            comparison: "="
                          }
                        ]);
                      } else {
                        setsearchQuery([
                          ...searchQuery.filter(
                            item => item.key !== "premiseAccess.userAccessId"
                          )
                        ]);
                        setSearchInput("");
                      }
                    }}
                    getOptionLabel={option => {
                      return option.name;
                    }}
                    renderOption={item => {
                      return (
                        <ListItem
                          dense
                          disableGutters
                          className="suggestedParticipant"
                        >
                          <ListItemAvatar>
                            <Avatar style={getAvatarStyle(item.email)}>
                              {getInitials(item.inputValue ? "+" : item.name)}
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <StyledBadge
                                badgeContent={
                                  item.role ? rolename(item.role) : "Staff"
                                }
                                color={item.role ? "primary" : "secondary"}
                              >
                                <Typography variant="h5">
                                  {item.name}
                                </Typography>
                              </StyledBadge>
                            }
                            secondary={
                              <Typography variant="body2" color="textSecondary">
                                {item.role &&
                                  item[orgSettings.employeePrimaryIdentifier]}
                                {!item.role &&
                                  item[orgSettings.staffPrimaryIdentifier]}
                              </Typography>
                            }
                          />
                        </ListItem>
                      );
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        size="small"
                        placeholder="Search by Name"
                        fullWidth
                        variant="outlined"
                        value={searchInput}
                        onChange={e => setSearchInput(e.target.value)}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </TableCell>
                <TableCell align="left">
                  <FormControl variant="outlined" fullWidth size="small">
                    <Select
                      variant="outlined"
                      defaultValue=""
                      displayEmpty
                      value={filterUserType}
                      onChange={e => {
                        setFilterUserType(e.target.value);
                        if (e.target.value == "") {
                          setsearchQuery([
                            ...searchQuery.filter(
                              item => item.key !== "userAccess.employee"
                            )
                          ]);
                        } else {
                          setsearchQuery([
                            ...searchQuery.filter(
                              item => item.key !== "userAccess.employee"
                            ),
                            {
                              key: "userAccess.employee",
                              value:
                                e.target.value == "employee"
                                  ? "NOT NULL"
                                  : "NULL",
                              comparison: "IS"
                            }
                          ]);
                        }
                      }}
                    >
                      <MenuItem value="">
                        <em>All</em>
                      </MenuItem>
                      <MenuItem value="employee">Employee</MenuItem>
                      <MenuItem value="staff">Staff</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell align="left">
                  <TextField
                    type="date"
                    size="small"
                    value={filterStartDate}
                    margin="none"
                    variant="outlined"
                    placeholder="Filter by date"
                    fullWidth
                    onChange={e => {
                      setFilterStartDate(e.target.value);
                      setsearchQuery([
                        ...searchQuery.filter(
                          item => item.key !== "premiseAccess.startAccessTs"
                        ),
                        {
                          key: "premiseAccess.startAccessTs",
                          value: moment(e.target.value)
                            .startOf("day")
                            .format("x"),
                          comparison: ">="
                        },
                        {
                          key: "premiseAccess.startAccessTs",
                          value: moment(e.target.value)
                            .add(1, "day")
                            .startOf("day")
                            .format("x"),
                          comparison: "<"
                        }
                      ]);
                    }}
                  />
                </TableCell>
                <TableCell align="left">
                  <TextField
                    type="date"
                    size="small"
                    value={filterEndDate}
                    margin="none"
                    variant="outlined"
                    placeholder="Filter by date"
                    fullWidth
                    onChange={e => {
                      setFilterEndDate(e.target.value);
                      setsearchQuery([
                        ...searchQuery.filter(
                          item => item.key !== "premiseAccess.endAccessTs"
                        ),
                        {
                          key: "premiseAccess.endAccessTs",
                          value: moment(e.target.value)
                            .startOf("day")
                            .format("x"),
                          comparison: ">="
                        },
                        {
                          key: "premiseAccess.endAccessTs",
                          value: moment(e.target.value)
                            .add(1, "day")
                            .startOf("day")
                            .format("x"),
                          comparison: "<"
                        }
                      ]);
                    }}
                  />
                </TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {accessData.map((row, ind) => (
                <TableRow key={ind}>
                  <TableCell align="left">
                    {row.userAccess.employee?.name ??
                      row.userAccess.staff?.name}
                  </TableCell>
                  <TableCell align="left">
                    {row.userAccess.employee ? "Employee" : "Staff"}
                  </TableCell>
                  <TableCell align="left">
                    {moment
                      .utc(new Date(parseInt(row.startAccessTs)).toISOString())
                      .local()
                      .format("Do MMM YYYY")}
                  </TableCell>
                  <TableCell align="left">
                    {moment
                      .utc(new Date(parseInt(row.endAccessTs)).toISOString())
                      .local()
                      .format("Do MMM YYYY")}
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      aria-label="edit"
                      onClick={() => {
                        setModalOpen(true);
                        setSelectedVip(row);
                      }}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <br />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={countData}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
        <VipRoomAccessDetail
          newModal={modalOpen}
          premiseId={modalData.id}
          setnewModal={() => setModalOpen(false)}
          modalData={selectedVip}
        />
      </>
    </div>
  );
}

export default VipAccessManagement;
