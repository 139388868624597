import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  IconButton,
  Collapse,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { captureException } from "@sentry/browser";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Calendar,
  Clipboard,
  Clock,
  Info,
  MapPin,
  ArrowDown,
  ArrowUp
} from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Page from "src/components/Page";
import * as actions from "../../actions";
import { toastAction } from "../../actions";
import api from "../../utils/api";
import rolename from "../../utils/rolename";
import getAvatarStyle from "../../utils/getAvatarStyle";
import getInitials from "../../utils/getInitials";
import Loader from "../../utils/loader";
import PhotoModal from "./PhotoModal";
import ResponseModal from "./ResponseModal";
import ViewModal from "./ViewModal";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  container: {
    marginTop: theme.spacing(3)
  }
}));

const MeetingDetails = props => {
  const navigate = useNavigate();
  const params = useParams();
  const [meeting, setMeeting] = useState({});
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [participantModalId, setParticipantModalId] = useState("");
  const [photoModalOpen, setPhotoModalOpen] = useState(false);
  const [responseModalOpen, setResponseModalOpen] = useState(false);
  const [responseSent, setResponseSent] = useState(false);
  const [selectRoom, setSelectRoom] = useState(true);
  const [participantId, setParticipantId] = useState("");
  const [myParticipantId, setMyParticipantId] = useState(null);
  const [pendingMyResponse, setPendingMyResponse] = useState(false);
  const [showEditButton, setShowEditButton] = useState(false);
  const [viewActivity, setViewActivity] = useState([]);
  const user = useSelector(state => state.user);
  const orgSettings = useSelector(state => state.orgSettings);
  const business = useSelector(state => state.business);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    setLoading(true);
    (async () => {
      fetchMeeting();
    })();
  }, [responseSent]);

  const fetchMeeting = async () => {
    try {
      const data = await api("GET", `meeting/${params.id}`, null, true);
      setMeeting(data);

      setLoading(false);
      setViewActivity(data.participants.map(item => false));
      dispatch(actions.meeting(data));
      let pendingMe = data.participants.find(
        itemParent =>
          (itemParent.employeeId === user.userId ||
            itemParent.visitorId === user.userId) &&
          itemParent.status === "pending"
      );
      if (
        pendingMe &&
        (orgSettings.visitorApprovalType === "host" ||
          orgSettings.employeeApprovalType === "invitee")
      ) {
        setMyParticipantId(pendingMe.id);
        setPendingMyResponse(true);
      } else {
        setPendingMyResponse(false);
      }
      if (
        JSON.parse(orgSettings.editMeetingUser).includes("organizer") &&
        data.requestedBy === user.userId
      ) {
        setShowEditButton(true);
      } else if (
        JSON.parse(orgSettings.editMeetingUser).includes("admin") &&
        (user.role === "admin" || user.role === "superadmin")
      ) {
        setShowEditButton(true);
      } else if (
        JSON.parse(orgSettings.editMeetingUser).includes("visitor") &&
        user.loginType === "visitor" &&
        data.participants.map(item => item.visitorId).includes(user.userId)
      ) {
        setShowEditButton(true);
      } else if (
        JSON.parse(orgSettings.editMeetingUser).includes("employee") &&
        user.loginType === "employee" &&
        data.participants.map(item => item.employeeId).includes(user.userId)
      ) {
        setShowEditButton(true);
      }
      let hostParticipant = data.participants.find(
        item => item.isHost === true
      );

      let respondingParticipant = data.participants.find(
        item => item.id === pendingMe.id
      );

      if (hostParticipant.employee || respondingParticipant.visitor)
        setSelectRoom(false);
    } catch (error) {
      captureException(error);
    }
  };

  const handleResponse = async (participantId, response) => {
    try {
      await api(
        "PATCH",
        "meeting/updateParticipantStatus/" + participantId,
        {
          status: response
        },
        true
      );
      setResponseSent(true);
      dispatch(
        toastAction({
          status: true,
          message: "Meeting request successfully " + response,
          type: "success"
        })
      );
    } catch (error) {
      captureException(error);
      dispatch(
        toastAction({
          status: true,
          message: "Something went wrong",
          type: "error"
        })
      );
    }
  };

  const cancelMeeting = async () => {
    try {
      await api("PATCH", "meeting/cancel/" + meeting.id, null, true);
      dispatch(
        toastAction({
          status: true,
          message: "Meeting successfully cancelled",
          type: "success"
        })
      );
      await fetchMeeting();
    } catch (error) {
      captureException(error);
      dispatch(
        toastAction({
          status: true,
          message: "Something went wrong",
          type: "error"
        })
      );
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <Page className={classes.root} title="Meeting Details">
      <Grid
        alignItems="flex-end"
        container
        justify="space-between"
        spacing={3}
        direction="row"
      >
        <Grid item>
          <Typography component="h1" variant="h3" color="primary">
            View Meeting
          </Typography>
        </Grid>
        {showEditButton && meeting.status !== "cancelled" && (
          <Grid item>
            <Grid container spacing={3}>
              <Grid item>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={cancelMeeting}
                >
                  Cancel Meeting
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    navigate("edit");
                  }}
                >
                  Edit Meeting
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid className={classes.container} container spacing={1}>
        <Grid item md={4} sm={12}>
          <Card>
            <CardContent>
              <Typography component="h2" gutterBottom variant="h4">
                Meeting Details
              </Typography>
              <br />
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    spacing={1}
                    alignItems="center"
                  >
                    <Grid item>
                      <Box width={30}>
                        <Clipboard />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box width={80}>
                        <ListItem disableGutters>
                          <ListItemText
                            primary={
                              <Typography variant="h5">Purpose</Typography>
                            }
                          />
                        </ListItem>
                      </Box>
                    </Grid>
                    <Grid item>
                      <ListItem disableGutters>
                        <ListItemText
                          primary={
                            <Typography variant="body1">
                              {meeting.purpose}
                            </Typography>
                          }
                        />
                      </ListItem>
                    </Grid>
                  </Grid>
                </Grid>
                {meeting.room && business.domain !== "peoplestrong" && (
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      spacing={1}
                      alignItems="center"
                    >
                      <Grid item>
                        <Box width={30}>
                          <MapPin />
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box width={80}>
                          <ListItem disableGutters>
                            <ListItemText
                              primary={
                                <Typography variant="h5">Venue</Typography>
                              }
                            />
                          </ListItem>
                        </Box>
                      </Grid>
                      <Grid item>
                        <ListItem disableGutters>
                          <ListItemText
                            primary={
                              <Typography variant="body1">
                                {meeting.room.name}
                                {", "}
                                {meeting.building.name}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    spacing={1}
                    alignItems="center"
                  >
                    <Grid item>
                      <Box width={30}>
                        <Calendar />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box width={80}>
                        <ListItem disableGutters>
                          <ListItemText
                            primary={<Typography variant="h5">Date</Typography>}
                          />
                        </ListItem>
                      </Box>
                    </Grid>
                    <Grid item>
                      <ListItem disableGutters>
                        <ListItemText
                          primary={
                            <Typography variant="body1">
                              {moment(parseInt(meeting.startTime))
                                .local()
                                .format("Do MMM YYYY")}
                            </Typography>
                          }
                        />
                      </ListItem>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    spacing={1}
                    alignItems="center"
                  >
                    <Grid item>
                      <Box width={30}>
                        <Clock />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box width={80}>
                        <ListItem disableGutters>
                          <ListItemText
                            primary={<Typography variant="h5">Time</Typography>}
                          />
                        </ListItem>
                      </Box>
                    </Grid>
                    <Grid item>
                      <ListItem disableGutters>
                        <ListItemText
                          primary={
                            business.domain !== "peoplestrong" ? (
                              <Typography variant="body1">
                                {moment(parseInt(meeting.startTime))
                                  .local()
                                  .format("LT")}
                                {" to "}
                                {moment(parseInt(meeting.endTime))
                                  .local()
                                  .format("LT")}
                              </Typography>
                            ) : (
                              <Typography variant="body1">
                                {moment(parseInt(meeting.startTime))
                                  .local()
                                  .format("LT")}
                              </Typography>
                            )
                          }
                        />
                      </ListItem>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    spacing={1}
                    alignItems="center"
                  >
                    <Grid item>
                      <Box width={30}>
                        <Info />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box width={80}>
                        <ListItem disableGutters>
                          <ListItemText
                            primary={
                              <Typography variant="h5">Status</Typography>
                            }
                          />
                        </ListItem>
                      </Box>
                    </Grid>
                    <Grid item>
                      <ListItem disableGutters>
                        <ListItemText
                          primary={
                            <Typography variant="button">
                              {meeting.status}
                            </Typography>
                          }
                        />
                      </ListItem>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <br />
          {meeting.visitorOtp && (
            <Card>
              <CardContent>
                <Typography component="h2" gutterBottom variant="h4">
                  TAT Details
                </Typography>
                <br />
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      spacing={1}
                      alignItems="center"
                    >
                      <Grid item></Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      spacing={1}
                      alignItems="center"
                    >
                      <Grid item>
                        <Box width={30}>
                          <Calendar />
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box width={80}>
                          <ListItem disableGutters>
                            <ListItemText
                              primary={
                                <Typography variant="h5">Date</Typography>
                              }
                            />
                          </ListItem>
                        </Box>
                      </Grid>
                      <Grid item>
                        <ListItem disableGutters>
                          <ListItemText
                            primary={
                              <Typography variant="body1">
                                {moment(parseInt(meeting.startTime))
                                  .local()
                                  .format("Do MMM YYYY")}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      spacing={1}
                      alignItems="center"
                    >
                      <Grid item>
                        <Box width={30}>
                          <Clock />
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box width={80}>
                          <ListItem disableGutters>
                            <ListItemText
                              primary={
                                <Typography variant="h5">
                                  Visitor Arrived At
                                </Typography>
                              }
                            />
                          </ListItem>
                        </Box>
                      </Grid>
                      <Grid item>
                        <ListItem disableGutters>
                          <ListItemText
                            primary={
                              <Typography variant="body1">
                                {meeting.tatStartTime &&
                                  moment(parseInt(meeting.tatStartTime))
                                    .local()
                                    .format("LT")}
                                {!meeting.tatStartTime && "Not Available"}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      spacing={1}
                      alignItems="center"
                    >
                      <Grid item>
                        <Box width={30}>
                          <Clock />
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box width={80}>
                          <ListItem disableGutters>
                            <ListItemText
                              primary={
                                <Typography variant="h5">
                                  Visitor Received At
                                </Typography>
                              }
                            />
                          </ListItem>
                        </Box>
                      </Grid>
                      <Grid item>
                        <ListItem disableGutters>
                          <ListItemText
                            primary={
                              <Typography variant="body1">
                                {meeting.tatEndTime &&
                                  moment(parseInt(meeting.tatEndTime))
                                    .local()
                                    .format("LT")}
                                {!meeting.tatEndTime && "Not Available"}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      spacing={1}
                      alignItems="center"
                    >
                      <Grid item>
                        <Box width={30}>
                          <Clock />
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box width={80}>
                          <ListItem disableGutters>
                            <ListItemText
                              primary={
                                <Typography variant="h5">
                                  Visitor Waiting Time
                                </Typography>
                              }
                            />
                          </ListItem>
                        </Box>
                      </Grid>
                      <Grid item>
                        <ListItem disableGutters>
                          <ListItemText
                            primary={
                              <Typography variant="body1">
                                {meeting.tatEndTime &&
                                  meeting.tatStartTime &&
                                  moment
                                    .utc(
                                      meeting.tatEndTime - meeting.tatStartTime
                                    )
                                    .format(
                                      meeting.tatEndTime -
                                        meeting.tatStartTime >
                                        60 * 60 * 1000
                                        ? "H[h]m[m]s[s] "
                                        : " m[m]s[s]"
                                    )}
                                {!(
                                  meeting.tatEndTime && meeting.tatStartTime
                                ) && "Not Available"}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </Grid>
                    </Grid>
                  </Grid>
                  {(user.role === "businessAdmin" ||
                    user.role === "admin" ||
                    user.role === "superadmin") && (
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        spacing={1}
                        alignItems="center"
                      >
                        <Grid item>
                          <Box width={30}>
                            <Clock />
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box width={80}>
                            <ListItem disableGutters>
                              <ListItemText
                                primary={
                                  <Typography variant="h5">
                                    Visitor OTP
                                  </Typography>
                                }
                              />
                            </ListItem>
                          </Box>
                        </Grid>
                        <Grid item>
                          <ListItem disableGutters>
                            <ListItemText
                              primary={
                                <Typography variant="body1">
                                  {meeting.visitorOtp}
                                </Typography>
                              }
                            />
                          </ListItem>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </Card>
          )}
        </Grid>

        <Grid item md={8} sm={12}>
          <Card>
            <CardContent>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
              >
                <Grid item>
                  <Typography component="h2" gutterBottom variant="h4">
                    Participants
                  </Typography>
                </Grid>
                {pendingMyResponse && (
                  <Grid item>
                    {orgSettings.meetingRoomManagement && (
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          setParticipantId(myParticipantId);
                          setResponseModalOpen(true);
                        }}
                      >
                        Response
                      </Button>
                    )}
                    {!orgSettings.meetingRoomManagement && (
                      <Grid container spacing={1}>
                        <Grid item>
                          <Button
                            fullWidth
                            variant="contained"
                            onClick={() => {
                              handleResponse(myParticipantId, "accepted");
                            }}
                            color="primary"
                          >
                            Accept
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="outlined"
                            fullWidth
                            onClick={() => {
                              handleResponse(myParticipantId, "declined");
                            }}
                            color="secondary"
                          >
                            Decline
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                    <br />
                    <br />
                  </Grid>
                )}
              </Grid>

              {meeting.participants.map((itemParent, index) => {
                const item =
                  itemParent.employee ||
                  itemParent.visitor ||
                  itemParent.staff ||
                  {};
                return (
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Grid container direction="row" alignItems="center">
                        <Grid item>
                          <Box minWidth={280} flexBasis={1}>
                            <ListItem disableGutters>
                              <ListItemAvatar>
                                <Avatar
                                  style={getAvatarStyle(item.email || "")}
                                >
                                  {getInitials(item.name)}
                                </Avatar>
                              </ListItemAvatar>

                              <ListItemText
                                primary={
                                  <Typography variant="h5">
                                    {item.name}
                                  </Typography>
                                }
                                secondary={
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    {itemParent.type == "employee" &&
                                      item[
                                        orgSettings.employeePrimaryIdentifier
                                      ]}
                                    {itemParent.type == "visitor" &&
                                      item[
                                        orgSettings.visitorPrimaryIdentifier
                                      ]}
                                    {itemParent.type == "staff" &&
                                      item[orgSettings.staffPrimaryIdentifier]}
                                  </Typography>
                                }
                              />
                            </ListItem>
                          </Box>

                          {itemParent.status === "pending" &&
                            orgSettings.visitorApprovalType === "admin" &&
                            (user.role === "admin" ||
                              user.role === "superadmin") &&
                            itemParent.employee && (
                              <Box minWidth={280} flexBasis={1}>
                                <Grid item>
                                  {orgSettings.meetingRoomManagement && (
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      onClick={() => {
                                        setParticipantId(itemParent.id);
                                        setResponseModalOpen(true);
                                      }}
                                    >
                                      Response
                                    </Button>
                                  )}
                                  {!orgSettings.meetingRoomManagement && (
                                    <Grid container spacing={1}>
                                      <Grid item>
                                        <Button
                                          fullWidth
                                          variant="contained"
                                          onClick={() => {
                                            handleResponse(
                                              itemParent.id,
                                              "accepted"
                                            );
                                          }}
                                          color="primary"
                                        >
                                          Accept
                                        </Button>
                                      </Grid>
                                      <Grid item>
                                        <Button
                                          variant="outlined"
                                          fullWidth
                                          onClick={() => {
                                            handleResponse(
                                              itemParent.id,
                                              "declined"
                                            );
                                          }}
                                          color="secondary"
                                        >
                                          Decline
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  )}
                                  <br />
                                  <br />
                                </Grid>{" "}
                              </Box>
                            )}
                        </Grid>
                        <Grid item>
                          <Box minWidth={180} flexBasis={1}>
                            <ListItem disableGutters>
                              <ListItemText
                                primary={
                                  <Typography variant="h5">Type</Typography>
                                }
                                secondary={
                                  <Typography
                                    variant="button"
                                    color="textSecondary"
                                  >
                                    {itemParent.type == "employee"
                                      ? rolename(item.role)
                                      : itemParent.type}
                                    <Typography
                                      variant="button"
                                      component="span"
                                      color="primary"
                                    >
                                      {itemParent.isHost && "[Organizer]"}
                                    </Typography>
                                  </Typography>
                                }
                              />
                            </ListItem>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box minWidth={120} flexBasis={1}>
                            <ListItem disableGutters>
                              {business.domain !== "peoplestrong" ? (
                                <ListItemText
                                  primary={
                                    <Typography variant="h5">Status</Typography>
                                  }
                                  secondary={
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      {itemParent.status}
                                    </Typography>
                                  }
                                />
                              ) : itemParent.type === "employee" ? (
                                <ListItemText
                                  primary={
                                    <Typography variant="h5">
                                      Department
                                    </Typography>
                                  }
                                  secondary={
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      {item.department}
                                    </Typography>
                                  }
                                />
                              ) : (
                                <ListItemText
                                  primary={
                                    <Typography variant="h5">
                                      Coming from
                                    </Typography>
                                  }
                                  secondary={
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      {item.company || "N/A"}
                                    </Typography>
                                  }
                                />
                              )}
                            </ListItem>
                          </Box>
                        </Grid>
                        {orgSettings.tempMeasure && (
                          <Grid item>
                            <Box flexBasis={1}>
                              <ListItem disableGutters>
                                <ListItemText
                                  primary={
                                    <Typography variant="h5">
                                      Temperature
                                    </Typography>
                                  }
                                  secondary={
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      {itemParent.temperature || "N/A"}
                                    </Typography>
                                  }
                                />
                              </ListItem>
                            </Box>
                          </Grid>
                        )}
                        <Grid item>
                          <Box px={3}>
                            {itemParent.type === "visitor" &&
                              orgSettings.showSafetySurvey && (
                                <Button
                                  variant="outlined"
                                  onClick={() => {
                                    setParticipantModalId(itemParent.id);
                                    setModalOpen(true);
                                  }}
                                >
                                  View Safety Survey
                                </Button>
                              )}
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box px={3}>
                            {itemParent.type === "visitor" && item.photo && (
                              <>
                                <Button
                                  variant="outlined"
                                  onClick={() => {
                                    setPhotoModalOpen(true);
                                  }}
                                >
                                  View Photo
                                </Button>
                                <PhotoModal
                                  open={photoModalOpen}
                                  close={() => {
                                    setPhotoModalOpen(false);
                                  }}
                                  checkInPhoto={item.photo}
                                  idPhoto={item.idPhoto}
                                />
                              </>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container direction="row" alignItems="center">
                        {itemParent.type === "visitor" &&
                          orgSettings.baggageBusinessEnable && (
                            <Grid item xs={12}>
                              <Typography
                                component="h2"
                                gutterBottom
                                variant="h4"
                                color="primary"
                                align="left"
                              >
                                Baggage
                              </Typography>
                              <Divider />

                              {itemParent.baggages.length === 0 && (
                                <Typography
                                  component="h2"
                                  gutterBottom
                                  variant="h5"
                                  align="left"
                                >
                                  No baggage added
                                </Typography>
                              )}
                              {itemParent.baggages.map(bag => (
                                <ListItem disableGutters>
                                  <ListItemText
                                    primary={
                                      bag.name.charAt(0).toUpperCase() +
                                      bag.name.slice(1)
                                    }
                                    secondary={
                                      <React.Fragment>
                                        <Typography
                                          component="span"
                                          variant="body2"
                                          color="textPrimary"
                                        >
                                          {bag.description}
                                        </Typography>
                                        {bag.model !== "" &&
                                          bag.model !== null &&
                                          " - " + bag.model}
                                        {bag.serial !== "" &&
                                          bag.serial !== null &&
                                          " - " + bag.serial}
                                      </React.Fragment>
                                    }
                                  />
                                </ListItem>
                              ))}
                              <br />
                            </Grid>
                          )}
                        <Grid item xs={12}>
                          <Typography
                            component="h2"
                            gutterBottom
                            variant="h4"
                            color="primary"
                            align="left"
                          >
                            Activities
                          </Typography>
                          <Divider />
                          {meeting.activities.filter(
                            activityItem =>
                              activityItem.visitorId === item.id ||
                              activityItem.employeeId === item.id ||
                              activityItem.staffId === item.id
                          ).length === 0 && (
                            <Typography
                              component="h2"
                              gutterBottom
                              variant="h5"
                              align="left"
                            >
                              No activities yet
                            </Typography>
                          )}

                          {meeting.activities
                            .filter(
                              activityItem =>
                                activityItem.visitorId === item.id ||
                                activityItem.employeeId === item.id ||
                                activityItem.staffId === item.id
                            )
                            .map(eachActivity => (
                              <ListItem disableGutters>
                                <ListItemText
                                  primary={
                                    <Typography
                                      gutterBottom
                                      variant="h5"
                                      align="left"
                                    >
                                      {eachActivity.eventDescription ===
                                        "visitor pending" && "Request Received"}
                                      {eachActivity.eventDescription ===
                                        "employee pending" &&
                                        "Request Received"}
                                      {eachActivity.eventDescription ===
                                        "visitor accepted" &&
                                        "Request Accepted"}
                                      {eachActivity.eventDescription ===
                                        "employee accepted" &&
                                        "Request Accepted"}
                                      {eachActivity.eventDescription ===
                                        "staff accepted" && "Request Accepted"}
                                      {eachActivity.eventDescription ===
                                        "visitor checkedIn" && "Checked In"}
                                      {eachActivity.eventDescription ===
                                        "visitor checkedOut" && "Checked Out"}
                                      {eachActivity.eventDescription ===
                                        "staff checkedIn" && "Checked In"}
                                      {eachActivity.eventDescription ===
                                        "staff checkedOut" && "Checked Out"}
                                    </Typography>
                                  }
                                  secondary={
                                    <Typography
                                      gutterBottom
                                      variant="body2"
                                      align="left"
                                    >
                                      {item.name}
                                      {eachActivity.eventDescription ===
                                        "visitor pending" &&
                                        " has received the meeting request "}
                                      {eachActivity.eventDescription ===
                                        "employee pending" &&
                                        " has received the meeting request "}
                                      {eachActivity.eventDescription ===
                                        "visitor accepted" &&
                                        " has accepted the meeting request "}
                                      {eachActivity.eventDescription ===
                                        "employee accepted" &&
                                        " has accepted the meeting request "}
                                      {eachActivity.eventDescription ===
                                        "staff accepted" &&
                                        " has accepted the meeting request "}
                                      {eachActivity.eventDescription ===
                                        "visitor checkedIn" &&
                                        " has checked in at the venue "}
                                      {eachActivity.eventDescription ===
                                        "visitor checkedOut" &&
                                        " has checked out at the venue "}
                                      {eachActivity.eventDescription ===
                                        "staff checkedIn" &&
                                        " has checked in at the venue "}
                                      {eachActivity.eventDescription ===
                                        "staff checkedOut" &&
                                        " has checked out at the venue "}
                                      at{" "}
                                      <b>
                                        {moment(
                                          parseInt(eachActivity.createdAt)
                                        )
                                          .local()
                                          .format("LT")}
                                        {", "}
                                        {moment(
                                          parseInt(eachActivity.createdAt)
                                        )
                                          .local()
                                          .format("Do MMM YYYY")}
                                      </b>
                                    </Typography>
                                  }
                                />
                              </ListItem>
                            ))}
                        </Grid>
                        <Grid item>
                          <Box>
                            {(itemParent.checkInPhoto ||
                              itemParent.idPhoto) && (
                              <>
                                <Button
                                  variant="outlined"
                                  onClick={() => {
                                    setPhotoModalOpen(true);
                                  }}
                                >
                                  Checkin Photos
                                </Button>
                                <PhotoModal
                                  open={photoModalOpen}
                                  close={() => {
                                    setPhotoModalOpen(false);
                                  }}
                                  checkInPhoto={itemParent.checkInPhoto}
                                  idPhoto={itemParent.idPhoto}
                                />
                              </>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <ViewModal
        open={modalOpen}
        close={() => {
          setModalOpen(false);
        }}
        participantId={participantModalId}
      />
      <ResponseModal
        open={responseModalOpen}
        close={responded => {
          console.log(responded);
          setResponseModalOpen(false);
          responded && setResponseSent(true);
        }}
        selectRoom={selectRoom}
        participantId={participantId}
      />
    </Page>
  );
};

export default MeetingDetails;
