import Button from "@material-ui/core/Button";
import {
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Card,
  CardContent
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import Dialog from "@material-ui/core/Dialog";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";
import RestoreIcon from "@material-ui/icons/Restore";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import EditIcon from "@material-ui/icons/Edit";
import { captureException } from "@sentry/react";
import React, { useEffect, useState } from "react";
import { PlusCircle } from "react-feather";
import api from "src/utils/api";
import { useLocation, useNavigate } from "react-router";
import Loader from "../../../utils/loader";
import ConfirmationDialog from "../common/ConfirmDialog";
import OrganizationModal from "./OrganizationModal";
import { useDispatch, useSelector } from "react-redux";
import { DropzoneDialog } from "material-ui-dropzone";
import { organizationSettings } from "../../../actions";
import { business as businessDispatch, toastAction } from "../../../actions";
import axios from "axios";
import baseurl from "../../../utils/baseurl";
import IsActiveModal from "./IsActiveModel";
// import { CheckBox } from "@material-ui/icons";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  button: {
    marginBottom: 10
  }
});

export default function Organizations({ className, ...rest }) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [buildings, setBuildings] = useState([]);
  const [isInactiveOpen, setInActiveOpen] = useState(false);
  const [countryDetails, setCountryDetails] = useState();
  const [open, setOpen] = useState(false);
  const [restoreopen, setrestoreOpen] = useState(false);
  const [isLoading, setLoader] = useState(true);
  const [error, setError] = useState("");
  const [isLoadingDelete, setDeleteLoader] = useState(false);
  const [isLoadingRestore, setRestoreLoader] = useState(false);
  const [organizationList, setOrganizationList] = useState([]);
  const [organizationInactiveList, setOrganizationInactiveList] = useState([]);
  const [activeData, setData] = useState({});
  const [countData, setCount] = useState(0);
  const [countDataInactive, setInactiveCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [floorList, setFloorList] = useState([]);
  const [dropzoneOpen, setDropzoneOpen] = useState(false);
  const [dropzone, setDropzone] = useState({});
  const [viewOpen, setViewOpen] = useState(false);
  const [building, setBuilding] = useState([]);
  const [viewUrl, setViewUrl] = useState("");
  const [viewType, setViewType] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [newOrgSettings, setNewOrgSettings] = useState({});
  const [exchangeDetail, setExchangeDetail] = useState();
  const [viewOrganization, setViewOrganization] = useState(
    new Array(rowsPerPage).fill(false)
  );
  const business = useSelector(state => state.business);
  const [businessDetails, setBusinessDetails] = useState(business);
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const [checked, setChecked] = useState(false);
  const [orgDetails, setOrgDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [orgData, setOrgData] = useState();
  const [defaultMeetingRoom, setDefaultMeetingRoom] = useState("");
  const [roomList, setRoomList] = useState([]);
  const [defaultRoomName, setDefaultRoomName] = useState("");
  const org = useSelector(state => state.org);
  const orgSettings = useSelector(state => state.orgSettings);
  let location,
    { search } = useLocation();
  let navigate = useNavigate();
  useEffect(() => {
    dispatch(organizationSettings({ ...orgSettings, ...newOrgSettings }));
  }, [loading]);

  useEffect(() => {
    (async () => {
      try {
        const buildings = await api(
          "POST",
          "building/find",
          {
            search: [{ key: "building.isActive", value: "", comparison: "=" },
            {"key":"organization.isActive","value":"true","comparison":"="}],
            sort: { key: "building.createdAt", order: "ASC" },
            skip: 0,
            take: 10
          },
          true
        );
        setBuildings(buildings);
        // if (buildings.length === 1) {
        //   setBuildingId(buildings[0].id);
        // }
      } catch (error) {
        if (error.response && error.response.data) {
          dispatch(
            toastAction({
                status: true,
                message: error.response.data.message,
                type: "error"
            })
        )
          setError(error.response.data);
        }
        captureException(error);
      }
    })();
  }, []);

  React.useEffect(() => {
    (async () => {
      const floorL = await api("POST", "floor/find", null , true);
      setFloorList(floorL);
      organizationCount();
      organizationInactiveCount();
    })();
  }, []);

  // console.log("floorList",floorList);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let params = new URLSearchParams(search);
        let type = params.get("type");
        let code = params.get("code");
        if (code && type === "google") {
          const response = await api(
            "POST",
            "integration/update",
            {
              code,
              type,
              redirect_url: `${window.location.origin}/settings/integration/google`
            },
            true
          );
          await fetchData();
          dispatch(
            toastAction({
              type: "success",
              message: "Google integration updated successfully"
            })
          );
          navigate("#integration");
          setLoading(false);
        }
      } catch (error) {
        if (error.response && error.response.data) {
          dispatch(
            toastAction({
                status: true,
                message: error.response.data.message,
                type: "error"
            })
        )
          setError(error.response.data);
        }
        console.log(error);
        setLoading(false);
      }
    })();
  }, []);

  const fetchIntegrationData = async () => {
    try {
      const newdata =
        user.role !== "businessAdmin"
          ? await api(
              "POST",
              "organization/getOrgSettingsFilteredByKeys/" +
                user.organizationId,
              {
                keys: [
                  "meetingKeywords",
                  "outLookTimeOverlap",
                  "meetingRoomManagement",
                  "meetingRoomRelease",
                  "meetingRoomReleaseTime",
                  "outlookIntegration",
                  "googleIntegration",
                  "salesforceIntegration",
                  "isOutlookSyncOnlineMeeting",
                  "isOutlookSyncMeetingWithSelf",
                  "isGoogleSyncOnlineMeeting",
                  "isGoogleSyncMeetingWithSelf",
                  "adEnable",
                  "adUserUpdateSendEmail",
                  "adUsersDefaultBuildingId",
                  "editMeetingUser",
                  "defaultMeetingRoom",
                  "trailingMeetingEnabledOrg",
                  "trailingMeetingAllowOverlap",
                  "trailingMeetingSkipOnOverlap",
                  "trailingMeetingDurationMinutes",
                  "outlookTenantId"
                ]
              },
              true
            )
          : {};
      const newdatabusiness = await api(
        "POST",
        "business/getBusinessSettingsFilteredByKeys/" + user.businessId,
        {
          keys: [
            "outlookBusinesIntegration",
            "googleBusinesIntegration",
            "businessmeetingRoomManagement",
            "businessPantryManagement",
            "salesforceBusinesIntegration",
            "trailingMeetingEnabled",
          ]
        },
        true
      );
      setNewOrgSettings({ ...newdata, ...newdatabusiness });
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(
          toastAction({
              status: true,
              message: error.response.data.message,
              type: "error"
          })
      )
        setError(error.response.data);
      }
      captureException(error);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchIntegrationData();
      await fetchRoomList();
    })();
  }, []);

  const organizationCount = async () => {
    try {
      const countPayload = {
        search: [
          { key: "organization.isActive", value: true, comparison: "=" }
        ],
        sort: { key: "organization.createdAt", order: "ASC" },
        skip: 0,
        take: 10
      };
      const res = await api(
        "POST",
        "organization/findCount",
        countPayload,
        true
      );
      setCount(res);
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(
          toastAction({
              status: true,
              message: error.response.data.message,
              type: "error"
          })
      )
        setError(error.response.data);
      }
      captureException(error);
    }
  };
  const organizationInactiveCount = async () => {
    try {
      const countPayload = {
        search: [
          { key: "organization.isActive", value: false, comparison: "=" }
        ],
        sort: { key: "organization.createdAt", order: "ASC" },
        skip: 0,
        take: 10
      };
      const res = await api(
        "POST",
        "organization/findCount",
        countPayload,
        true
      );
      setInactiveCount(res);
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(
          toastAction({
              status: true,
              message: error.response.data.message,
              type: "error"
          })
      )
        setError(error.response.data);
      }
      captureException(error);
    }
  };

  const handleChange = e => {
    setNewOrgSettings({ ...newOrgSettings, [e.target.name]: e.target.checked });
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
    setViewOrganization(new Array(event.target.value).fill(false));
    setPage(0);
  };

  React.useEffect(() => {
    fetchData();
    fetchInactiveData();
    handleViewDialog(businessDetails.logo, "image");
  }, [rowsPerPage, page]);

  const handleOrgData = async () => {
    const OrgData = await api("GET", `organization/cvsdvd654646a`, null, true);
    setOrgData(OrgData);
  };

  const fetchData = async () => {
    if (user.role === "superadmin") {
      setLoader(true);
      try {
        const payload = {
          search: [
            { key: "organization.isActive", value: true, comparison: "=" }
          ],
          sort: { key: "organization.createdAt", order: "ASC" },
          skip: page * rowsPerPage
        };
        const organization = await api(
          "POST",
          "organization/find",
          payload,
          true
        );
        const res = organization;
        setOrganizationList(res);
        setLoader(false);
      } catch (error) {
        if (error.response && error.response.data) {
          dispatch(
            toastAction({
                status: true,
                message: error.response.data.message,
                type: "error"
            })
        )
          setError(error.response.data);
        }
        setLoader(false);
      }
    } else {
      setLoader(true);
      try {
        const payload = {
          search: [
            { key: "organization.isActive", value: true, comparison: "=" }
          ],
          sort: { key: "organization.createdAt", order: "ASC" },
          skip: page * rowsPerPage,
          take: rowsPerPage
        };
        const organization = await api(
          "POST",
          "organization/find",
          payload,
          true
        );
        const res = organization;
        setOrganizationList(res);
        setLoader(false);
      } catch (error) {
        if (error.response && error.response.data) {
          dispatch(
            toastAction({
                status: true,
                message: error.response.data.message,
                type: "error"
            })
        )
          setError(error.response.data);
        }
        setLoader(false);
      }
    }
  };
  const fetchInactiveData = async () => {
    setLoader(true);
    try {
      const payload = {
        search: [
          { key: "organization.isActive", value: false, comparison: "=" }
        ],
        sort: { key: "organization.createdAt", order: "ASC" },
        skip: page * rowsPerPage,
        take: rowsPerPage
      };
      const organization = await api(
        "POST",
        "organization/find",
        payload,
        true
      );
      const res = organization;
      setBuilding(res);
      setOrganizationInactiveList(res);
      setLoader(false);
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(
          toastAction({
              status: true,
              message: error.response.data.message,
              type: "error"
          })
      )
        setError(error.response.data);
      }
      setLoader(false);
    }
  };

  const fetchRoomList = async () =>
  {
    try{
      const res = await api(
        "POST",
        "room/find",
        {
          search: [],
          sort: { key: "room.createdAt", order: "ASC" }
        },
        true
      );
      let availablerooms = res.filter(rooms => rooms.isActive && rooms.premiseType === "meetingRoom");
      setRoomList(availablerooms);
    }
    catch(error)
    {
      if (error.response && error.response.data) {
        dispatch(
          toastAction({
              status: true,
              message: error.response.data.message,
              type: "error"
          })
      )
        setError(error.response.data);
      }
      // setLoader(false);
    }
  }


  const handleViewDialog = (url, type) => {
    setViewUrl(url);
    setViewType(type);
    // setViewOpen(true);
  };

  const handleUploadDalog = (title, key, type, flag) => {
    setDropzone({
      title,
      key,
      type,
      flag
    });
    setDropzoneOpen(true);
  };

  const handleUpdateIdentity = async files => {
    dispatch(
      toastAction({
        status: true,
        message: "Updating identity..",
        type: "info",
        noIcon: true
      })
    );
    await api(
      "PATCH",
      "business",
      {
        name: businessDetails.name
      },
      true
    );
    dispatch(businessDispatch({ ...business, name: businessDetails.name }));
    dispatch(
      toastAction({
        status: true,
        message: "Identity updated",
        type: "success"
      })
    );
  };
  const handleUpload = async files => {
    dispatch(
      toastAction({
        status: true,
        message: "Uploading file ... ",
        type: "info",
        noIcon: true
      })
    );
    const url = await upload(files[0], "businessLogo");
    dispatch(
      toastAction({
        status: true,
        message: "Updating identity..",
        type: "info",
        noIcon: true
      })
    );
    await api(
      "PATCH",
      "business",
      {
        logo: url
      },
      true
    );
    dispatch(businessDispatch({ ...business, logo: url }));
    setDropzoneOpen(false);
    setBusinessDetails({
      ...businessDetails,
      logo: url
    });
    dispatch(
      toastAction({
        status: true,
        message: "Identity updated",
        type: "success"
      })
    );
  };

  const upload = async (file, folder) => {
    const token = localStorage ? localStorage.getItem("token") : null;
    let filenameSplit = file.name.split(".");
    const formData = new FormData();
    formData.append(
      "file",
      file,
      Date.now() + "." + filenameSplit[filenameSplit.length - 1]
    );
    formData.append("fileStorage", folder);

    try {
      let response = await axios({
        method: "POST",
        url: baseurl + "file/upload",
        data: formData,
        headers: {
          Authorization: "Bearer " + token
        },
        onUploadProgress: progressEvent => {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          dispatch(
            toastAction({
              status: true,
              message: "Uploading file ... " + percentCompleted + "%",
              type: "info",
              noIcon: true
            })
          );
        }
      });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const editOrganizations = data => {
    if (data.countrycode === "IN") {
      data.country = "India";
      data.isAdEnabled = org?.isAdEnabled;
      data.defaultPhoneCountryCode="+91"
      // console.log("data",data);
      setData(data);
      setOrgDetails(data);
      setIsOpen(true);
    } else {
      data.country = "United Kingdom";
      data.isAdEnabled = org?.isAdEnabled;
      data.defaultPhoneCountryCode="+44"
      setData(data);
      setOrgDetails(data);
      setIsOpen(true);
    }
  };

  const editOrganizationsToArray = item => {
    fetchData();
  };

  const addOrganizationsToArray = item => {
    fetchData();
  };

  const handleModal = () => {
    setData({});
    setIsOpen(true);
  };

  const handleActiveModel = () => {
    setData({});
  };

  const handleInactiveModel = () => {
    setData({});
  };

  const deleteOrganization = async () => {
    setDeleteLoader(true);
    try {
      const organization = await api(
        "PATCH",
        `organization/${activeData.id}`,
        { isActive: false },
        true
      );
      if (organization.id) {
        organizationCount();
        fetchData();
        setOpen(false);
        setError("");
      }
      setDeleteLoader(false);
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(
          toastAction({
              status: true,
              message: error.response.data.message,
              type: "error"
          })
      )
        setError(error.response.data);
      }
      setDeleteLoader(false);
      setError("Some things went wrong please try again");
    }
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      user.role !== "businessAdmin" &&
        (await api(
          "PATCH",
          "organization/settings/" + user.organizationId,
          {
            defaultMeetingRoom: newOrgSettings.defaultMeetingRoom,
            meetingKeywords: newOrgSettings.meetingKeywords,
            outLookTimeOverlap: newOrgSettings.outLookTimeOverlap,
            meetingRoomManagement: newOrgSettings.meetingRoomManagement,
            meetingRoomRelease: newOrgSettings.meetingRoomRelease,
            meetingRoomReleaseTime: newOrgSettings.meetingRoomReleaseTime,
            outlookIntegration: newOrgSettings.outlookIntegration,
            googleIntegration: newOrgSettings.googleIntegration,
            salesforceIntegration: newOrgSettings.salesforceIntegration,
            isGoogleSyncOnlineMeeting: newOrgSettings.isGoogleSyncOnlineMeeting,
            isGoogleSyncMeetingWithSelf:
              newOrgSettings.isGoogleSyncMeetingWithSelf,
            isOutlookSyncOnlineMeeting:
              newOrgSettings.isOutlookSyncOnlineMeeting,
            isOutlookSyncMeetingWithSelf:
              newOrgSettings.isOutlookSyncMeetingWithSelf,
            adEnable: newOrgSettings.adEnable,
            adUserUpdateSendEmail: newOrgSettings.adUserUpdateSendEmail,
            adUsersDefaultBuildingId: newOrgSettings.adUsersDefaultBuildingId,
            editMeetingUser: newOrgSettings.editMeetingUser,
            trailingMeetingEnabledOrg: newOrgSettings.trailingMeetingEnabledOrg,
            trailingMeetingAllowOverlap:newOrgSettings.trailingMeetingAllowOverlap,
            trailingMeetingSkipOnOverlap:newOrgSettings.trailingMeetingSkipOnOverlap,
            trailingMeetingDurationMinutes: newOrgSettings.trailingMeetingDurationMinutes,
            outlookTenantId:newOrgSettings.outlookTenantId
          },
          true
        ));

      user.role === "businessAdmin" &&
        (await api(
          "PATCH",
          "business/settings/" + user.businessId,
          {
            outlookBusinesIntegration: newOrgSettings.outlookBusinesIntegration,
            googleBusinesIntegration: newOrgSettings.googleBusinesIntegration,
            businessmeetingRoomManagement:
              newOrgSettings.businessmeetingRoomManagement,
            businessPantryManagement: newOrgSettings.businessPantryManagement,
            salesforceBusinesIntegration:
              newOrgSettings.salesforceBusinesIntegration,
            trailingMeetingEnabled: newOrgSettings.trailingMeetingEnabled
          },
          true
        ));
      await fetchData();
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(
          toastAction({
              status: true,
              message: error.response.data.message,
              type: "error"
          })
      )
        setError(error.response.data);
      }
      captureException(error);
    }
  };

  const restoreOrganization = async () => {
    setRestoreLoader(true);
    try {
      const organization = await api(
        "PATCH",
        `organization/${activeData.id}`,
        { isActive: true },
        true
      );
      if (organization.id) {
        organizationCount();
        fetchData();
        setrestoreOpen(false);
        setError("");
      }
      setRestoreLoader(false);
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(
          toastAction({
              status: true,
              message: error.response.data.message,
              type: "error"
          })
      )
        setError(error.response.data);
      }
      setRestoreLoader(false);
      setError("Some things went wrong please try again");
    }
  };

  const conformRoomModal = row => {
    if (row && row.id) {
      setOpen(true);
      setData(row);
    } else {
      setData({});
      setOpen(false);
    }
  };
  const conformOrgRestore = row => {
    if (row && row.id) {
      setrestoreOpen(true);
      setData(row);
    } else {
      setData({});
      setrestoreOpen(false);
    }
  };

  useEffect(() => {
    (async () => {
      let data = await api("GET", "quickSettings/details", null, true);
      let ExchangeDetails = await api(
        "GET",
        "quickSettings/exchangeId",
        null,
        true
      );
      setCountryDetails(data.data);
      setExchangeDetail(ExchangeDetails.data);
    })();
  }, []); 

  useEffect(()=>{
    if(org?.exchangeId === 2 && newOrgSettings.outlookTenantId !== null)
    {
      setNewOrgSettings({
        ...newOrgSettings,
        outlookIntegration: true
      });
    }
    // if(org?.exchangeId === 2 && newOrgSettings.outlookTenantId === null && newOrgSettings.outlookIntegration)
    // {
    //   setNewOrgSettings({
    //     ...newOrgSettings,
    //     outlookIntegration: false
    //   });
    // }
  },[newOrgSettings.outlookTenantId])

  useEffect(()=>{
    if(org?.isAdEnabled === true)
    {
      setNewOrgSettings({
        ...newOrgSettings,
        adEnable: true
      });
    }
    else
    {
      setNewOrgSettings({
        ...newOrgSettings,
        adEnable: false
      });
    }
  },[newOrgSettings.adEnable])


  useEffect(()=>{
    let defRoom = roomList.find(
      item => item.id === newOrgSettings.defaultMeetingRoom
    )
    setDefaultRoomName(defRoom);
  },[roomList,newOrgSettings.defaultMeetingRoom])




  // console.log("defRoom", defaultRoomName);
  // console.log("newOrgSettings.defaultMeetingRoom", newOrgSettings.defaultMeetingRoom);

  const DialogButtons = (
    <React.Fragment>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => conformRoomModal()}
      >
        NO
      </Button>
      <Button
        variant="contained"
        color="primary"
        disabled={isLoadingDelete}
        onClick={() => deleteOrganization()}
      >
        {isLoadingDelete ? "Loading..." : "Yes, delete"}
      </Button>
    </React.Fragment>
  );
  const RestoreDialogButtons = (
    <React.Fragment>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => conformOrgRestore()}
      >
        NO
      </Button>
      <Button
        variant="contained"
        color="primary"
        disabled={isLoadingRestore}
        onClick={() => restoreOrganization()}
      >
        {isLoadingRestore ? "Loading..." : "Yes, Restore"}
      </Button>
    </React.Fragment>
  );

  if (isLoading) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={4} />
        <Grid item xs={4}>
          <Loader />
        </Grid>
        <Grid item xs={4} />
      </Grid>
    );
  }
  if (isInactiveOpen) {
    return (
      <IsActiveModal
        open={isInactiveOpen}
        activeData={activeData}
        organizationCount={organizationCount}
        addOrganizationsToArray={addOrganizationsToArray}
        editOrganizationsToArray={editOrganizationsToArray}
        close={() => setInActiveOpen(false)}
      />
    );
  }

  if (isOpen) {
    return (
      <OrganizationModal
        open={isOpen}
        organizationCount={organizationCount}
        activeData={activeData}
        orgDetails={orgDetails}
        addOrganizationsToArray={addOrganizationsToArray}
        editOrganizationsToArray={editOrganizationsToArray}
        countryDetails={countryDetails}
        exchangeDetail={exchangeDetail}
        userExchangeData={orgData}
        close={() => setIsOpen(false)}
      />
    );
  }
  return (
    <React.Fragment>
      {user.role !== "superadmin" ? (
        <>
          <Grid
            alignItems="center"
            container
            justify="flex-end"
            spacing={3}
            direction="row"
          >
            <Grid item>
              <Button
                style={{ marginRight: 15, color: "blue" }}
                color="primary"
                className={classes.button}
                variant="outlined"
                onClick={handleActiveModel}
              >
                Active
              </Button>
              <Button
                style={{ color: "red" }}
                color="primary"
                className={classes.button}
                variant="outlined"
                onClick={() => {
                  handleInactiveModel();
                  setIsActive(true);
                  setInActiveOpen(true);
                }}
              >
                InActive
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                className={classes.button}
                startIcon={<PlusCircle />}
                variant="outlined"
                onClick={handleModal}
              >
                Add Organization
              </Button>
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Logo</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isActive
                  ? organizationList.map((row, ind) => (
                      <React.Fragment key={ind}>
                        <TableRow>
                          <TableCell align="left">
                            <img
                              src={row.logo}
                              style={{
                                backgroundColor: "#fff",
                                height: 50
                              }}
                            />
                          </TableCell>
                          <TableCell align="left">{row.name || "-"}</TableCell>
                          <TableCell align="center">
                            {row.isActive ? "Active" : "Inactive"}
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                              aria-label="edit"
                              onClick={() => {
                                editOrganizations(row);
                                handleOrgData();
                              }}
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                            {row.isActive ? (
                              <IconButton
                                aria-label="delete"
                                onClick={() => conformRoomModal(row)}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            ) : (
                              <IconButton
                                aria-label="restore"
                                onClick={() => conformOrgRestore(row)}
                              >
                                <RestoreIcon fontSize="small" />
                              </IconButton>
                            )}
                            <IconButton
                              aria-label="edit"
                              onClick={() => {
                                setViewOrganization(
                                  viewOrganization.map((vitem, vindex) => {
                                    if (vindex === ind) {
                                      return !vitem;
                                    } else {
                                      return vitem;
                                    }
                                  })
                                );
                              }}
                            >
                              {!viewOrganization[ind] && (
                                <ExpandMoreIcon fontSize="small" />
                              )}
                              {viewOrganization[ind] && (
                                <ExpandLessIcon fontSize="small" />
                              )}
                            </IconButton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ padding: 0 }} colSpan={4}>
                            <Collapse
                              in={viewOrganization[ind]}
                              style={{ display: "block" }}
                            >
                              <Box margin={3}>
                                <Typography variant="h4">{row.name}</Typography>
                                <Typography variant="h5">
                                  Buildings and Floors
                                </Typography>
                                <List>
                                  {row.building.filter(f=>f.isActive).map((bitem, bindex) => (
                                    <ListItem
                                      style={{ paddingLeft: 0 }}
                                      key={bindex}
                                    >
                                      <ListItemText
                                        primary={
                                          floorList.find(
                                            floor => floor.id === bitem.floorId
                                          )?.name
                                        }
                                        secondary={
                                          floorList.find(
                                            floor => floor.id === bitem.floorId
                                          )?.building?.name
                                        }
                                      />
                                    </ListItem>
                                  ))}
                                </List>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))
                  : organizationInactiveList.map((row, ind) => (
                      <React.Fragment key={ind}>
                        <TableRow>
                          <TableCell align="left">{row.name || "-"}</TableCell>
                          <TableCell align="left">
                            <img
                              src={row.logo}
                              style={{
                                backgroundColor: "#fff",
                                height: 50
                              }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            {row.isActive ? "Active" : "Inactive"}
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                              aria-label="edit"
                              onClick={() => editOrganizations(row)}
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                            {row.isActive ? (
                              <IconButton
                                aria-label="delete"
                                onClick={() => conformRoomModal(row)}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            ) : (
                              <IconButton
                                aria-label="restore"
                                onClick={() => conformOrgRestore(row)}
                              >
                                <RestoreIcon fontSize="small" />
                              </IconButton>
                            )}
                            <IconButton
                              aria-label="edit"
                              onClick={() => {
                                setViewOrganization(
                                  viewOrganization.map((vitem, vindex) => {
                                    if (vindex === ind) {
                                      return !vitem;
                                    } else {
                                      return vitem;
                                    }
                                  })
                                );
                              }}
                            >
                              {!viewOrganization[ind] && (
                                <ExpandMoreIcon fontSize="small" />
                              )}
                              {viewOrganization[ind] && (
                                <ExpandLessIcon fontSize="small" />
                              )}
                            </IconButton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ padding: 0 }} colSpan={4}>
                            <Collapse
                              in={viewOrganization[ind]}
                              style={{ display: "block" }}
                            >
                              <Box margin={3}>
                                <Typography variant="h4">{row.name}</Typography>
                                <Typography variant="h5">
                                  Buildings and Floors
                                </Typography>
                                <List>
                                  {row.building.filter(f=>f.isActive).map((bitem, bindex) => (
                                    <ListItem
                                      style={{ paddingLeft: 0 }}
                                      key={bindex}
                                    >
                                      <ListItemText
                                        primary={
                                          floorList.filter(f=>f.organization.isActive).find(
                                            floor => floor.id === bitem.floorId
                                          )?.name
                                        }
                                        secondary={
                                          floorList.find(
                                            floor => floor.id === bitem.floorId
                                          )?.building?.name
                                        }
                                      />
                                    </ListItem>
                                  ))}
                                </List>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            component="div"
            count={countData}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </>
      ) : (
        <>
          <br />
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Logo</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {organizationList
                  .filter(ele => ele.id === user.organizationId)
                  .map((row, ind) => (
                    <React.Fragment key={ind}>
                      <TableRow>
                        <TableCell align="left">
                          <img
                            src={row.logo}
                            style={{
                              backgroundColor: "#fff",
                              height: 50
                            }}
                          />
                        </TableCell>
                        <TableCell align="left">{row.name || "-"}</TableCell>
                        <TableCell align="center">
                          {row.isActive ? "Active" : "Inactive"}
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            aria-label="edit"
                            onClick={() => editOrganizations(row)}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                          {/* {row.isActive ? (
                      <IconButton
                        aria-label="delete"
                        onClick={() => conformRoomModal(row)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    ) : (
                      <IconButton
                        aria-label="restore"
                        onClick={() => conformOrgRestore(row)}
                      >
                        <RestoreIcon fontSize="small" />
                      </IconButton>
                    )} */}
                          <IconButton
                            aria-label="edit"
                            onClick={() => {
                              setViewOrganization(
                                viewOrganization.map((vitem, vindex) => {
                                  if (vindex === ind) {
                                    return !vitem;
                                  } else {
                                    return vitem;
                                  }
                                })
                              );
                            }}
                          >
                            {!viewOrganization[ind] && (
                              <ExpandMoreIcon fontSize="small" />
                            )}
                            {viewOrganization[ind] && (
                              <ExpandLessIcon fontSize="small" />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ padding: 0 }} colSpan={4}>
                          <Collapse
                            in={viewOrganization[ind]}
                            style={{ display: "block" }}
                          >
                            <Box margin={3}>
                              <Grid container spacing={2}>
                                <Grid item xs={2}>
                                  {/* <Typography variant="h4">{row.name}</Typography> */}
                                  <Typography variant="h5">
                                    Buildings and Floors
                                  </Typography>
                                  <List>
                                    {row.building.map((bitem, bindex) => (
                                      <ListItem
                                        style={{ paddingLeft: 0 }}
                                        key={bindex}
                                      >
                                        <ListItemText
                                          primary={
                                            floorList.find(
                                              floor =>
                                                floor.id === bitem.floorId && floor.isActive
                                            )?.name
                                          }
                                          secondary={
                                            floorList.find(
                                              floor =>
                                                floor.id === bitem.floorId && floor.isActive
                                            )?.building?.name
                                          }
                                        />
                                      </ListItem>
                                    ))}
                                  </List>
                                </Grid>
                                <Grid item xs={2}>
                                  <Typography variant="h5">TimeZone</Typography>
                                  <List>
                                    {building
                                      .filter(
                                        ele => ele.id === user.organizationId
                                      )
                                      .map(
                                        (bitem, bindex) => bitem.timezoneName
                                      )}
                                  </List>
                                </Grid>
                                <Grid item xs={2}>
                                  <Typography variant="h5">TimeZone</Typography>
                                  <List>
                                    {building
                                      .filter(
                                        ele => ele.id === user.organizationId
                                      )
                                      .map((bitem, bindex) => bitem.timezone)}
                                  </List>
                                </Grid>
                                <Grid item xs={2}>
                                  <Typography variant="h5">TimeZone</Typography>
                                  <List>
                                    {building
                                      .filter(
                                        ele => ele.id === user.organizationId
                                      )
                                      .map(
                                        (bitem, bindex) => bitem.countrycode
                                      )}
                                  </List>
                                </Grid>
                                <Grid item xs={2}>
                                  <Typography variant="h5">
                                    ExchangeId
                                  </Typography>
                                  <List>
                                    {/* {building.filter((ele)=>ele.id===user.organizationId).map((bitem, bindex) => (
                                bitem.countrycode
                              ))} */}
                                    {org.exchangeId === 1
                                      ? "None"
                                      : org.exchangeId === 2
                                      ? "Microsoft Outlook"
                                      : org.exchangeId === 3
                                      ? "Google Workspace"
                                      : null}
                                  </List>
                                </Grid>
                                {/* </Grid> */}
                              </Grid>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br />
          
            <form onSubmit={handleSubmit}>
              <Grid container direction="row" spacing={3}>
                <Grid item sm={6} md={6}>
                  <Card>
                    <CardContent>
                {user.role !== "businessAdmin" && (
                <>
                <Typography color="textPrimary" variant="h6">
                  Default Meeting Room
                </Typography>

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        newOrgSettings.defaultMeetingRoom !== null ||
                        defaultMeetingRoom
                      }
                      onChange={e => {
                        setDefaultMeetingRoom(e.target.checked);
                        if (!e.target.checked)
                          setNewOrgSettings({
                            ...newOrgSettings,
                            defaultMeetingRoom: null
                          });
                      }}
                      name="defaultMeetingRoom"
                    />
                  }
                  label="Enable"
                />
                {/* <br/> */}

                {(newOrgSettings.defaultMeetingRoom !== null ||
                  defaultMeetingRoom) && !defaultRoomName &&
                  (
                    <>
                  <Autocomplete
                    id="combo-box-demo"
                    options={roomList}
                    getOptionLabel={option =>
                      option.name + ", " + option.building.name
                    }
                    onChange={(event, value) => {
                      setNewOrgSettings({
                        ...newOrgSettings,
                        defaultMeetingRoom: value.id,
                      });
                    }}
                    defaultValue={roomList.find(
                      item => item.id === newOrgSettings.defaultMeetingRoom
                    )}
                    disableClearable
                    className={classes.formSelect}
                    renderInput={params => (
                      <TextField {...params} fullWidth variant="outlined" />
                    )}
                  />
                  <br/>
                  </>
                )}
                {(newOrgSettings.defaultMeetingRoom !== null ||
                  defaultMeetingRoom) && defaultRoomName
                  && (
                    <>
                  <Autocomplete
                    id="combo-box-demo"
                    options={roomList}
                    getOptionLabel={option =>
                      option.name + ", " + option.building.name
                    }
                    onChange={(event, value) => {
                      setNewOrgSettings({
                        ...newOrgSettings,
                        defaultMeetingRoom: value.id,
                      });
                    }}
                    defaultValue={{name:defaultRoomName?.name,
                      building:defaultRoomName?.building}}
                    disableClearable
                    className={classes.formSelect}
                    renderInput={params => (
                      <TextField {...params} fullWidth variant="outlined" />
                    )}
                  />
                  <br/>
                  </>
                )}
                         
                         {user.role !== "businessAdmin" &&
                        newOrgSettings.businessmeetingRoomManagement && (
                          <>
                                <br />
                                <br />
                               <Typography
                                  color="textPrimary"
                                  gutterBottom
                                  variant="h5"
                                >
                                  Release meeting room if visitor doesn't check
                                  in
                                </Typography>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        newOrgSettings.meetingRoomRelease
                                      }
                                      onChange={e => {
                                        setNewOrgSettings({
                                          ...newOrgSettings,
                                          meetingRoomRelease: e.target.checked
                                        });
                                      }}
                                      name="Outlook"
                                    />
                                  }
                                  label="Enable"
                                />
                                {newOrgSettings.meetingRoomRelease && (
                                  <TextField
                                    label="Meeting room release waiting time in (minutes)"
                                    fullWidth
                                    variant="outlined"
                                    type="number"
                                    margin="normal"
                                    value={
                                      newOrgSettings.meetingRoomReleaseTime
                                    }
                                    onChange={e => {
                                      setNewOrgSettings({
                                        ...newOrgSettings,
                                        meetingRoomReleaseTime: e.target.value
                                      });
                                    }}
                                  />
                                )}
                                </>
                        )}
                
                         {newOrgSettings.trailingMeetingEnabled && (
                            <>
                                <br />
                                <br />
                            <Typography
                              color="textPrimary"
                              gutterBottom
                              variant="h5"
                            >
                              Post Meetings(A short span meeting just before next meeting starts for housekeeping )--Experimental
                            </Typography>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    newOrgSettings.trailingMeetingEnabledOrg
                                  }
                                  onChange={handleChange}
                                  name="trailingMeetingEnabledOrg"
                                />
                              }
                              label="Enable"
                            />
                            {newOrgSettings.trailingMeetingEnabledOrg && (
                              <>
                                <br />
                                <br />
                                <FormControl component="fieldset">
                                  <Typography
                                    color="textPrimary"
                                    gutterBottom
                                    variant="body2"
                                  >
                                    In case of post meeting overlapping
                                  </Typography>
                                  <FormGroup row>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={
                                            newOrgSettings.trailingMeetingAllowOverlap
                                          }
                                          onChange={e => {
                                            let update = {
                                              trailingMeetingAllowOverlap:
                                                e.target.checked
                                            };
                                            if (e.target.checked) {
                                              update = {
                                                ...update,
                                                trailingMeetingSkipOnOverlap: false
                                              };
                                            }
                                            setNewOrgSettings({
                                              ...newOrgSettings,
                                              ...update
                                            });
                                          }}
                                          name="trailingMeetingAllowOverlap"
                                        />
                                      }
                                      label="Allow overlapping"
                                    />
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={
                                            newOrgSettings.trailingMeetingSkipOnOverlap
                                          }
                                          onChange={e => {
                                            let update = {
                                              trailingMeetingSkipOnOverlap:
                                                e.target.checked
                                            };
                                            if (e.target.checked) {
                                              update = {
                                                ...update,
                                                trailingMeetingAllowOverlap: false
                                              };
                                            }
                                            setNewOrgSettings({
                                              ...newOrgSettings,
                                              ...update
                                            });
                                          }}
                                          name="trailingMeetingSkipOnOverlap"
                                        />
                                      }
                                      label="Skip post meeting"
                                    />
                                  </FormGroup>
                                </FormControl>

                                <TextField
                                  label="Post Meeting Duration (in minutes)"
                                  fullWidth
                                  variant="outlined"
                                  type="number"
                                  margin="normal"
                                  name="trailingMeetingDurationMinutes"
                                  onChange={e =>
                                    setNewOrgSettings({
                                      ...newOrgSettings,
                                      trailingMeetingDurationMinutes:
                                        e.target.value
                                    })
                                  }
                                  value={
                                    newOrgSettings.trailingMeetingDurationMinutes
                                  }
                                />
                              </>
                            )}
                          </>
                        )}
                        <br/>
                       <br/>
                       </>
                       )}
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="h5"
                        >
                          Calendar Integration:
                        </Typography>
             
                      {org.exchangeId === 1 && 
                        user.role !== "businessAdmin" &&(
                          <Typography
                          color="error"
                          gutterBottom
                          variant="h5"
                        >
                          None is selected in Exchange Details
                        </Typography>
                        )}
                      {org.exchangeId === 2 &&
                        user.role !== "businessAdmin" && 
                         (
                          <>
                            <FormControl component="fieldset">
                              <FormGroup row>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                     disabled={newOrgSettings.outlookTenantId === null && !newOrgSettings.outlookIntegration}
                                      checked={
                                        newOrgSettings.outlookIntegration || newOrgSettings.outlookTenantId !== null
                                      }
                                      onChange={e => {
                                        if(newOrgSettings.outlookIntegration)
                                        {
                                          setNewOrgSettings(
                                            newOrgSettings.
                                            outlookTenantId = null
                                        )
                                        }
                                          setNewOrgSettings({
                                            ...newOrgSettings,
                                            outlookIntegration: e.target.checked
                                          });
                                      }}
                                      // name="Outlook"
                                    />
                                  }
                                />
                                <Button
                                  size="large"
                                  disabled={newOrgSettings.outlookTenantId != null || newOrgSettings.outlookIntegration}
                                  variant="contained"
                                  startIcon={
                                    <img
                                      src="https://i.ibb.co/dtCr25t/200px-Microsoft-Office-logo-2019-present-svg.png"
                                      alt="o365"
                                      style={{ height: 20 }}
                                    />
                                  }
                                  style={{ maxWidth: 156 }}
                                  href={`https://login.microsoftonline.com/common/adminconsent?client_id=bc5b9a9f-8f73-48a9-80b6-6a8ce4d8e622&state=${
                                    user.organizationId
                                  }&redirect_uri=${
                                    process.env.REACT_APP_ENV.trim() === "prod"
                                      ? "https://xelh4x5hi3.execute-api.us-east-1.amazonaws.com/"
                                      : "https://rrp7ixj615.execute-api.us-east-1.amazonaws.com"
                                  }`}
                                >
                                  Office 365
                                </Button>
                              </FormGroup>
                              <br />
                            </FormControl>
                            {newOrgSettings.outlookIntegration && (
                              <TextField
                                label="Meeting sync criteria (Subject keywords)"
                                fullWidth
                                variant="outlined"
                                type="text"
                                margin="normal"
                                value={newOrgSettings.meetingKeywords}
                                onChange={e => {
                                  setNewOrgSettings({
                                    ...newOrgSettings,
                                    meetingKeywords: e.target.value
                                  });
                                }}
                              />
                            )}
                      {newOrgSettings.outlookIntegration &&
                        user.role !== "businessAdmin" && (
                          <>
                            <FormGroup row>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  ml: 3
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        newOrgSettings.isOutlookSyncOnlineMeeting
                                      }
                                      onChange={e => {
                                        setNewOrgSettings({
                                          ...newOrgSettings,
                                          isOutlookSyncOnlineMeeting:
                                            e.target.checked
                                        });
                                      }}
                                    />
                                  }
                                  label="Sync Online Meeting"
                                />
                              </Box>
                            </FormGroup>
                            <FormGroup row>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  ml: 3
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        newOrgSettings.isOutlookSyncMeetingWithSelf
                                      }
                                      onChange={e => {
                                        setNewOrgSettings({
                                          ...newOrgSettings,
                                          isOutlookSyncMeetingWithSelf:
                                            e.target.checked
                                        });
                                      }}
                                    />
                                  }
                                  label="Sync Meeting With Self"
                                />
                                <br />
                                <br />
                              </Box>
                            </FormGroup>
                          </>
                        )}
                        </>
                        )}

                      {org.exchangeId === 3 &&
                        user.role !== "businessAdmin" && (
                          <>
                            <FormControl component="fieldset">
                              <FormGroup row>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={newOrgSettings.googleIntegration}
                                      onChange={e => {
                                        // !e.target.checked &&
                                          setNewOrgSettings({
                                            ...newOrgSettings,
                                            googleIntegration: e.target.checked
                                          });
                                      }}
                                      name="Outlook"
                                    />
                                  }
                                />
                                <Button
                                  size="large"
                                  disabled={newOrgSettings.googleIntegration}
                                  variant="contained"
                                  startIcon={
                                    <img
                                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/2048px-Google_%22G%22_Logo.svg.png"
                                      alt="Google Integration"
                                      style={{ height: 20 }}
                                    />
                                  }
                                  style={{ maxWidth: 240 }}
                                  href={`https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?redirect_uri=${window.location.origin}/settings/integration/google&prompt=consent&response_type=code&client_id=75007441741-fnj34l7ohcc8cq022bbbagpvpejmkuob.apps.googleusercontent.com&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fadmin.directory.resource.calendar&access_type=offline&flowName=GeneralOAuthFlow`}
                                  target="_blank"
                                >
                                  Google Workspace
                                </Button>
                              </FormGroup>
                              <br />
                            </FormControl>
                      {newOrgSettings.googleIntegration &&
                        user.role !== "businessAdmin" && (
                          <>
                            <FormGroup row>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  ml: 3
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        newOrgSettings.isGoogleSyncOnlineMeeting
                                      }
                                      onChange={e => {
                                        setNewOrgSettings({
                                          ...newOrgSettings,
                                          isGoogleSyncOnlineMeeting:
                                            e.target.checked
                                        });
                                      }}
                                    />
                                  }
                                  label="Sync Online Meeting"
                                />
                              </Box>
                            </FormGroup>
                            <FormGroup row>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  ml: 3
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        newOrgSettings.isGoogleSyncMeetingWithSelf
                                      }
                                      onChange={e => {
                                        setNewOrgSettings({
                                          ...newOrgSettings,
                                          isGoogleSyncMeetingWithSelf:
                                            e.target.checked
                                        });
                                      }}
                                    />
                                  }
                                  label="Sync Meeting With Self"
                                />
                              </Box>
                            </FormGroup>
                          </>
                        )}
                      </>
                        )}
                      {org.exchangeId === 2 &&
                      org?.isAdEnabled &&
                        newOrgSettings.outlookIntegration &&
                        user.role !== "businessAdmin" && (
                          <>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                ml: 3
                              }}
                            >
                              <Typography
                                color="textPrimary"
                                gutterBottom
                                variant="h5"
                              >
                                Employee Database Integration:
                              </Typography>
                              <FormControl component="fieldset">
                                <FormGroup row>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={newOrgSettings.adEnable && org.isAdEnabled}
                                        onChange={e => {
                                          setNewOrgSettings({
                                            ...newOrgSettings,
                                            adEnable: e.target.checked
                                          });
                                        }}
                                        name="Outlook"
                                      />
                                    }
                                  />
                                  <Button
                                    size="large"
                                    disabled
                                    variant="contained"
                                    startIcon={
                                      <img
                                        src="https://img.favpng.com/16/19/23/active-directory-microsoft-azure-lightweight-directory-access-protocol-user-png-favpng-7zuNDnk3SdDHQdUG2etjZYWr2.jpg"
                                        alt="Active Directory"
                                        style={{ height: 20 }}
                                      />
                                    }
                                    style={{ maxWidth: 360 }}
                                    href={`https://google.com`}
                                    target="_blank"
                                  >
                                    Active Directory
                                  </Button>
                                </FormGroup>
                              </FormControl>
                              <br />
                              <br />
                            </Box>
                          </>
                        )}
                      {org.exchangeId === 2 &&
                      org?.isAdEnabled &&
                      newOrgSettings.adEnable &&
                        newOrgSettings.outlookIntegration &&
                        user.role !== "businessAdmin" && (
                          <>
                            <FormGroup row>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  ml: 5
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        newOrgSettings.adUserUpdateSendEmail
                                      }
                                      onChange={e => {
                                        setNewOrgSettings({
                                          ...newOrgSettings,
                                          adUserUpdateSendEmail:
                                            e.target.checked
                                        });
                                      }}
                                    />
                                  }
                                  label="Send Notification on Profile Update"
                                />
                              </Box>
                            </FormGroup>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                ml: 5
                              }}
                            >
                              <FormControl
                                required
                                variant="standard"
                                style={{ width: "100%" }}
                              >
                                <InputLabel id="building-Id">
                                  Building Id
                                </InputLabel>
                                <Select
                                  labelId="building"
                                  id="building"
                                  label="Building "
                                  value={
                                    newOrgSettings.adUsersDefaultBuildingId
                                  }
                                  onChange={e => {
                                    setNewOrgSettings({
                                      ...newOrgSettings,
                                      adUsersDefaultBuildingId: e.target.value
                                    });
                                  }}
                                  autoWidth
                                >
                                  {buildings.map(building => {
                                    return (
                                      <MenuItem
                                        value={building.id}
                                        key={building.id}
                                      >
                                        {building.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                              <br />
                              <br />
                            </Box>
                          </>
                        )}
                      {/* {user.role === "businessAdmin" && (
                        <>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h5"
                          >
                            Meeting Room Management
                          </Typography>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  newOrgSettings.businessmeetingRoomManagement
                                }
                                onChange={e => {
                                  setNewOrgSettings({
                                    ...newOrgSettings,
                                    businessmeetingRoomManagement:
                                      e.target.checked
                                  });
                                }}
                                name="Outlook"
                              />
                            }
                            label="Enable"
                          />
                        </>
                      )} */}
                      {/* {user.role !== "businessAdmin" &&
                        newOrgSettings.businessmeetingRoomManagement &&
                        (newOrgSettings.outlookIntegration || newOrgSettings.googleIntegration) && (
                              <>
                                <br />
                                <br />
                                <Typography
                                  color="textPrimary"
                                  gutterBottom
                                  variant="h5"
                                >
                                Overlap meeting room when booking from exchange
                                </Typography>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        newOrgSettings.outLookTimeOverlap
                                      }
                                      onChange={e => {
                                        setNewOrgSettings({
                                          ...newOrgSettings,
                                          outLookTimeOverlap: e.target.checked
                                        });
                                      }}
                                      name="Outlook"
                                    />
                                  }
                                  label="Enable"
                                />
                          </>
                        )} */}
                      {/* <br />
                      <br /> */}
                      {/* {user.role === "businessAdmin" && (
                        <>
                          <Typography
                            color="textPrimary"
                            gutterBottom
                            variant="h5"
                          >
                            Pantry Management
                          </Typography>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  newOrgSettings.businessPantryManagement
                                }
                                onChange={e => {
                                  setNewOrgSettings({
                                    ...newOrgSettings,
                                    businessPantryManagement: e.target.checked
                                  });
                                }}
                                name="Outlook"
                              />
                            }
                            label="Enable"
                          />
                        </>
                      )} */}
                    </CardContent>
                  </Card>
                </Grid>
                <Grid className={classes.item} item sm={6} md={6}>
                  {newOrgSettings.editMeetingUser && (
                    <Card>
                      <CardContent>
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="h4"
                        >
                          Meeting Settings
                        </Typography>
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="h6"
                        >
                          Users allowed to edit a meeting
                        </Typography>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={JSON.parse(
                                  newOrgSettings.editMeetingUser
                                ).includes("organizer")}
                                onChange={e => {
                                  e.target.checked &&
                                    setNewOrgSettings({
                                      ...newOrgSettings,
                                      editMeetingUser: JSON.stringify([
                                        ...JSON.parse(
                                          newOrgSettings.editMeetingUser
                                        ),
                                        "organizer"
                                      ])
                                    });
                                  !e.target.checked &&
                                    setNewOrgSettings({
                                      ...newOrgSettings,
                                      editMeetingUser: JSON.stringify(
                                        JSON.parse(
                                          newOrgSettings.editMeetingUser
                                        ).filter(item => item !== "organizer")
                                      )
                                    });
                                }}
                              />
                            }
                            label="Organizer"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={JSON.parse(
                                  newOrgSettings.editMeetingUser
                                ).includes("employee")}
                                onChange={e => {
                                  e.target.checked &&
                                    setNewOrgSettings({
                                      ...newOrgSettings,
                                      editMeetingUser: JSON.stringify([
                                        ...JSON.parse(
                                          newOrgSettings.editMeetingUser
                                        ),
                                        "employee"
                                      ])
                                    });
                                  !e.target.checked &&
                                    setNewOrgSettings({
                                      ...newOrgSettings,
                                      editMeetingUser: JSON.stringify(
                                        JSON.parse(
                                          newOrgSettings.editMeetingUser
                                        ).filter(item => item !== "employee")
                                      )
                                    });
                                }}
                              />
                            }
                            label="Employee participants"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={JSON.parse(
                                  newOrgSettings.editMeetingUser
                                ).includes("visitor")}
                                onChange={e => {
                                  e.target.checked &&
                                    setNewOrgSettings({
                                      ...newOrgSettings,
                                      editMeetingUser: JSON.stringify([
                                        ...JSON.parse(
                                          newOrgSettings.editMeetingUser
                                        ),
                                        "visitor"
                                      ])
                                    });
                                  !e.target.checked &&
                                    setNewOrgSettings({
                                      ...newOrgSettings,
                                      editMeetingUser: JSON.stringify(
                                        JSON.parse(
                                          newOrgSettings.editMeetingUser
                                        ).filter(item => item !== "visitor")
                                      )
                                    });
                                }}
                              />
                            }
                            label="Visitor participants"
                          />

                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={JSON.parse(
                                  newOrgSettings.editMeetingUser
                                ).includes("admin")}
                                onChange={e => {
                                  e.target.checked &&
                                    setNewOrgSettings({
                                      ...newOrgSettings,
                                      editMeetingUser: JSON.stringify([
                                        ...JSON.parse(
                                          newOrgSettings.editMeetingUser
                                        ),
                                        "admin"
                                      ])
                                    });
                                  !e.target.checked &&
                                    setNewOrgSettings({
                                      ...newOrgSettings,
                                      editMeetingUser: JSON.stringify(
                                        JSON.parse(
                                          newOrgSettings.editMeetingUser
                                        ).filter(item => item !== "admin")
                                      )
                                    });
                                }}
                              />
                            }
                            label="Admin"
                          />
                        </FormGroup>
                      </CardContent>
                    </Card>
                  )}
                </Grid>
              </Grid>
              <br />
              <Card>
                <CardContent>
                  <Box display="flex" justifyContent="flex-end">
                    <Button color="primary" variant="contained" type="submit">
                      Update
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </form>
        </>
      )}
      {open && (
        <ConfirmationDialog
          open={open}
          error={error}
          handleClose={() => setOpen(false)}
          title={"Delete Organization"}
          content={"Are you sure you want to delete this organization?"}
          btnActions={DialogButtons}
        />
      )}
      {restoreopen && (
        <ConfirmationDialog
          open={restoreopen}
          error={error}
          handleClose={() => setrestoreOpen(false)}
          title={"Restore Organization"}
          content={"Are you sure you want to restore this organization?"}
          btnActions={RestoreDialogButtons}
        />
      )}
      <Dialog
        open={viewOpen}
        onClose={() => setViewOpen(false)}
        disableAutoFocus={true}
      >
        {viewType === "image" && (
          <img
            src={viewUrl}
            alt="preview"
            style={{ maxHeight: "90vh", maxWidth: "100%" }}
          />
        )}
        {viewType === "video" && (
          <video src={viewUrl} width="100%" height="100%" controls></video>
        )}
      </Dialog>

      <DropzoneDialog
        open={dropzoneOpen}
        onSave={files => {
          handleUpload(files);
        }}
        acceptedFiles={[dropzone.type + "/*"]}
        showPreviews={true}
        filesLimit={1}
        maxFileSize={5000000000}
        dialogTitle={dropzone.title}
        onClose={() => setDropzoneOpen(false)}
        submitButtonText="Upload"
        cancelButtonText="Cancel"
      />
    </React.Fragment>
  );
}
