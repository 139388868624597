import { Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, Button,Box } from "@material-ui/core";
import { KeyboardBackspace } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import api from "src/utils/api";
import moment from "moment-timezone";
import {getAccessLog} from "src/utils/reporting-api"
import { search } from "core-js/fn/symbol";
import { CSVLink } from "react-csv";
// import DownloadIcon from '';
// import { Button } from "react-simple-wysiwyg";



const RoomAccessLog = ({close,activeData}) =>{

  // const [employeeName, setEmployeeName] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [accessLogData, setAccessLogData] = useState([]);
  const [accessLogPagination, setAccessLogPagination] = useState([]);
  const [accessLogDownload, setAccessLogDownload] = useState([]);

 

  useEffect(()=>{
    fetchAccessLog()
  },[currentPage,pageSize])

  useEffect(()=>{
    fetchLogDownload();
  },[])


  const fetchLogDownload = async () =>
  {
    let query = `currentPage=${1}&pageSize=${100}`;
    query = "&eventType=AUDIT_ACL";
    query += `&filters=[{"premiseId":"${activeData.id}"}]`;
    query += `&order={"id": "DESC"}`;
    const download = await getAccessLog(query);
    console.log("download",download);
    let downloadData = [];
    download.data.records.map(d=>{
      let row = {};
      row["id"] = d.id;
      row["User Name"] = d.userName;
      row["User Type"] = d.userType;
      row["Premise Name"] = d.premiseName;
      row["Device Name"] = d.deviceName;
      row["Device Category"] = d.deviceCateogry;
      row["Device Sub-Category"]= d.deviceSubcateogry;
      row["Source"] = d.source;
      row["Access Medium"] = d.accessMedium;
      row["isApproved"] = d.isApproved;
      row["Access Time & Date"] = moment.utc(d.createdAt).local().format("HH:mm, Do MMM YYYY");

      downloadData.push(row);
    })
    setAccessLogDownload(downloadData)

  }


  const fetchAccessLog = async () =>
  {
    try
    {
      let query = `currentPage=${currentPage}&pageSize=${pageSize}`;
      query += "&eventType=AUDIT_ACL";
      query += `&filters=[{"premiseId":"${activeData.id}"}]`;
      query += `&order={"id": "DESC"}`;
      const res= await getAccessLog(query);
      setAccessLogPagination(res.data.pagination.total)
      console.log(res.data.records)
      setAccessLogData(res.data.records);
     
    }
    catch (error)
    {
      console.log(error)
    }
  }

    const handlePageChange = (event, newPage) => {
      onPaginate(pageSize, newPage + 1);
    };
  
    const handleRowsPerPageChange = (event, newRowsPerPage) => {
      onPaginate(event.target.value, currentPage);
    };


    const onPaginate = (size, page) => {
      setPageSize(size);
      if (size !== pageSize) {
        setCurrentPage(1);
      } else {
        setCurrentPage(page);
      }
    };


    return (
        <>
        <Grid
        alignItems="center"
        container
        spacing={1}
        justify="space-between"
        direction="row"
      >
        <Grid item>
          <IconButton aria-label="close" onClick={close} size="small">
            <KeyboardBackspace />
          </IconButton>
          <Typography>Back </Typography>
        </Grid>
        <Grid item>
          <Button variant="outlined" >
            <CSVLink  data={accessLogDownload}>Download Logs</CSVLink>
          </Button>
        </Grid>
        </Grid>
        <br/>
        <Grid item>
            <Typography variant="h4" color="primary">
              {activeData.name}
            </Typography>
          </Grid>
        <br/>
        <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">User Name</TableCell>
              <TableCell align="left">User Type</TableCell>
              <TableCell align="left">Access Medium</TableCell>
              <TableCell align="left">Device Name</TableCell>
              <TableCell align="left">Device Category</TableCell>
              <TableCell align="left">Device Sub-Cat</TableCell>
              <TableCell align="left">Source</TableCell>
              {/* <TableCell align="left">Time</TableCell> */}
              <TableCell align="left">Access Time and Date</TableCell>
            </TableRow>
            <TableRow>
            </TableRow>
          </TableHead>
          <TableBody>
          {accessLogData.map((row, ind) => (
              <TableRow key={ind}>
                <TableCell align="left">{row.userName}</TableCell>
                <TableCell align="left">{row.userType}</TableCell>
                <TableCell align="left">{row.accessMedium}</TableCell>
                <TableCell align="left">{row.deviceName}</TableCell>
                <TableCell align="left">{row.deviceCateogry}</TableCell>
                <TableCell align="left">{row.deviceSubcateogry}</TableCell>
                <TableCell align="left">{row.source}</TableCell>
                {/* <TableCell align="left">{(new Date(row.premiseAccessTime)).toLocaleString()}</TableCell> */}
                <TableCell align="left">{moment.utc(row.createdAt).local().format("HH:mm, Do MMM YYYY")}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br/>
      {accessLogData.length===0 && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <h3>No access log available in this room!!!</h3>
                </Box>
            )}
      <TablePagination
          component="div"
          count={accessLogPagination}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={currentPage-1}
          rowsPerPage={pageSize}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </>
    );
}
export default RoomAccessLog;