import {
  makeStyles,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Typography,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import { KeyboardBackspace } from "@material-ui/icons";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import { AddCircleOutline } from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useState } from "react";
import "react-phone-input-2/lib/material.css";
import api from "src/utils/api";
import { captureException } from "@sentry/react";
import TableBody from "@material-ui/core/TableBody";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Collapse from "@material-ui/core/Collapse";
import RoomDeviceLinkModal from "./RoomDeviceLinkModal";
import ConfirmationDialog from "../common/ConfirmDialog";
import VipAccessManagement from "../vip/VipAccessManagement";
import { useDispatch, useSelector } from "react-redux";
import { toastAction } from "../../../actions";
import RoomControlDetailsDialog from "../Controls/RoomControlDetailsDialog";
import { Alert } from "@material-ui/lab";
import { X } from "react-feather";
import RoomAccessLog from "./RoomAccessLog";
import AttachedFloorListModel from "./AttachedFloorListModel";

const useStyles = makeStyles(theme => ({
  root: {},
  marginNormal: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  formControl: {
    marginTop: "16px"
  },
  formSelect: {
    marginBottom: "5px"
  }
}));

const initialState = {
  name: "",
  premiseType: "",
  occupancy: "",
  minOccupancy: "",
  maxOccupancy: "",
  buildingId: "",
  buildingName: "",
  floorId: "",
  floorName: "",
  type: "",
  enableMeetingBooking: true
};

const RoomModal = props => {
  const classes = useStyles();
  const {
    open,
    close,
    buildingList,
    floorList,
    activeData,
    roomCount,
    fetchData,
    editRoomToArray
  } = props;
  const [formData, setFormData] = useState(initialState);
  const [isLoading, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [deviceList, setDeviceList] = useState([]);
  const [employeeName, setEmployeeName] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [roomsControlList, setRoomControlList] = useState([]);
  const [selectedControl, setSelectedControl] = useState({});
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [accessLogOpen, setAccessLogOpen] = useState(false);
  const [deviceLinkOpen, setdeviceLinkOpen] = useState(false);
  const [deviceSettingDataList, setDeviceSettingDataList] = useState([]);
  const [viewOrganization, setViewOrganization] = useState(
    new Array(rowsPerPage).fill(false)
  );
  const [openDelink, setOpenDelink] = useState(false);
  const [openFloorListModel, setOpenFloorListModel] = useState(false);
  const [deviceFloorData, setDeviceFloorData] = useState({});
  const [isLoadingDelete, setDeleteLoader] = useState(false);
  const [id, setID] = useState();
  const [countData, setCountData] = useState(0);
  const [accessOpen, setAccessOpen] = useState(false);
  const [permittedNumber, setPermittedNumber]=useState(0);
  const user = useSelector(state => state.user);
  const orgSettings = useSelector(state => state.orgSettings);

  const handleClose = () => {
    close();
  };
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (activeData.id) {
      activeData.buildingId = activeData.building.id;
      activeData.buildingName = activeData.building.name;
      activeData.floorId = activeData.floor.id;
      activeData.floorName = activeData.floor.name;
      setFormData(activeData);
    }
  }, []);

  const handleAction = () => {
    if (activeData.id) {
      handleUpdate();
    } else {
      handleAdd();
    }
  };

  const handleAdd = async () => {
    if (
      (formData.name &&
        formData.premiseType &&
        formData.buildingId) ||
      formData.type
    ) {
      setLoader(true);
      try {
        const res = await api(
          "POST",
          "room",
          {
            name: formData.name,
            premiseType: formData.premiseType,
            buildingId: formData.buildingId,
            floorId: formData.floorId,
            occupancy: formData.occupancy,
            meetingRoomEmailDisplayName: formData.meetingRoomEmailDisplayName,
            meetingRoomEmail: formData.meetingRoomEmail,
            minOccupancy: formData.minOccupancy,
            maxOccupancy: formData.maxOccupancy,
            type: formData.type,
            enableMeetingBooking:formData.enableMeetingBooking
          },
          true
        );
        if (res.id) {
          roomCount();
          handleClose();
          fetchData();
          setLoader(false);
        }
      } catch (error) {
        if (error.response && error.response.data) {
          dispatch(
            toastAction({
                status: true,
                message: error.response.data.message,
                type: "error"
            })
        )
          setError(error.response.data);
        }
        setLoader(false);
      }
    } else {
      setError("Please fill all the fields");
    }
  };

  const handleUpdate = async () => {
    if (
      (formData.name &&
        formData.premiseType &&
        formData.buildingId) ||
      formData.type
    ) {
      setLoader(true);
      try {
        const data = await api(
          "PATCH",
          `room/${activeData.id}`,
          {
            name: formData.name,
            premiseType: formData.premiseType,
            buildingId: formData.buildingId,
            floorId: formData.floorId,
            occupancy: formData.occupancy ?? 1,
            minOccupancy: formData.minOccupancy,
            maxOccupancy: formData.maxOccupancy,
            type: formData.type,
            meetingRoomEmailDisplayName: formData.meetingRoomEmailDisplayName,
            meetingRoomEmail: formData.meetingRoomEmail,
            enableMeetingBooking:formData.enableMeetingBooking
          },
          true
        );
        console.log("data", data);
        if (data.id) {
          editRoomToArray(data);
          handleClose();
          setLoader(false);
        }
      } catch (error) {
        if (error.response && error.response.data) {
          dispatch(
            toastAction({
              status: true,
              message: error.response.data.message,
              type: "error"
            })
          );
        }
        setLoader(false);
      }
    } else {
      setError("Please fill all the fields");
    }
  };

  const onTagsChange = (event, values) => {
    setFormData(formData => ({
      ...formData,
      buildingId: values.id
    }));
  };

  const onFloorChange = (event, values) => {
    setFormData(formData => ({
      ...formData,
      floorId: values.id
    }));
  };

  const handleInputChange = event => {
    event.persist();
    const { name, value } = event.target;
    if (
      name === "number" ||
      name === "occupancy" ||
      name === "minOccupancy" ||
      name === "maxOccupany" ||
      name === "premiseType"
    ) {
      const re = /^[0-9\b]+$/;
      if (value === "" || re.test(value)) {
        setFormData(formData => ({
          ...formData,
          [name]: value
        }));
      }
    } else {
      setFormData(formData => ({
        ...formData,
        [name]: value
      }));
    }
  };

  const handleMeetingBooking = (event) =>
  {
    event.persist();
    setFormData(formData => ({
      ...formData,
      enableMeetingBooking: event.target.checked
    }));
  }

  React.useEffect(() => {
    fetchDeviceData();
    fetchRoomControlData();
    fetchDeviceCount();
  }, [rowsPerPage, page]);

  const fetchDeviceData = async () => {
    setLoader(true);
    try {
      const payload = {
        search: [
          { key: "device.roomId", value: activeData.id, comparison: "=" }
        ],
        sort: {
          key: "device.name",
          order: "ASC"
        },
        skip: 0,
        take: 10
      };
      try {
        const building = await api("POST", "device/find", payload, true);
        const res = building || [];
        setDeviceList(res);
        if (res[0]) {
          if (activeData.premiseType == "vipRoom") {
            await confirmEmloyeeName(res[0].employeeId);
          }
        }
        setLoader(false);
      } catch (error) {
        captureException(error);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const fetchDeviceCount = async () => {
    try {
      const roomType = await api("GET", "quickSettings/roomSettings", null, true);
      let numberBackend = roomType.data[activeData.premiseType].premiseTypeDeviceCount;
      setPermittedNumber(numberBackend);
      const countPayload = {
        search: [
          { key: "device.roomId", value: activeData.id, comparison: "=" }
        ],
        sort: { key: "device.name", order: "ASC" },
        skip: 0,
        take: 10
      };
      const res = await api("POST", "device/findCount", countPayload, true);
      setCountData(res);
    } catch (error) {}
  };

  const fetchRoomControlData = async () => {
    setLoader(true);
    try {
      const data = await api(
        "GET",
        "room/controls/" + activeData.id,
        null,
        true
      );
      setRoomControlList(data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  const handleViewModal = row => {
    setSelectedControl(row);
    setDetailsOpen(true);
  };

  const handleLinkModal = () => {
    setdeviceLinkOpen(true);
  };

  const fetchDeviceSettingData = async id => {
    try {
      const data = await api("GET", "deviceSetting/device/" + id, null, true);
      setDeviceSettingDataList(data);
    } catch (error) {
      setLoader(false);
    }
  };

  const delinkDeviceData = async () => {
    setDeleteLoader(true);
    try {
      await api(
        "DELETE",
        `room/${activeData.id}/link/device/${id}`,
        null,
        true
      );
      fetchDeviceData();
      fetchDeviceCount();
      setOpenDelink(false);
      setDeleteLoader(false);
    } catch (error) {
      setError("Some things went wrong please try again");
      setDeleteLoader(false);
    }
  };

  const conformDelinkModal = row => {
    if (row && row.id) {
      setOpenDelink(true);
      setID(row.id);
    } else {
      setOpenDelink(false);
    }
  };

  const attachedFoorList = row =>
  {
    setOpenFloorListModel(true);
    setDeviceFloorData(row);

  }

  const confirmEmloyeeName = async empoyeeId => {
    const employee = await api("GET", `employee/${empoyeeId}`, null, true);
    setEmployeeName(employee.name);
  };

  const handleAccessModal = () => {
    setAccessOpen(true);
  };

  // console.log("deviceList",orgSettings.outlookIntegration);



  if (accessOpen)
    return (
      <VipAccessManagement
        close={() => setAccessOpen(false)}
        modalData={activeData}
      />
    );

    if(accessLogOpen)
    return (
      <RoomAccessLog 
      close={() => setAccessLogOpen(false)}
      activeData={activeData}
      />
    );

  const DialogButtons = (
    <React.Fragment>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => setOpenDelink(false)}
      >
        NO
      </Button>
      <Button
        variant="contained"
        disabled={isLoadingDelete}
        color="primary"
        onClick={() => delinkDeviceData()}
      >
        {isLoadingDelete ? "Loading..." : "Yes Delink"}
      </Button>
    </React.Fragment>
  );

  if (deviceLinkOpen) {
    return (
      <>
        <RoomDeviceLinkModal
          open={deviceLinkOpen}
          buildingList={buildingList}
          floorList={floorList}
          fetchDeviceData={fetchDeviceData}
          fetchDeviceCount={fetchDeviceCount}
          activeData={activeData}
          deviceList={deviceList}
          close={() => setdeviceLinkOpen(false)}
        />
      </>
    );
  }

  return (
    <div>
      <Grid
        alignItems="center"
        container
        spacing={1}
        justify="space-between"
        direction="row"
      >
        <Grid item>
          <IconButton aria-label="close" onClick={handleClose} size="small">
            <KeyboardBackspace />
          </IconButton>
          <Typography
            variant="h6"
            onClick={handleClose}
            color="textPrimary"
            className={classes.backButton}
          >
            Back to All Devices
          </Typography>
        </Grid>

        {activeData.id && (
          <>
            <Grid item>
             <Button
                style={{ marginRight: 10 }}
                variant="outlined"
                color="primary"
                onClick={() => {
                  setAccessLogOpen(true);
                }}
              >
                Access Log
              </Button>
              <Button
                style={{ marginRight: 10 }}
                variant="outlined"
                color="primary"
                onClick={() => {
                  setDetailsOpen(true);
                  setSelectedControl({ roomId: activeData.id });
                }}
              >
                Add Controls
              </Button>
              <Button
                style={{ marginRight: 10 }}
                variant="outlined"
                color="primary"
                onClick={handleAccessModal}
              >
                View Access
              </Button>
              <Button
                disabled={countData >= permittedNumber}
                startIcon={<AddCircleOutline />}
                variant="outlined"
                color="primary"
                onClick={handleLinkModal}
              >
                Link Device
              </Button>
            </Grid>
          </>
        )}
      </Grid>
      <br />
      <Card>
        <CardContent>
          <Typography component="h1" variant="h3" color="primary">
            {activeData.id ? "Edit" : "Add"} Room
          </Typography>
          {error !== "" && (
            <Alert
              severity="error"
              action={
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setError("");
                  }}
                >
                  <X />
                </Button>
              }
            >
              {error}
            </Alert>
          )}
          <br />
          <Grid container spacing={1}>
            {activeData.id && (
              <Grid item xs={6}>
                <Autocomplete
                  id="building"
                  options={buildingList}
                  getOptionLabel={option => option.name}
                  onChange={onTagsChange}
                  defaultValue={{
                    name: activeData.building.name,
                    buildingId: activeData.building.id
                  }}
                  disableClearable
                  className={classes.formSelect}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Building"
                      fullWidth
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            )}

            {!activeData.id && (
              <Grid item xs={6}>
                <Autocomplete
                  id="building"
                  options={buildingList}
                  getOptionLabel={option => option.name}
                  onChange={onTagsChange}
                  disableClearable
                  className={classes.formSelect}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Building"
                      fullWidth
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            )}

            {!activeData.id && (
              <Grid item xs={6}>
                <Autocomplete
                  id="floor"
                  options={floorList.filter(
                    item => item.building.id === formData.buildingId
                  )}
                  getOptionLabel={option => option.name}
                  onChange={onFloorChange}
                  defaultValue={{
                    name: formData.floorName,
                    buildingId: formData.floorId
                  }}
                  disableClearable
                  className={classes.formSelect}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Floor"
                      fullWidth
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            )}

            {activeData.id && (
              <Grid item xs={6}>
                <Autocomplete
                  id="floor"
                  deviceList
                  options={floorList.filter(
                    item => item.building.id === formData.buildingId
                  )}
                  getOptionLabel={option => option.name}
                  onChange={onFloorChange}
                  defaultValue={{
                    name: activeData.floor.name,
                    buildingId: activeData.floor.id
                  }}
                  disableClearable
                  className={classes.formSelect}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Floor"
                      fullWidth
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            )}

            <Grid item xs={6}>
              <TextField
                id="name"
                label="Name"
                variant="outlined"
                type="text"
                margin="normal"
                required
                fullWidth
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl
                variant="outlined"
                style={{ width: "100%", marginTop: 16 }}
              >
                <InputLabel id="premiseType">Premise Type</InputLabel>
                <Select
                  labelId="premiseType"
                  id="premiseType"
                  label="Premise Type"
                  value={formData.premiseType}
                  onChange={e =>
                    setFormData({ ...formData, premiseType: e.target.value })
                  }
                  fullWidth
                >
                  <MenuItem value="meetingRoom">Meeting Room</MenuItem>
                  <MenuItem value="vipRoom">Vip Room</MenuItem>
                  <MenuItem value="accessZone">Access Zone</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {formData.premiseType != "accessZone" && (
              <>
            <Grid item xs={6}>
              <TextField
                id="occupancy"
                label="Total Occupancy"
                variant="outlined"
                type="text"
                margin="normal"
                required
                fullWidth
                name="occupancy"
                value={formData.occupancy}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                id="occupancy"
                label="Maximum occupancy"
                variant="outlined"
                type="text"
                margin="normal"
                required
                fullWidth
                name="maxOccupancy"
                value={formData.maxOccupancy}
                onChange={handleInputChange}
                error={
                  parseInt(formData.maxOccupancy) > parseInt(formData.occupancy)
                }
                helperText={
                  parseInt(formData.maxOccupancy) > parseInt(formData.occupancy)
                    ? "Max. Occupancy cannot be greater than Total Occupancy"
                    : ""
                }
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                id="occupancy"
                label="Minimum occupancy"
                variant="outlined"
                type="text"
                margin="normal"
                required
                fullWidth
                name="minOccupancy"
                value={formData.minOccupancy}
                onChange={handleInputChange}
                error={
                  parseInt(formData.minOccupancy) >
                  parseInt(formData.maxOccupancy)
                }
                helperText={
                  parseInt(formData.minOccupancy) >
                  parseInt(formData.maxOccupancy)
                    ? "Min. Occupancy cannot be greater than Max Occupancy"
                    : ""
                }
              />
            </Grid>
            </>
            )}

            <Grid item xs={6}>
              <FormControl
                variant="outlined"
                fullWidth
                className={classes.formControl}
              >
                <InputLabel>Type</InputLabel>
                <Select
                  label="Type"
                  name="type"
                  value={formData.type}
                  onChange={handleInputChange}
                >
                  <MenuItem value="" disabled>
                    <em>Select</em>
                  </MenuItem>
                  {["square", "rectangle", "circle", "oval", "other"].map(
                    (val, ind) => (
                      <MenuItem key={ind} value={val}>
                        {(val.charAt(0) && val.charAt(0).toUpperCase()) +
                          val.slice(1)}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Grid>
            {user.role != "businessAdmin" &&
              orgSettings.outlookIntegration === true &&
              formData.premiseType === "meetingRoom" && (
                <>
                  <Grid item xs={6}>
                    <TextField
                      id="meetingRoomEmail"
                      label="Room mailbox (for exchange integration only)"
                      variant="outlined"
                      type="text"
                      margin="normal"
                      fullWidth
                      name="meetingRoomEmail"
                      value={formData.meetingRoomEmail}
                      onChange={handleInputChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="meetingRoomEmailDisplayName"
                      label="Room mailbox display name (for exch. int. only)"
                      variant="outlined"
                      type="text"
                      margin="normal"
                      fullWidth
                      name="meetingRoomEmailDisplayName"
                      value={formData.meetingRoomEmailDisplayName}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </>
              )}
              {formData.premiseType === "meetingRoom" &&(
                <FormGroup>
                  <FormControlLabel
                    control={
                    <Checkbox 
                    checked={formData.enableMeetingBooking}
                    onChange={(e)=>handleMeetingBooking(e)} 
                    />
                  }
                    label="Enable Meeting Booking"
                  />
                </FormGroup>
              )}
            <Grid item sm={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  onClick={handleAction}
                  disabled={
                    isLoading ||
                    parseInt(formData.maxOccupancy) >
                      parseInt(formData.occupancy) ||
                    parseInt(formData.minOccupancy) >
                      parseInt(formData.maxOccupancy)
                  }
                  variant="contained"
                  color="primary"
                >
                  {isLoading ? "Loading..." : activeData.id ? "Update" : "Add"}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <br />
      <Divider />

      {activeData.id && (
        <Card>
          <CardContent>
            <Typography variant="h4" color="primary">
              Device List
            </Typography>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Device Name</TableCell>
                    <TableCell align="left">Category</TableCell>
                    <TableCell align="left">Subcategory</TableCell>
                    <TableCell align="left">Pass key</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {deviceList.length > 0 &&
                    deviceList.map((row, ind) => (
                      <>
                        <TableRow key={ind}>
                          <TableCell align="left">{row.name || "-"}</TableCell>
                          <TableCell align="left">
                            {row.category || "-"}
                          </TableCell>
                          <TableCell align="left">
                            {row.subCategory[0] || "-"}
                          </TableCell>
                          <TableCell align="left">
                            {row.passcode || "-"}
                          </TableCell>
                          <TableCell align="left">
                            {row.isActive ? "Active" : "InActive"}
                          </TableCell>
                          <TableCell align="left">
                            <IconButton
                              aria-label="edit"
                              onClick={() => {
                                setViewOrganization(
                                  viewOrganization.map((vitem, vindex) => {
                                    if (vindex === ind) {
                                      return !vitem;
                                    } else {
                                      return vitem;
                                    }
                                  })
                                );
                                fetchDeviceSettingData(row.id);
                              }}
                            >
                              {!viewOrganization[ind] && (
                                <ExpandMoreIcon fontSize="small" />
                              )}
                              {viewOrganization[ind] && (
                                <ExpandLessIcon fontSize="small" />
                              )}
                            </IconButton>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => conformDelinkModal(row)}
                            >
                              Delink Device
                            </Button>
                            {formData.premiseType === "accessZone" && (
                            <Button
                            style={{marginLeft:5}}
                              variant="outlined"
                              color="primary"
                              onClick={() => attachedFoorList(row)}
                            >
                              View Floors
                            </Button>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ padding: 0 }} colSpan={9}>
                            <Collapse
                              in={viewOrganization[ind]}
                              style={{ display: "block" }}
                            >
                              <Card>
                                <CardContent>
                                  <Typography variant="h5">
                                    Device Setting List
                                  </Typography>
                                  <Table aria-label="simple table">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell align="left">
                                          SL No
                                        </TableCell>
                                        <TableCell align="left">Type</TableCell>
                                        <TableCell align="left">
                                          Sub Type
                                        </TableCell>
                                        <TableCell align="left">
                                          Value
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {deviceSettingDataList.length > 0 ? (
                                        deviceSettingDataList.map(
                                          (setting, index) => {
                                            return (
                                              <TableRow key={index}>
                                                <TableCell align="left">
                                                  {index + 1}
                                                </TableCell>
                                                <TableCell align="left">
                                                  {setting.type}
                                                </TableCell>
                                                <TableCell align="left">
                                                  {setting.sub_type}
                                                </TableCell>
                                                <TableCell align="left">
                                                  {JSON.stringify(
                                                    setting.value
                                                  )}
                                                </TableCell>
                                                <TableCell align="left"></TableCell>
                                              </TableRow>
                                            );
                                          }
                                        )
                                      ) : (
                                        <></>
                                      )}
                                    </TableBody>
                                  </Table>
                                </CardContent>
                              </Card>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                  {deviceList.length === 0 && (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <h3>No Device Found</h3>
                    </Box>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      )}
      <Divider />
      {activeData.id && (
        <>
          <Card>
            <CardContent>
              <Grid
                container
                alignItems="center"
                direction="row"
                justifyContent="space-between"
              >
                <Grid item>
                  <Typography variant="h4" color="primary">
                    Room Control
                  </Typography>
                </Grid>
              </Grid>

              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Control Name</TableCell>
                      <TableCell align="left">Control Type</TableCell>
                      <TableCell align="left">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {roomsControlList.length > 0 &&
                      roomsControlList.map((row, ind) => (
                        <TableRow key={ind}>
                          <TableCell align="left">{row.controlName}</TableCell>
                          <TableCell align="left">{row.controlType}</TableCell>
                          <TableCell align="left">
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => handleViewModal(row)}
                            >
                              View
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    {roomsControlList.length === 0 && (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <h3>No Control Found in this room</h3>
                      </Box>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
          <Divider />
          {activeData.premiseType == "vipRoom" && (
            <Card>
              <CardContent>
                <Grid container alignItems="center" direction="row">
                  <Grid item>
                    <Typography variant="h4" color="primary">
                      Employee Attached
                    </Typography>
                  </Grid>
                </Grid>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableBody>
                      <Box style={{ marginTop: 15 }}>
                        <h3>{employeeName}</h3>
                      </Box>
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          )}

          <RoomControlDetailsDialog
            open={detailsOpen}
            fetchRoomControlData={fetchRoomControlData}
            close={() => setDetailsOpen(false)}
            dataList={selectedControl}
          />
        </>
      )}

      {openDelink && (
        <ConfirmationDialog
          open={open}
          error={error}
          handleClose={() => setOpenDelink(false)}
          title={"Delink Device"}
          content={"Are you sure you want to delink device?"}
          btnActions={DialogButtons}
        />
      )}
{openFloorListModel && (
   <AttachedFloorListModel
    open={openFloorListModel}
    deviceFloorData={deviceFloorData}
    close={() => setOpenFloorListModel(false)}
    />
    )}

    </div>
  );
};

export default RoomModal;
